import React, { useState } from "react";
import moment from "moment";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import styles from "components/Tarjetas/Tarjetas.module.css";

const DEFAULT_OPTION = `Elije la hora aquí`;

export default function DropdownHoras(props) {
  const { options, select } = props.data;
  let horariosTotales = [];
  let horariosFiltrados = [];

  horariosTotales = options.map((horario) => {
    return {
      ...horario,
      textual: horario.dia.substring(11, 16),
    };
  });

  horariosFiltrados = horariosTotales;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(DEFAULT_OPTION);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  if (horariosFiltrados.length === 0) {
    return (
      <div className={styles.divMartin}>
        <p className={`${styles.divMartin__p}`}>Horarios no disponibles</p>
      </div>
    );
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        caret
        color="white"
        className={`${styles.DropdownHorarios} px-5`}
      >
        {selected}
      </DropdownToggle>
      <DropdownMenu
        modifiers={{
          setMaxHeight: {
            enabled: true,
            order: 890,
            fn: (data) => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  overflow: "auto",
                  maxHeight: "200px",
                },
              };
            },
          },
        }}
      >
        {horariosFiltrados.map((horario, index) => (
          <DropdownItem
            key={horario.textual}
            className={`${styles.ItemHorarios}`}
            onClick={() => {
              setSelected(horario.textual);
              select(horario);
            }}
          >
            {horario.textual}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
