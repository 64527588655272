import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import Cookies from 'universal-cookie';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import BotonGeneral from '../../components/BotonGeneral';

import '../../css/Fonts.css';
import CuestionarioDatos from '../../data/Cuestionario_sintomatologia';

import styles from '../../css/Cuestionario.module.css';
import DiagnosticosData from '../../data/Diagnosticos.json';
import FlowsData from '../../data/flows.json';

const cookies = new Cookies();
const headerName = 'Tu doctor de los ojos en línea';

let prioridad = 0;
let indexDiagnostic;
const useStyles = makeStyles({
	root: {
		position: 'absolute',
		top: 0,
		right: '-16px',
		color: '#10798b',
		['@media (max-width:750px)']: {
			top: 0,
			right: '-20px',
		},
	},
	icon: {
		border: 'none',
		borderRadius: 50,
		width: 16,
		height: 16,
	},
	checkedIcon: {
		backgroundColor: '#ffcd00',
		'&:before': {
			borderColor: '#ffcd00',
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#ffcd00',
		},
	},
	checked: {
		'&+$label': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '110px',
			height: '110px',
			backgroundColor: '#fff',
			textAlign: 'center',
			borderRadius: '50%',
			border: '1px solid #ffcd00',
			['@media (max-width:750px)']: {
				width: '100px',
				height: '100px',
			},
		},
	},
	label: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '110px',
		height: '110px',
		backgroundColor: '#fff',
		textAlign: 'center',
		borderRadius: '50%',
		marginLeft: '11px',
		marginRight: '-16px',
		marginBottom: '-10px',
		['@media (max-width:750px)']: {
			width: '100px',
			height: '100px',
		},
	},
});

function StyledCheckbox(props) {
	const classes = useStyles();

	return (
		<Checkbox
			className={classes.root}
			disableRipple
			color='default'
			checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
			icon={<span className={classes.icon} />}
			inputProps={{ 'aria-label': 'decorative checkbox' }}
			{...props}
		/>
	);
}

function FormControlLabelStyled(props) {
	const classes = useStyles();
	return (
		<FormControlLabel
			className={`${styles.formControlLabel}`}
			classes={{
				label: classes.label,
			}}
			checked={props.checked}
			control={
				<StyledCheckbox
					classes={{
						checked: classes.checked,
					}}
					onChange={props.onChange}
					value='checkedA'
				/>
			}
			label={props.label}
		/>
	);
}

export default class Cuestionario extends Component {
	constructor(props) {
		super(props);

		if (process.env.NODE_ENV !== 'production') {
			console.tron.display({
				name: `[Cuestionario] History`,
				value: props.location.state,
				important: true,
			});
		}

		const getShowCookies = cookies.get('showCookies');
		let cookie = false;

		if (getShowCookies === 'true' || typeof getShowCookies !== 'string') {
			cookies.set('showCookies', true, {
				path: '/',
			});
			cookie = true;
		}
		let sintomasSeleccionados = Object.keys(CuestionarioDatos).map(() => false);
		this.state = {
			quiereConsulta: false,
			activaBotonContinuar: false,
			toast: false,
			sintomasSeleccionados,
			modalCookies: cookie,
			showCookies: cookie,
			checkSeleccionaEdad: false,
		};
	}
	getObjectByService = (nameService) => {
		return Object.keys(DiagnosticosData).find(
			(key) => DiagnosticosData[key].nombreServicio === nameService
		);
	};
	navegaPorServicio = (servicio) => {
		let diagnosticoIndex = this.getObjectByService(servicio);
		this.setHistoryForNavigation(diagnosticoIndex);
	};
	setHistoryForNavigation = (index) => {
		indexDiagnostic = index;
	};
	navega = () => {
		this.comprobarSintomas();
		const regresion = false;
		const rutaA = '/cita/orientacion';
		let { sintomasSeleccionados } = this.state;
		const { history, location } = this.props;
		let { header, diagnostico, historialFlujo } = location.state;
		let { flujoStep } = historialFlujo;
		historialFlujo.flujoStep = flujoStep + 1;
		header.push({
			id: 2,
			titulo: 'Quiero un servicio para:',
			dato: headerName,
			ruta: rutaA,
		});

		let newHistory = {
			...location.state,
			header,
			regresion,
			prioridad,
			historialFlujo,
			para: {
				paraQuien: 1,
			},
		};

		cookies.set('showCookies', false, { path: '/' });
		this.setState({
			modalCookies: false,
			showCookies: false,
		});
		if (sintomasSeleccionados[0] === true) {
			//! id de actualizacion de lentes
			newHistory.diagnostico = DiagnosticosData[1];
			historialFlujo.tipoConsulta = 'presencial';
			newHistory.idsSeleccionadosOMO = sintomasSeleccionados;
		} else {
			newHistory.idsSeleccionadosOMO = sintomasSeleccionados;
		}
		this.navegaPorServicio(diagnostico.nombreServicio);
		// history.push(LINK + history.location.search, newHistory);
		history.push(
			FlowsData[DiagnosticosData[indexDiagnostic].routes][
				this.props.location.pathname
			] + history.location.search,
			newHistory
		);
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	toogleSintoma = (idSintoma) => {
		let { sintomasSeleccionados } = this.state;
		sintomasSeleccionados[idSintoma] = !sintomasSeleccionados[idSintoma];
		let activaBotonContinuar = sintomasSeleccionados.includes(true);
		this.setState({ sintomasSeleccionados, activaBotonContinuar });
	};

	comprobarSintomas = () => {
		let { sintomasSeleccionados } = this.state;
		let arraySeleccionados = sintomasSeleccionados.map((sintoma, index) => {
			if (sintoma === true) {
				// if (process.env.NODE_ENV !== 'production') { console.tron.log(`[Cuestionario] @comprobarSintomas sintoma sintoma ${sintoma} index ${index + 1}`) }
				return index + 1;
			}
			return null;
		});

		arraySeleccionados = arraySeleccionados.filter((seleccionado) => {
			return seleccionado;
		});
		let arregloPrioridad = [];
		Object.values(CuestionarioDatos).map((objetoSintoma) => {
			arraySeleccionados.map((value) => {
				if (value === objetoSintoma.id) {
					// if (process.env.NODE_ENV !== 'production') { console.tron.log(`[Cuestionario] @comprobarSintomas objetoSintoma ${objetoSintoma.id} objetoSintoma.prioridad ${objetoSintoma.prioridad}`) }
					arregloPrioridad.push(objetoSintoma.prioridad);
				}
				return null;
			});
			return null;
		});

		arregloPrioridad.sort((a, b) => {
			return a - b;
		});

		prioridad = arregloPrioridad[0];
	};

	render() {
		const { sintomasSeleccionados } = this.state;
		return (
			<Container fluid className={`${styles.containerCuestionario}`}>
				<Container className={`${styles.cuestionarioContainer}`}>
					<Row className={`${styles.rowContainer}`}>
						<Col xs={12} className={`${styles.colContainer}`}>
							<Row className={`${styles.rowContainerPadesimientos}`}>
								<Col className={`${styles.colContainerPadesimientos}`}>
									<Row className={`${styles.rowContainerTitle}`}>
										<Col
											className={`${styles.colContainerTitle} d-flex justify-content-center`}>
											<span className={`${styles.colContainerTitle__title}`}>
												Selecciona los síntomas que ha presentado:
											</span>
										</Col>
									</Row>
									<Row
										className={`${styles.rowContainerAllPadesimientos} d-flex justify-content-center`}>
										{Object.values(CuestionarioDatos).map((pregunta, index) => (
											<Col
												xs={5}
												sm={5}
												md={5}
												lg={3}
												xl={3}
												className={`${styles.colContainerOnePadesimiento}`}>
												<FormControlLabelStyled
													checked={
														sintomasSeleccionados[pregunta.id - 1] || false
													}
													onChange={() => this.toogleSintoma(pregunta.id - 1)}
													label={
														<p
															className={styles.formControlLabelDescription__p}>
															{pregunta.descripcionSeparada !== undefined &&
																pregunta.descripcionSeparada.map((parrafo) => (
																	<p
																		className={`${styles.formControlLabelDescription__lineaParrafo}`}>
																		{parrafo}
																	</p>
																))}
														</p>
													}
												/>
											</Col>
										))}
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className={`${styles.rowContainerButtons}`}>
						<Col
							xs={12}
							className={`${styles.colContainerButtons} d-flex justify-content-center`}>
							<BotonGeneral
								manejarClick={() => this.navega()}
								nombreBoton='CONTINUAR'
								activo={this.state.activaBotonContinuar}
								existe={true}
							/>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	}
}
