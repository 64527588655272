import React from 'react';
import CreaPacienteTamizado from './CreaPacienteTamizado';

export default (props) => {
    if (process.env.NODE_ENV !== 'production') {
    console.tron.display({
      name: `[Diagnosticos] CreaPacienteTamizadoContainer`,
      value: props.history.location.state,
    });
  }
  const { gdlvCampaign } = props.history.location.state;
  return <CreaPacienteTamizado gdlvCampaign={gdlvCampaign} />;
};
