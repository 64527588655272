import React from "react";
import { Container, Row, Col } from "reactstrap";

import HeaderServicios from "../HeaderServicios";

import imagen2x1 from "images/png/imagen2x1.png";
import styles from "css/CitaPromo2x1Styles.module.css";

let TXT_TOAST = "";

export default ({ goToPromo, backToHome, state }) => {
  return (
    <Container fluid className={`${styles.container}`}>
      <HeaderServicios toastText={TXT_TOAST} toastShow={state.toast} />
      <Container className={`${styles.containerDatos}`}>
        <Row className={`${styles.rowContainerImage}`}>
          <Col xs={2}> </Col>
          <Col
            className={`d-flex justify-content-center py-5`}
            xs={8}
          >
            <img
              src={imagen2x1}
              alt="imagen 2x1"
              className={`img-fluid ${styles.rowContainerImage__image} `}
            />
          </Col>
          <Col xs={2}> </Col>
        </Row>
        <Row className={`${styles.rowContainerIncluye}`}>
          <Col
            className={`${styles.rowContainerIncluye__colTileIncluye} d-flex justify-content-center`}
            xs={12}
          >
            <h2 className={`${styles.rowContainerIncluye__title}`}>
              La promoción aplica en consulta adulto, incluye:
            </h2>
          </Col>
          <Col
            className={`${styles.rowContainerIncluye__colIncluye} d-flex justify-content-center`}
            xs={12}
          >
            <ul className={`${styles.rowContainerIncluye__ul}`}>
              <li className={`${styles.rowContainerIncluye__li}`}>
                Tonómetro (toma de presión oculaer)
              </li>
              <li className={`${styles.rowContainerIncluye__li}`}>
                Autorefractomretro (examen computarizado)
              </li>
              <li className={`${styles.rowContainerIncluye__li}`}>
                Revisión con el optometrista (agudeza visual, retinoscopia,
                refracción y prueba ambulatoria)
              </li>
            </ul>
          </Col>
        </Row>
        <Row
          className={`${styles.rowContainerButtons} d-flex justify-content-center py-5`}
        >
          <Col
            className={`${styles.rowContainerButtons__colButtonPromo2x1} d-flex justify-content-center`}
            xs={12}
            md={5}
            lg={4}
          >
            <button
              type="button"
              onClick={goToPromo}
              className={`${styles.rowContainerButtons__promo2x1}`}
            >
              Quiero agendar una cita 2x1
            </button>
          </Col>
          <Col
            className={`${styles.rowContainerButtons__colButtonCitaNormal} d-flex justify-content-center py-0 py-sm-3 py-md-0`}
            xs={12}
            md={5}
            lg={4}
          >
            <button
              type="button"
              onClick={backToHome}
              className={`${styles.rowContainerButtons__citaNormal}`}
            >
              Quiero agendar una cita
            </button>
          </Col>
        </Row>
        <Row className={`${styles.rowContainerRestricciones}`}>
          <Col
            className={`${styles.rowContainerRestricciones__colRestricciones} d-flex justify-content-center py-5`}
            xs={12}
          >
            <ul className={`${styles.rowContainerRestricciones__ul}`}>
              <h3 className={`${styles.rowContainerRestricciones__h3}`}>
                Restricciones:
              </h3>
              <li className={`${styles.rowContainerRestricciones__li}`}>
                Aplica en consulta general de primera vez
              </li>
              <li className={`${styles.rowContainerRestricciones__li}`}>
                Deberán agendar cita previamente para poder obtener la promoción
              </li>
              <li className={`${styles.rowContainerRestricciones__li}`}>
                La cita debe programarse para ambos pacientes el mismo día
              </li>
              <li className={`${styles.rowContainerRestricciones__li}`}>
                Ambos pacientes deberán acudir en el mismo día de la cita
              </li>
              <li className={`${styles.rowContainerRestricciones__li}`}>
                Válido solo en Centro Quirúrgico Hamburgo y clínicas Neza,
                Aragón Av. Central
              </li>
              <li className={`${styles.rowContainerRestricciones__li}`}>
                Vigencia al 15 de septiembre del 2020
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Row className={`${styles.footer}`} />
    </Container>
  );
};
