import React, { useState } from "react";
import FormaDePagoSelector from "./FormaDePagoSelector";

export default ({ getFormaDePago }) => {
  const [tipoPago, setTipoPago] = useState("card");
  getFormaDePago(tipoPago);
  const selectTipoDePago = (e) => {
    getFormaDePago(e.target.value);
    setTipoPago(e.target.value);
  };
  return (
    <FormaDePagoSelector
      selectTipoDePago={selectTipoDePago}
      tipoPago={tipoPago}
    />
  );
};
