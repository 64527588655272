import React, { Component } from "react";
import PropTypes from "prop-types";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

export class MapaSucursales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coords: props.coords,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.coords !== state.coords) {
      return {
        coords: props.coords,
      };
    }
    return null;
  }

  render() {
    const { coords, google } = this.props;
    const location = coords;
    const ubicacion = location.split(",");
    const latitud = ubicacion[0];
    const longitud = ubicacion[1];
    const disableDefaultUI = true;
    const zoomControl = true;
    const zoom = 17;
    const style = {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      width: "100%",
      height: "100%",
    };
    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[MapaSucursales] Coordenadas`,
        value: {
          latitud,
          longitud,
        },
      });
    }

    return (
      <Map
        google={google}
        zoom={zoom}
        initialCenter={{ lat: latitud, lng: longitud }}
        center={{ lat: latitud, lng: longitud }}
        disableDefaultUI={disableDefaultUI}
        zoomControl={zoomControl}
        style={style}
      >
        <Marker
          onClick={this.onMarkerClick}
          name="Current location"
          position={{ lat: latitud, lng: longitud }}
        />
      </Map>
    );
  }
}

MapaSucursales.propTypes = {
  coords: PropTypes.any,
  google: PropTypes.any,
  showMartin: PropTypes.bool,
};

MapaSucursales.defaultProps = {
  coords: null,
  google: null,
  showMartin: true,
};

export default GoogleApiWrapper(() => ({
  apiKey: "AIzaSyBulEa58DEwZIPFX0GWg5mIKg8nIiFwRUY",
  language: "es",
}))(MapaSucursales);
