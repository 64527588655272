/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from "react";
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import LentePrimario from "./LentePrimario.json";
import sprite from "images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";
import Admiracion from "images/svg/confirmacion__admiracion.svg";

import styles from "./FormularioCIStyles.module.css";
import stylesConfirmacion from "css/ConfirmacionStyles.module.css";

const FormularioCI = ({
  state,
  formState,
  handleChange,
  comprobarDatos,
  confirmarDatos,
  modalState,
  handleConfirmar,
  continuar,
}) => {
  const renderOjo = (renderizarEn) => {
    if (renderizarEn === "formulario") {
      return (
        <Row className={`${styles.rowContainerItemFormulario}`}>
          <Col xs={6} className={`${styles.colContainerItemFormulario_Title}`}>
            <label>Selecciona el ojo</label>
          </Col>
          <Col xs={6} className={`${styles.colContainerItemFormulario}`}>
            <select onChange={(e) => handleChange(e)} name="ojoOperar">
              <option value="OI">Ojo Izquierdo</option>
              <option value="OD">Ojo Derecho</option>
              <option value="AO">Ambos Ojos</option>
            </select>
          </Col>
        </Row>
      );
    }
    return (
      <Col xs={12}>
        <p className={stylesConfirmacion.textModalNoDisponible}>
          Ojo a seleccionado : {formState.ojoOperar}
        </p>
      </Col>
    );
  };

  const TipoAnestecia = (renderizarEn) => {
    if (renderizarEn === "formulario") {
      return (
        <Row className={`${styles.rowContainerItemFormulario}`}>
          <Col xs={6} className={`${styles.colContainerItemFormulario_Title}`}>
            <label>Tipo de anestesia</label>
          </Col>
          <Col xs={6} className={`${styles.colContainerItemFormulario}`}>
            <select onChange={(e) => handleChange(e)} name="tipoAnestecia">
              <option value="Tópica">Tópica</option>
              <option value="Sedación Tópica">Sedación Tópica</option>
              <option value="Sedación Retrobulbar">Sedación Retrobulbar</option>
              <option value="General">General</option>
            </select>
          </Col>
        </Row>
      );
    }

    return (
      <Col xs={12}>
        <p className={stylesConfirmacion.textModalNoDisponible}>
          Tipo de anestesia : {formState.tipoAnestecia}
        </p>
      </Col>
    );
  };

  const renderTipoTecnica = (renderizarEn) => {
    if (renderizarEn === "formulario") {
      return (
        <Row className={`${styles.rowContainerItemFormulario}`}>
          <Col xs={6} className={`${styles.colContainerItemFormulario_Title}`}>
            <label>Tipo de Tecnica</label>
          </Col>
          <Col xs={6} className={`${styles.colContainerItemFormulario}`}>
            <select onChange={(e) => handleChange(e)} name="tipoTecnica">
              {state.ServicioCI.nombreServicio === "CI - CROSSLINKING" ? (
                <>
                  <option value="EPI ON">EPI ON</option>
                  <option value="EPI OFF">EPI OFF</option>
                </>
              ) : (
                <>
                  <option value="LASIK">LASIK</option>
                  <option value="PRK">PRK</option>
                </>
              )}
            </select>
          </Col>
        </Row>
      );
    }

    return (
      <Col xs={12}>
        <p className={stylesConfirmacion.textModalNoDisponible}>
          Tipo de técnica : {formState.tipoTecnica}
        </p>
      </Col>
    );
  };

  const renderLugarProcedimiento = (renderizarEn) => {
    if (renderizarEn === "formulario") {
      return (
        <Row className={`${styles.rowContainerItemFormulario}`}>
          <Col xs={6} className={`${styles.colContainerItemFormulario_Title}`}>
            <label>Lugar de procedimiento</label>
          </Col>
          <Col xs={6} className={`${styles.colContainerItemFormulario}`}>
            <select onChange={(e) => handleChange(e)} name="lugarProcedimiento">
              {state.ServicioCI.nombreServicio === "CI - CHALAZION" ? (
                <>
                  <option value="Quirofano">Quirofano</option>
                  <option value="Gabinete">Gabinete</option>
                </>
              ) : (
                <>
                  <option value="Quirofano">Quirofano</option>
                  <option value="Sala de procedimientos">
                    Sala de procedimientos
                  </option>
                </>
              )}
            </select>
          </Col>
        </Row>
      );
    }

    return (
      <Col xs={12}>
        <p className={stylesConfirmacion.textModalNoDisponible}>
          Lugar de procedimiento : {formState.lugarProcedimiento}
        </p>
      </Col>
    );
  };

  const renderEquipo = (renderizarEn) => {
    if (renderizarEn === "formulario") {
      return (
        <Row className={`${styles.rowContainerItemFormulario}`}>
          <Col xs={6} className={`${styles.colContainerItemFormulario_Title}`}>
            <label>Equipo</label>
          </Col>
          <Col xs={6} className={`${styles.colContainerItemFormulario}`}>
            <select onChange={(e) => handleChange(e)} name="equipo">
              <option value="Centurion Silver">Centurion Silver</option>
              <option value="Infiniti">Infiniti</option>
              <option value="Constellation">Constellation</option>
            </select>
          </Col>
        </Row>
      );
    }

    return (
      <Col xs={12}>
        <p className={stylesConfirmacion.textModalNoDisponible}>
          Equipo : {formState.equipo}
        </p>
      </Col>
    );
  };

  const renderInsumos = (renderizarEn) => {
    if (renderizarEn === "formulario") {
      return (
        <Row className={`${styles.rowContainerItemFormulario}`}>
          <Col xs={6} className={`${styles.colContainerItemFormulario_Title}`}>
            <label>Insumos</label>
          </Col>
          <Col xs={6} className={`${styles.colContainerItemFormulario}`}>
            <textarea name="insumos" onChange={(e) => handleChange(e)} />
          </Col>
        </Row>
      );
    }

    return (
      <Col xs={12}>
        <p className={stylesConfirmacion.textModalNoDisponible}>
          Insumos : {formState.insumos}
        </p>
      </Col>
    );
  };

  const renderNombrePaciente = () => {
    return (
      <Row className={`${styles.rowContainerItemFormulario}`}>
        <Col xs={6} className={`${styles.colContainerItemFormulario_Title}`}>
          <label>Nombre del paciente</label>
        </Col>
        <Col xs={6} className={`${styles.colContainerItemFormulario}`}>
          <input
            type="text"
            name="nombrePaciente"
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </Row>
    );
  };

  const requiereLio = (renderizarEn) => {
    if (renderizarEn === "formulario") {
      return (
        <Row className={`${styles.rowContainerItemFormulario}`}>
          <Col xs={6} className={`${styles.colContainerItemFormulario_Title}`}>
            <label>Requiere calculo de lio</label>
          </Col>
          <Col xs={6} className={`${styles.colContainerItemFormulario}`}>
            <select onChange={(e) => handleChange(e)} name="requiereCalLio">
              <option value="SI">Si</option>
              <option value="NO">No</option>
            </select>
          </Col>
        </Row>
      );
    }

    return (
      <Col xs={12}>
        <p className={stylesConfirmacion.textModalNoDisponible}>
          Requiere calculo de LIO : {formState.requiereCalLio}
        </p>
      </Col>
    );
  };

  const renderModeloLio = (renderizarEn) => {
    if (renderizarEn === "formulario") {
      return (
        <Row className={`${styles.rowContainerItemFormulario}`}>
          <Col xs={6} className={`${styles.colContainerItemFormulario_Title}`}>
            <label>Modelo de lio</label>
          </Col>
          <Col xs={6} className={`${styles.colContainerItemFormulario}`}>
            <select
              onChange={(e) => handleChange(e)}
              name="modeloLio"
              disabled={formState.requiereCalLio === "SI" ? false : true}
            >
              {LentePrimario.map((lente) => (
                <option value={lente.value}>{lente.nombre}</option>
              ))}
            </select>
          </Col>
        </Row>
      );
    }

    return (
      <Col xs={12}>
        <p className={stylesConfirmacion.textModalNoDisponible}>
          Modelo de LIO : {formState.modeloLio}
        </p>
      </Col>
    );
  };

  const renderPoder = (renderizarEn) => {
    if (renderizarEn === "formulario") {
      return (
        <Row className={`${styles.rowContainerItemFormulario}`}>
          <Col xs={6} className={`${styles.colContainerItemFormulario_Title}`}>
            <label>Poder de lente</label>
          </Col>
          <Col xs={6} className={`${styles.colContainerItemFormulario}`}>
            <input
              type="number"
              onChange={(e) => handleChange(e)}
              name="poderLente"
            />
          </Col>
        </Row>
      );
    }
    return (
      <Col xs={12}>
        <p className={stylesConfirmacion.textModalNoDisponible}>
          Poder de lente : {formState.poderLente}
        </p>
      </Col>
    );
  };

  const renderComentarios = () => {
    return (
      <Row className={`${styles.rowContainerItemFormulario}`}>
        <Col xs={6} className={`${styles.colContainerItemFormulario_Title}`}>
          <label>Comentarios</label>
        </Col>
        <Col xs={6} className={`${styles.colContainerItemFormulario}`}>
          <textarea name="comentarios" onChange={(e) => handleChange(e)} />
        </Col>
      </Row>
    );
  };

  return (
    <Container fluid className={`${styles.containerBienvenido}`}>
      <Container className={`${styles.bienvenidoContainer}`}>
        <Row className={`${styles.rowContainer}`}>
          <Col className={`${styles.colContainer}`}>
            <Row className={`${styles.rowTitle}`}>
              <Col className={`${styles.colTitle}`}>
                Ingrese los datos para su {state.CategoriaCI}
              </Col>
            </Row>
            <Row className={`${styles.rowContainerFormulario}`}>
              <Col className={`${styles.colContainerFormulario}`}>
                <form className={`${styles.form}`}>
                  {state.CategoriaCI !== "Cirugía"
                    ? state.CategoriaCI !== "Inyecciones"
                      ? null
                      : renderOjo("formulario")
                    : renderOjo("formulario")}
                  {state.CategoriaCI === "Cirugía"
                    ? state.ServicioCI.nombreServicio !== "CI - CROSSLINKING"
                      ? state.ServicioCI.nombreServicio !== "CI - LASIK"
                        ? state.ServicioCI.nombreServicio !== "CI - CHALAZION"
                          ? TipoAnestecia("formulario")
                          : null
                        : null
                      : null
                    : null}
                  {(state.ServicioCI.nombreServicio === "CI - CROSSLINKING" ||
                    state.ServicioCI.nombreServicio === "CI - LASIK") &&
                    renderTipoTecnica("formulario")}
                  {state.ServicioCI.nombreServicio === "CI - CHALAZION"
                    ? renderLugarProcedimiento("formulario")
                    : state.ServicioCI.nombreServicio === "CI - INYECCION"
                    ? renderLugarProcedimiento("formulario")
                    : null}
                  {/* Comienza tercera fila */}
                  {state.CategoriaCI === "Cirugía"
                    ? state.ServicioCI.nombreServicio !== "CI - CROSSLINKING"
                      ? state.ServicioCI.nombreServicio !== "CI - LASIK"
                        ? state.ServicioCI.nombreServicio !== "CI - CHALAZION"
                          ? renderEquipo("formulario")
                          : null
                        : null
                      : null
                    : null}
                  {/* Comienza cuarta fila */}
                  {state.CategoriaCI === "Cirugía"
                    ? renderInsumos("formulario")
                    : state.CategoriaCI === "Inyecciones"
                    ? renderInsumos("formulario")
                    : null}
                  {/* Comienza quinta fila */}
                  {renderNombrePaciente()}
                  {/* Comienza sexta fila */}
                  {state.CategoriaCI === "Cirugía"
                    ? state.ServicioCI.nombreServicio !== "CI - CROSSLINKING"
                      ? state.ServicioCI.nombreServicio !== "CI - LASIK"
                        ? state.ServicioCI.nombreServicio !== "CI - CHALAZION"
                          ? requiereLio("formulario")
                          : null
                        : null
                      : null
                    : null}
                  {/* Comienza septima fila */}
                  {state.CategoriaCI === "Cirugía"
                    ? state.ServicioCI.nombreServicio !== "CI - CROSSLINKING"
                      ? state.ServicioCI.nombreServicio !== "CI - LASIK"
                        ? state.ServicioCI.nombreServicio !== "CI - CHALAZION"
                          ? renderModeloLio("formulario")
                          : null
                        : null
                      : null
                    : null}
                  {/* Comienza octava fila */}
                  {state.CategoriaCI === "Cirugía"
                    ? state.ServicioCI.nombreServicio !== "CI - CROSSLINKING"
                      ? state.ServicioCI.nombreServicio !== "CI - LASIK"
                        ? state.ServicioCI.nombreServicio !== "CI - CHALAZION"
                          ? renderPoder("formulario")
                          : null
                        : null
                      : null
                    : null}
                  {/* Comienza novena fila */}
                  {renderComentarios()}
                  {comprobarDatos() === true && (
                    <Row className={`${styles.rowButtonContinuar}`}>
                      <Col className={`${styles.colButtonContinuar}`} xs={12}>
                        <button
                          className={`${styles.colContainerButton__button}`}
                          onClick={() => confirmarDatos()}
                          type="button"
                        >
                          <Container fluid>
                            <Row>
                              <Col
                                xs={7}
                                className={`${styles.colContainerNombreBoton}`}
                              >
                                Continuar
                              </Col>
                              <Col
                                xs={4}
                                className={`${styles.colContainerSpanBoton}`}
                              >
                                <span>
                                  <svg className={`${styles.svg}`}>
                                    <use
                                      xlinkHref={`${sprite}#icon-arrow-right`}
                                    />
                                  </svg>
                                </span>
                              </Col>
                            </Row>
                          </Container>
                        </button>
                      </Col>
                    </Row>
                  )}
                </form>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={modalState}
              toggle={() => handleConfirmar()}
              className={stylesConfirmacion.modalContainer}
              id="confirmaDatos"
              backdropClassName={`${stylesConfirmacion.backdropModal}`}
              modalClassName={`${stylesConfirmacion.modal}`}
              contentClassName={`${stylesConfirmacion.modalContanet}`}
            >
              <ModalHeader
                toggle={() => handleConfirmar()}
                className={`${stylesConfirmacion.modalHeader}`}
                buttonClassClosed={`${stylesConfirmacion.modalButtonClosed}`}
              />
              <ModalBody className={`${stylesConfirmacion.modalBody}`}>
                <Container>
                  <Row>
                    <Col
                      xs={12}
                      className={`d-flex justify-content-center align-items-center`}
                    >
                      <img src={Admiracion} alt="modal_icon" />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      className={`${stylesConfirmacion.colContainerTextModalCancelar} `}
                    >
                      <Row>
                        <Col xs={12}>
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            ¿Quieres confirmar los siguientes datos?
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        {state.CategoriaCI !== "Cirugía"
                          ? state.CategoriaCI !== "Inyecciones"
                            ? null
                            : renderOjo("confirmacion")
                          : renderOjo("confirmacion")}
                        {state.CategoriaCI === "Cirugía"
                          ? state.ServicioCI.nombreServicio !==
                            "CI - CROSSLINKING"
                            ? state.ServicioCI.nombreServicio !== "CI - LASIK"
                              ? state.ServicioCI.nombreServicio !==
                                "CI - CHALAZION"
                                ? TipoAnestecia("confirmacion")
                                : null
                              : null
                            : null
                          : null}
                        {(state.ServicioCI.nombreServicio ===
                          "CI - CROSSLINKING" ||
                          state.ServicioCI.nombreServicio === "CI - LASIK") &&
                          renderTipoTecnica("confirmacion")}
                        {state.ServicioCI.nombreServicio === "CI - CHALAZION"
                          ? renderLugarProcedimiento("confirmacion")
                          : state.ServicioCI.nombreServicio === "CI - INYECCION"
                          ? renderLugarProcedimiento("confirmacion")
                          : null}
                        {state.CategoriaCI === "Cirugía"
                          ? state.ServicioCI.nombreServicio !==
                            "CI - CROSSLINKING"
                            ? state.ServicioCI.nombreServicio !== "CI - LASIK"
                              ? state.ServicioCI.nombreServicio !==
                                "CI - CHALAZION"
                                ? renderEquipo("confirmacion")
                                : null
                              : null
                            : null
                          : null}
                        {state.CategoriaCI === "Cirugía"
                          ? renderInsumos("confirmacion")
                          : state.CategoriaCI === "Inyecciones"
                          ? renderInsumos("confirmacion")
                          : null}
                        <Col xs={12}>
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            Nombre del paciente : {formState.nombrePaciente}
                          </p>
                        </Col>
                        {state.CategoriaCI === "Cirugía"
                          ? state.ServicioCI.nombreServicio !==
                            "CI - CROSSLINKING"
                            ? state.ServicioCI.nombreServicio !== "CI - LASIK"
                              ? state.ServicioCI.nombreServicio !==
                                "CI - CHALAZION"
                                ? requiereLio("confirmacion")
                                : null
                              : null
                            : null
                          : null}
                        {state.CategoriaCI === "Cirugía"
                          ? state.ServicioCI.nombreServicio !==
                            "CI - CROSSLINKING"
                            ? state.ServicioCI.nombreServicio !== "CI - LASIK"
                              ? state.ServicioCI.nombreServicio !==
                                "CI - CHALAZION"
                                ? renderModeloLio("confirmacion")
                                : null
                              : null
                            : null
                          : null}
                        {state.CategoriaCI === "Cirugía"
                          ? state.ServicioCI.nombreServicio !==
                            "CI - CROSSLINKING"
                            ? state.ServicioCI.nombreServicio !== "CI - LASIK"
                              ? state.ServicioCI.nombreServicio !==
                                "CI - CHALAZION"
                                ? renderPoder("confirmacion")
                                : null
                              : null
                            : null
                          : null}
                        <Col xs={12}>
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            Comentarios : {formState.comentarios}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className={`${stylesConfirmacion.col_cancelarSi} d-flex justify-content-center`}
                      xs={12}
                    >
                      <button
                        type="button"
                        onClick={() => continuar()}
                        className={`${stylesConfirmacion.col_cancelarSiButton}`}
                      >
                        ACEPTAR
                      </button>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default FormularioCI;
