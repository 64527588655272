import React, { Component } from "react";
import { PulseLoader } from "react-spinners";
import PropTypes from "prop-types";
import axios from "axios";
import { isSafari, isIOS } from "react-device-detect";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { AvRadioGroup, AvRadio, AvForm } from "availity-reactstrap-validation";
import {
  Col,
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
import "moment/locale/es";
import classNames from "classnames/bind";

import DiagnosticosData from "data/Diagnosticos.json";
import FlowsData from "data/flows.json";
import campaigns from "data/campaigns.json";
import promociones from "data/promociones.json";
import ConfigAliados from "data/ConfigAliados.json";

import RoundInputTextDatosPaciente from "components/textInput/RoundInputTextDatosPaciente";

import stylesConfirmacion from "css/ConfirmacionStyles.module.css";
import stylesRadio from "components/RadioGroup/RadioGroup.module.css";

import sprite from "images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";
import Admiracion from "images/svg/confirmacion__admiracion.svg";
import { object } from "is_js";

let paraQuien = 1;
let isIntegration = false;
let appointmentConfirmed = false;

let stylesDatos = null;

const TXT_CLINICAJNH = "Selecciona tu clínica JNH";
const TXT_CONTACTO = "¿Dónde podemos contactarte?";
const TXT_CUPON = "Introduce Código de Cupón";
const TXT_DATOS_ACOMP = "Datos del 2° paciente (acompañante)";
const TXT_ENTERASTE = "¿Cómo te enteraste de nosotros?";
const TXT_QUIEN = "¿Cuál es tu nombre?";
const TXT_URL = "Confirmacion";

let canalPost = "Agenda2.0";
const comoSeEntero = [
  "Elige una opcion",
  "Aseguradora",
  "Busque en Internet",
  "Campaña Privada / Convenio",
  "E-mail",
  "Facebook",
  "Instagram",
  "Pagina de salauno",
  "Pasa la vista",
  "Radio",
  "Recomendado",
  "Referencia Medica / Guardianes",
  "TV",
  "Vi la clinica",
];

const YellowCheckBox = withStyles({
  root: {
    color: "#10798b",
    "&$checked": {
      color: "#10798b",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const mediosAvailable = [
  "Instagram",
  "IGA",
  "IG",
  "Facebook",
  "FBA",
  "FB",
  "Twitter",
  "Google",
  "Email",
  "GDLV",
  "SMS",
  "RCS",
  "DSP",
  "Chatbot",
  "CitaDeseada",
  "Campanas",
];
let mediosCampaign = {
  Instagram: {
    campaign: "C-DIG-IG-001",
    medio: "Instagram",
  },
  IG: {
    campaign: "C-DIG-IG-001",
    medio: "Instagram",
  },
  IGA: {
    campaign: "C-DIG-IGAD-001",
    medio: "Instagram Ad",
  },
  Facebook: {
    campaign: "C-DIG-FB-001",
    medio: "Facebook",
  },
  FB: {
    campaign: "C-DIG-FB-001",
    medio: "Facebook",
  },
  FBA: {
    campaign: "C-DIG-FBAD-001",
    medio: "Facebook Ad",
  },
  Twitter: {
    campaign: "C-DIG-TW-001",
    medio: "Twitter",
  },
  Google: {
    campaign: "C-DIG-GOOGLEMB-001",
    medio: "Google My Business",
  },
  // GoogleAdd:{
  //   campaign: 'C-DIG-GOOGLEMB-001',
  //   medio: 'Google Add',
  // },
  Email: {
    campaign: "C-DIG-EMAIL-001",
    medio: "Email",
  },
  SMS: {
    campaign: "C-DIG-SMS-001",
    medio: "SMS",
  },
  RCS: {
    campaign: "C-DIG-RCS-001",
    medio: "RCS",
  },
  CitaDeseada: {
    campaign: "C-DIG-SEO-001",
    medio: "CitaDeseada",
  },
  SEO: {
    campaign: "C-DIG-SEO-001",
    medio: "SEO",
  },
  DSP: {
    campaign: "C-DIG-DSP-001",
    medio: "DSP",
  },
  Chatbot: {
    campaign: "C-DIG-FBCHATBOT-001",
    medio: "Chatbot",
  },
  Campanas: {
    medio: "Campanas",
    campaign: "",
  },
  Aseguradoras: {
    medio: "Aseguradoras",
    campaign: "",
  },
};

const validCampaigns = Object.keys(campaigns);

let cx = null;
let indexDiagnostic;
let medioEncontrado;

let medioConfigAliadosGral;

export default class DatosPaciente extends Component {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[DatosPaciente] History`,
        value: props.location.state,
        important: true,
      });
    }
    let apellidoPaterno, apellidoMaterno;
    if (props.location.state.infoPx.length > 0) {
      console.log(
        "[DatosPaciente] Apellido1:",
        props.location.state.infoPx[0].apellidoP1
      );
      const words = props.location.state.infoPx[0].apellidoP1.split(" ");
      [apellidoPaterno = "", apellidoMaterno = ""] = words;
      console.log("[DatosPaciente] History:", props.location.state);
      console.log("Apellido Pat:", apellidoPaterno);
      console.log("Apellido Mat:", apellidoMaterno);
    }

    let { urlParams, para, gdlvCampaign, edadPromocion, diagnostico } =
      props.location.state;

    medioEncontrado =
      urlParams.utm_source !== null
        ? urlParams.utm_source
        : urlParams.medio !== null && urlParams.medio;

    paraQuien = para.paraQuien;
    paraQuien = props.location.state.para.paraQuien;
    const baseUrl =
      window.location.hostname === "citas.salauno.com.mx"
        ? "https://agenda.salauno.com.mx/salauno"
        : "https://salauno-agenda-new-erika.azurewebsites.net/salauno";

    const baseUrlSalesforce =
      window.location.hostname === "citas.salauno.com.mx"
        ? "https://salauno-nodeserver.azurewebsites.net/salesforce"
        : "https://salauno-nodeserver-testing.azurewebsites.net/salesforce";

    let estiloEnteraste = null;
    let campaign = null;
    let medioPost = "SEO"; //'SEO'
    console.log("medioEncontrado:", medioEncontrado);
    console.log("urlParams.medio:", urlParams.medio);
    console.log("urlParams.utm_source:", urlParams.utm_source);
    console.log("urlParams.promo:", urlParams.promo);
    console.log("urlParams.utm_campaign", urlParams.utm_campaign);
    console.log("urlParams.utm_medium:", urlParams.utm_medium);
    let parametros = new URLSearchParams(window.location.search);
    let medioparams = parametros.get('medio');
    console.log(medioparams);
    let ConfigAliadosParams;
    let medioConfigAliados;
    //ConfigAliadosParams = ConfigAliados[medioparams].parameter;
    console.log("ConfigAliadosParams:", ConfigAliadosParams);
    let mediosCampaignsAvailableConfigAliados;
    if (ConfigAliados[medioparams] !== undefined) {//checar si es undefined
      medioConfigAliados = ConfigAliados[urlParams.medio].medioGral;
      medioConfigAliadosGral = ConfigAliados[urlParams.medio].medioGral;
      mediosCampaignsAvailableConfigAliados = ConfigAliados[urlParams.medio].mediosCampaignsAvailable;

      if (urlParams.medio !== null || urlParams.utm_source !== null) {
        if (urlParams.promo !== null) {
          const {
            mediosCampaigns,
            mediosCampaignsAvailable,
            promocionParaDiferentesEdades,
            EdadCorte,
          } = ConfigAliados[urlParams.promo];
          console.log("promociones:", ConfigAliados[urlParams.promo]);
          console.log("mediosCampaignsAvailable", mediosCampaignsAvailable);
          console.log("mediosCampaigns", mediosCampaigns);
          let auxEdad;
          if (mediosCampaignsAvailable.includes(medioConfigAliados)) {
            if (promocionParaDiferentesEdades) {
              if (edadPromocion >= EdadCorte) {
                auxEdad = "Mayor";
              } else {
                auxEdad = "Menor";
              }
              mediosCampaigns[medioConfigAliados].map((campana) => {
                if (campana.Edad === auxEdad) {
                  campaign = campana.campaign;
                }
              });
            } else {
              console.log("Entra aquiq ");
              campaign = ConfigAliados[urlParams.promo].promoPara;
            }
          }



          if (mediosCampaignsAvailable.includes(urlParams.utm_source)) {
            if (promocionParaDiferentesEdades) {
              if (edadPromocion >= EdadCorte) {
                auxEdad = "Mayor";
              } else {
                auxEdad = "Menor";
              }
              mediosCampaigns[urlParams.medio].map((campana) => {
                if (campana.Edad === auxEdad) {
                  campaign = campana.campaign;
                }
              });
            } else {
              campaign = mediosCampaigns[urlParams.utm_source].campaign;
            }
          }

          if (promocionParaDiferentesEdades) {
            mediosCampaigns[urlParams.medio].map((campana) => {
              if (campana.Edad >= EdadCorte) {
                medioPost = campana.medio;
              } else {
                medioPost = campana.medio;
              }
            });
          } else {
            medioPost = mediosCampaigns[urlParams.utm_source]
              ? mediosCampaigns[urlParams.utm_source].medio
              : mediosCampaigns[urlParams.medio] &&
              mediosCampaigns[urlParams.medio].medio;
          }
        } else {
          isIntegration = true;

          const medioURL =
            urlParams.utm_source !== null
              ? urlParams.utm_source.charAt(0).toUpperCase() +
              urlParams.utm_source.slice(1)
              : urlParams.medio !== null &&
              urlParams.medio.charAt(0).toUpperCase() +
              urlParams.medio.slice(1);

          const { gdlvCampaign } = props.location.state;
          campaign = gdlvCampaign;
          let nuevoMedio = ConfigAliados[medioparams].medioGral;
          let nuevaCampaña = {
            campaign: gdlvCampaign,
            medio: nuevoMedio,
          };

          mediosCampaign = {
            ...mediosCampaign,
            [nuevoMedio]: {
              campaign: gdlvCampaign,
              medio: nuevoMedio,
            },
          };
          //ConfigAliados[nuevoMedio] = nuevaCampaña;
          console.log("nuevacampaña:", nuevaCampaña);
          console.log("gdlvCampaign:", gdlvCampaign);

          if (medioURL === "Campanas") {
            console.log("viene por url de campañas");
            const { gdlvCampaign } = props.location.state;
            console.log("gdlvCampaign:", gdlvCampaign);
            campaign = gdlvCampaign;
            console.log("campaign:", campaign);
            mediosCampaign = {
              ...mediosCampaign,
              GDLV: {
                campaign: gdlvCampaign,
                medio: "Campanas",
              },
            };
            console.log("mediosCampaign:", mediosCampaign);
          }

          console.log("VALOR DE gdlvCampaign : ", gdlvCampaign);
          if (mediosAvailable.includes(medioURL)) {
            campaign = mediosCampaign[medioURL].campaign;
          }

          medioPost = mediosCampaign[medioURL] && mediosCampaign[medioURL].medio;

          if (medioURL === "GDLV-P") {
            campaign = gdlvCampaign;
            medioPost = "GDLV-P";
            canalPost = "Prosperia";
          }

          if (medioURL === "GDLV-Optometria") {
            campaign = gdlvCampaign;
            medioPost = "GDLV";
            canalPost = "GDLV-Optometria";
          }

          if (medioURL === "GeneralCampanas") {
            campaign = gdlvCampaign;
            medioPost = "GDLV";
            canalPost = "GeneralCampanas";
          }

          if (medioURL === "GeneralCampanas" && !gdlvCampaign.includes("GDLV")) {
            console.log("Dentro de GeneralCampanas : ");
            campaign = gdlvCampaign;
            medioPost = "Campaña";
            canalPost = "GeneralCampanas";
          }

          if (medioURL === "SHCP") {
            campaign = gdlvCampaign;
            medioPost = "SHCP";
            canalPost = "SHCP";
          }

          if (medioURL === "Campanas") {
            campaign = gdlvCampaign;
            medioPost = "Campanas";
            canalPost = "Agenda2.0";
          }
        }
      } else {
        campaign = mediosCampaign["SEO"].campaign;
      }
    }
    else {
      if (urlParams.medio !== null || urlParams.utm_source !== null) {
        if (urlParams.promo !== null) {
          const {
            mediosCampaigns,
            mediosCampaignsAvailable,
            promocionParaDiferentesEdades,
            EdadCorte,
          } = promociones[urlParams.promo];

          let auxEdad;

          if (mediosCampaignsAvailable.includes(urlParams.medio)) {
            if (promocionParaDiferentesEdades) {
              if (edadPromocion >= EdadCorte) {
                auxEdad = "Mayor";
              } else {
                auxEdad = "Menor";
              }
              mediosCampaigns[urlParams.medio].map((campana) => {
                if (campana.Edad === auxEdad) {
                  campaign = campana.campaign;
                }
              });
            } else {
              campaign = mediosCampaigns[urlParams.medio].campaign;
            }
          }

          if (mediosCampaignsAvailable.includes(urlParams.utm_source)) {
            if (promocionParaDiferentesEdades) {
              if (edadPromocion >= EdadCorte) {
                auxEdad = "Mayor";
              } else {
                auxEdad = "Menor";
              }
              mediosCampaigns[urlParams.medio].map((campana) => {
                if (campana.Edad === auxEdad) {
                  campaign = campana.campaign;
                }
              });
            } else {
              campaign = mediosCampaigns[urlParams.utm_source].campaign;
            }
          }

          if (promocionParaDiferentesEdades) {
            mediosCampaigns[urlParams.medio].map((campana) => {
              if (campana.Edad >= EdadCorte) {
                medioPost = campana.medio;
              } else {
                medioPost = campana.medio;
              }
            });
          } else {
            medioPost = mediosCampaigns[urlParams.utm_source]
              ? mediosCampaigns[urlParams.utm_source].medio
              : mediosCampaigns[urlParams.medio] &&
              mediosCampaigns[urlParams.medio].medio;
          }
        } else {
          isIntegration = true;

          const medioURL =
            urlParams.utm_source !== null
              ? urlParams.utm_source.charAt(0).toUpperCase() +
              urlParams.utm_source.slice(1)
              : urlParams.medio !== null &&
              urlParams.medio.charAt(0).toUpperCase() +
              urlParams.medio.slice(1);
          // const medioURL =
          //   urlParams.medio.charAt(0).toUpperCase() + urlParams.medio.slice(1);
          if (
            medioURL === "GDLV" ||
            medioURL === "GDLV-P" ||
            medioURL === "Azucar" ||
            medioURL === "C-CLIVI24" ||
            medioURL === "Metro" ||
            medioURL === "GeneralCampanas" ||
            medioURL === "SHCP" ||
            medioURL === "GDLV-Optometria"
          ) {
            const { gdlvCampaign } = props.location.state;
            campaign = gdlvCampaign;
            mediosCampaign = {
              ...mediosCampaign,
              GDLV: {
                campaign: gdlvCampaign,
                medio: "GDLV",
              },
              Azucar: {
                campaign: gdlvCampaign,
                medio: "Azucar",
              },
              Metro: {
                campaign: gdlvCampaign,
                medio: "Metro",
              },
              SHCP: {
                campaign: gdlvCampaign,
                medio: "SHCP",
              },
              CLIVI: {
                campaign: gdlvCampaign,
                medio: "C-CLIVI24",
              },
            };
          }


          if (medioURL === "Campanas") {
            console.log("viene por url de campañas");
            const { gdlvCampaign } = props.location.state;
            console.log("gdlvCampaign:", gdlvCampaign);
            campaign = gdlvCampaign;
            console.log("campaign:", campaign);
            mediosCampaign = {
              ...mediosCampaign,
              GDLV: {
                campaign: gdlvCampaign,
                medio: "Campanas",
              },
            };
            console.log("mediosCampaign:", mediosCampaign);
          }

          console.log("VALOR DE gdlvCampaign : ", gdlvCampaign);
          if (mediosAvailable.includes(medioURL)) {
            campaign = mediosCampaign[medioURL].campaign;
          }

          medioPost = mediosCampaign[medioURL] && mediosCampaign[medioURL].medio;

          if (medioURL === "GDLV-P") {
            campaign = gdlvCampaign;
            medioPost = "GDLV-P";
            canalPost = "Prosperia";
          }

          if (medioURL === "GDLV-Optometria") {
            campaign = gdlvCampaign;
            medioPost = "GDLV";
            canalPost = "GDLV-Optometria";
          }

          if (medioURL === "GeneralCampanas") {
            campaign = gdlvCampaign;
            medioPost = "GDLV";
            canalPost = "GeneralCampanas";
          }

          if (medioURL === "GeneralCampanas" && !gdlvCampaign.includes("GDLV")) {
            console.log("Dentro de GeneralCampanas : ");
            campaign = gdlvCampaign;
            medioPost = "Campaña";
            canalPost = "GeneralCampanas";
          }

          if (medioURL === "SHCP") {
            campaign = gdlvCampaign;
            medioPost = "SHCP";
            canalPost = "SHCP";
          }

          if (medioURL === "Campanas") {
            campaign = gdlvCampaign;
            medioPost = "Campanas";
            canalPost = "Agenda2.0";
          }
        }
      } else {
        campaign = mediosCampaign["SEO"].campaign;
      }
    }

    if (urlParams.gclid !== null) {
      campaign = "C-DIG-SEM-001";
      // medioPost = "SEM";
    }

    console.log("urlParams.cmp:", urlParams.cmp);
    if(ConfigAliados[medioparams] !== undefined){
      console.log("si entra aca");
      if (urlParams.cmp !== null && validCampaigns.includes(urlParams.cmp)) {
        const {
          mediosCampaigns,
          mediosCampaignsAvailable,
          promocionParaDiferentesEdades,
          EdadCorte,
        } = ConfigAliados[urlParams.promo];
        let auxEdad;
        if (mediosCampaignsAvailable.includes(medioConfigAliados)) {
          if (promocionParaDiferentesEdades) {
            if (edadPromocion >= EdadCorte) {
              auxEdad = "Mayor";
            } else {
              auxEdad = "Menor";
            }
            mediosCampaigns[urlParams.medio].map((campana) => {
              if (campana.Edad === auxEdad) {
                campaign = campana.campaign;
              }
            });
          } else {
            console.log("Entra aquiq ");
              campaign = ConfigAliados[urlParams.promo].promoPara;//mediosCampaigns[urlParams.utm_source].promoPara
          }
        }
        if (mediosCampaignsAvailable.includes(urlParams.utm_source)) {
          if (promocionParaDiferentesEdades) {
            if (edadPromocion >= EdadCorte) {
              auxEdad = "Mayor";
            } else {
              auxEdad = "Menor";
            }
            mediosCampaigns[urlParams.medio].map((campana) => {
              if (campana.Edad === auxEdad) {
                campaign = campana.campaign;
              }
            });
          } else {
            campaign = mediosCampaigns[urlParams.utm_source].promoPara;
          }
        }
      }
    }
    else{
      if (urlParams.cmp !== null && validCampaigns.includes(urlParams.cmp)) {
        const {
          mediosCampaigns,
          mediosCampaignsAvailable,
          promocionParaDiferentesEdades,
          EdadCorte,
        } = promociones[urlParams.promo];
        let auxEdad;
        if (mediosCampaignsAvailable.includes(urlParams.medio)) {
          if (promocionParaDiferentesEdades) {
            if (edadPromocion >= EdadCorte) {
              auxEdad = "Mayor";
            } else {
              auxEdad = "Menor";
            }
            mediosCampaigns[urlParams.medio].map((campana) => {
              if (campana.Edad === auxEdad) {
                campaign = campana.campaign;
              }
            });
          } else {
            campaign = mediosCampaigns[urlParams.medio].campaign;
          }
        }
        if (mediosCampaignsAvailable.includes(urlParams.utm_source)) {
          if (promocionParaDiferentesEdades) {
            if (edadPromocion >= EdadCorte) {
              auxEdad = "Mayor";
            } else {
              auxEdad = "Menor";
            }
            mediosCampaigns[urlParams.medio].map((campana) => {
              if (campana.Edad === auxEdad) {
                campaign = campana.campaign;
              }
            });
          } else {
            campaign = mediosCampaigns[urlParams.utm_source].campaign;
          }
        }
      }
    }
    

    if (isSafari || isIOS) {
      stylesDatos = require("css/DatosPacienteStyles.module.css");
    } else {
      stylesDatos = require("css/DatosPacienteStyles.module.css");
    }
    cx = classNames.bind(stylesDatos);
    estiloEnteraste = stylesDatos.datosEnteraste;

    moment.locale("es");
    console.log(
      "Valor del props props.location.state.infoPx[0]:",
      props.location.state.infoPx[0]
    );

    this.state = {
      urlParams: urlParams,

      patientName:
        urlParams.firstName !== null
          ? urlParams.firstName
          : props.location.state.infoPx.length > 0
            ? props.location.state.infoPx[0].paciente1
            : "",

      patientLastname1:
        urlParams.lastNamePat !== null
          ? urlParams.lastNamePat
          : urlParams.lastNamePat === null
            ? apellidoPaterno
            : "",

      patientLastname2:
        urlParams.lastNameMat !== null
          ? urlParams.lastNameMat
          : urlParams.lastNameMat === null
            ? apellidoMaterno
            : "",

      gender: urlParams.genero !== null ? urlParams.genero : "*Género",

      email:
        urlParams.email !== null
          ? urlParams.email
          : props.location.state.infoPx.length > 0
            ? props.location.state.infoPx[0].email
            : "",
      phone:
        urlParams.cel !== null
          ? urlParams.cel
          : props.location.state.infoPx.length > 0
            ? props.location.state.infoPx[0].telefono
            : "",
      phoneHome:
        urlParams.tel !== null
          ? urlParams.tel
          : props.location.state.infoPx.length > 0
            ? props.location.state.infoPx[0].telefonoCasa
            : "",

      diabetes:
        props.location.state.infoPx.length > 0
          ? props.location.state.infoPx[0].diabetes
          : "",

      hipertension:
        props.location.state.infoPx.length > 0
          ? props.location.state.infoPx[0].hipertencion
          : "",

      otras_enfermedades_sistematicas:
        props.location.state.infoPx.length > 0
          ? props.location.state.infoPx[0].otraenfermedad
          : "",

      comentarios_GDLV:
        props.location.state.infoPx.length > 0
          ? props.location.state.infoPx[0].comentarios_GDLV
          : "",

      motivo_de_la_cita:
        props.location.state.infoPx.length > 0
          ? props.location.state.infoPx[0].motivocita
          : "",

      cp: urlParams.cp !== null ? urlParams.cp : "",

      selectedDate:
        urlParams.fechaNacim !== null
          ? moment(urlParams.fechaNacim).format("YYYY-MM-DD").toString()
          : props.location.state.infoPx.length > 0
            ? moment(props.location.state.infoPx[0].fechaNacim)
              .format("YYYY-MM-DD")
              .toString()
            : "",
      mateName: "",
      mateLastname1: "",
      mateLastname2: "",
      genderAcompañante: "*Género",
      emailAcompañante: "",
      selectedDateAcompañante: null,
      phoneAcompañante: "",
      phoneHomeAcompañante: "",
      cpAcompañante: "",
      cupon: urlParams.cupon,
      enteraste: "",
      errorPhone: "",
      errorPhoneAcompañante: "",
      errorPhoneHome: "",
      errorPhoneHomeAcompañante: "",
      errorEmail: "",
      errorDias: "",
      errorEmailAcompañante: "",
      errorNacim: "",
      errorCupon: "",
      errorCP: "",
      errorCPAcompañante: "",
      loading: false,
      modalOcupada: false,
      modalErrorCupon: false,
      modalErrorGeneral: false,
      modalMenor40Promocion: false,
      modalPaseValido: false,
      errorModalPaseValido: "",
      estiloJNH: "datosJNH",
      jnhCampaign: "",
      jnhClinic: "",
      estiloEnteraste,
      campaign,
      medioPost,
      termsCond: "no",
      tieneCorreo: "no",
      tieneTelefono: "no",
      dropdownGender: false,
      dropdownGenderAcompañante: false,
      baseUrl,
      baseUrlSalesforce,
      clickContinuar: false,
      como_se_entero_de_nosotros: null,
      GDLV_refiere_por:
        urlParams.medio !== "GDLV-P" || urlParams.utm_source !== "GDLV-P"
          ? ""
          : "Abrasion corneal",
      GDLV_refiere_a: "",
      clinica_externa: "Seleccionar",
      numero_autorizacion: "",
      folio_de_solicitud_de_servicio: "",
      fecha_pase_fin_vigencia: "",
      fecha_pase_inicio_vigencia: "",
      dias_restantes_vigencia: "",
      diagnostico,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.handleWindowSizeChange);

    if (appointmentConfirmed) {
      window.location.reload(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  aceptoPrivacidadTerminos = () => {
    let { termsCond } = this.state;

    termsCond = termsCond === "no" ? "yes" : "no";

    this.setState({ termsCond });
  };

  proporcionoCorreoPaciente = () => {
    let { tieneCorreo, email } = this.state;

    if (email !== "") {
      tieneCorreo = "no";
    } else {
      tieneCorreo = tieneCorreo === "no" ? "yes" : "no";
    }
    this.setState({ tieneCorreo, email });
  };

  proporcionoTelPaciente = () => {
    let { tieneTelefono, phone } = this.state;

    if (phone !== "") {
      tieneTelefono = "no";
    } else {
      tieneTelefono = tieneTelefono === "no" ? "yes" : "no";
    }
    this.setState({ tieneTelefono, phone });
  };

  toggleDropdownGender = () => {
    this.setState((prevState, props) => ({
      dropdownGender: !prevState.dropdownGender,
    }));
  };
  toggleDropdownGenderAcompañante = () => {
    this.setState((prevState, props) => ({
      dropdownGenderAcompañante: !prevState.dropdownGenderAcompañante,
    }));
  };
  getObjectByService = (nameService) => {
    return Object.keys(DiagnosticosData).find(
      (key) => DiagnosticosData[key].nombreServicio === nameService
    );
  };
  getGeneralInformation() {
    if (paraQuien === 3) {
      return (
        <div>
          <Row className={`${stylesDatos.row_TusDatos}`}>
            <Col className={`${stylesDatos.col_Tuyos}`} xs={{ size: 12 }}>
              <label className={`${stylesDatos.quien} mx-auto`}>
                {TXT_QUIEN}
              </label>
            </Col>
          </Row>
          {this.getPatientInformation()}
          <Row className={`${stylesDatos.row_Contactarte}`}>
            <Col className={`${stylesDatos.col_DondeContac}`} xs={{ size: 12 }}>
              <label className={`${stylesDatos.quien} mx-auto`}>
                {TXT_CONTACTO}
              </label>
            </Col>
          </Row>
          {this.getContactInformation()}
          <Row className={`${stylesDatos.row_LosDatosDe}`}>
            <Col className={`${stylesDatos.col_Quien}`} xs={{ size: 12 }}>
              <label className={`${stylesDatos.quien} mx-auto`}>
                {TXT_DATOS_ACOMP}
              </label>
            </Col>
          </Row>
          <Row className={`${stylesDatos.row_TusDatos}`}>
            <Col className={`${stylesDatos.col_Tuyos}`} xs={{ size: 12 }}>
              <label className={`${stylesDatos.quien} mx-auto`}>
                {TXT_QUIEN}
              </label>
            </Col>
          </Row>
          {this.getMateInformation()}
          <Row className={`${stylesDatos.row_Contactarte}`}>
            <Col className={`${stylesDatos.col_DondeContac}`} xs={{ size: 12 }}>
              <label className={`${stylesDatos.quien} mx-auto`}>
                {TXT_CONTACTO}
              </label>
            </Col>
          </Row>
          {this.getContactInformationAcompañante()}
        </div>
      );
    }
    return this.getPatientInformation();
  }

  getContactInformationAcompañante() {
    const {
      errorPhoneAcompañante,
      errorEmailAcompañante,
      errorPhoneHomeAcompañante,
      phoneAcompañante,
      phoneHomeAcompañante,
      emailAcompañante,
    } = this.state;
    return (
      <React.Fragment>
        <Row className={`${stylesDatos.row_Donde}`}>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={`${stylesDatos.col_Cel}`}
          >
            <RoundInputTextDatosPaciente
              placeholder="Teléfono celular"
              titulo="Telefono celular *"
              etiqueta="Phone"
              value={phoneAcompañante}
              errorText={errorPhoneAcompañante}
              id="PhoneAcompañante"
              onChange={(input) => this.changePhoneAcompañante(input)}
            />
          </Col>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={`${stylesDatos.col_txtAp}`}
          >
            <RoundInputTextDatosPaciente
              placeholder="Teléfono fijo"
              titulo="Telefono fijo"
              etiqueta="Phone"
              value={phoneHomeAcompañante}
              errorText={errorPhoneHomeAcompañante}
              id="PhoneHomeAcompañante"
              onChange={(input) => this.changePhoneHomeAcompañante(input)}
            />
          </Col>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={`${stylesDatos.col_txtAm}`}
          >
            <RoundInputTextDatosPaciente
              placeholder="Correo electrónico"
              titulo="Correo electrónico *"
              etiqueta="Email"
              value={emailAcompañante}
              id="Email"
              errorText={errorEmailAcompañante}
              onChange={(input) => this.changeEmailAcompañante(input)}
              handleKeyDP={this.handleKeyPress}
            />
          </Col>
          <Col
            xs={{ size: 12 }}
            className={`${stylesDatos.CObligatorioContainer}`}
          >
            <span>*Campos obligatorios</span>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  getContactInformation() {
    const { location } = this.props;
    const { state } = location;
    const { urlParams } = state;
    const {
      errorPhone,
      errorEmail,
      errorPhoneHome,
      phone,
      phoneHome,
      email,
      tieneCorreo,
      tieneTelefono,
    } = this.state;
    return (
      <>
        <Row className={`${stylesConfirmacion.rowContainerInfo}`}>
          <Col
            md={{ size: 4 }}
            className={`${stylesConfirmacion.colContainerInfo}`}
          >
            <RoundInputTextDatosPaciente
              placeholder="Teléfono celular"
              titulo="Teléfono celular *"
              etiqueta="Phone"
              value={phone}
              errorText={errorPhone}
              id="Phone"
              onChange={(input) => this.changePhone(input)}
            />
          </Col>
          <Col
            md={{ size: 4 }}
            className={`${stylesConfirmacion.colContainerInfo}`}
          >
            <RoundInputTextDatosPaciente
              placeholder="Teléfono fijo"
              titulo="Teléfono fijo"
              etiqueta="Phone"
              value={phoneHome}
              errorText={errorPhoneHome}
              id="PhoneHome"
              onChange={(input) => this.changePhoneHome(input)}
            />
          </Col>
          <Col
            md={{ size: 4 }}
            className={`${stylesConfirmacion.colContainerInfo}`}
          >
            <RoundInputTextDatosPaciente
              placeholder="Correo electrónico"
              titulo="Correo electrónico *"
              etiqueta="Email"
              value={email}
              id="Email"
              errorText={errorEmail}
              onChange={(input) => this.changeEmail(input)}
              handleKeyDP={this.handleKeyPress}
            />
          </Col>
        </Row>
        <Row
          className={`${stylesConfirmacion.rowContainerInfoCorreoProporcionado}`}
        >
          {urlParams.isContactCenter === "true" && (
            <>
              <Col
                md={{ size: 6 }}
                className={`${stylesConfirmacion.colContainerInfo}`}
              >
                <FormGroup row className="justify-content-center">
                  <FormControlLabel
                    control={
                      <YellowCheckBox
                        checked={tieneTelefono === "yes"}
                        onChange={this.proporcionoTelPaciente}
                        name="tieneTelCheck"
                      />
                    }
                    label={
                      <span className={`${stylesConfirmacion.font_info_14}`}>
                        Teléfono no proporcionado
                      </span>
                    }
                  />
                </FormGroup>
              </Col>
              <Col
                md={{ size: 6 }}
                className={`${stylesConfirmacion.colContainerInfo}`}
              >
                <FormGroup row className="justify-content-center">
                  <FormControlLabel
                    control={
                      <YellowCheckBox
                        checked={tieneCorreo === "yes"}
                        onChange={this.proporcionoCorreoPaciente}
                        name="tieneCorreoCheck"
                      />
                    }
                    label={
                      <span className={`${stylesConfirmacion.font_info_14}`}>
                        Correo electrónico no proporcionado
                      </span>
                    }
                  />
                </FormGroup>
              </Col>
            </>
          )}

          {(urlParams.medio === "GDLV-P" ||
            urlParams.utm_source === "GDLV-P") && (
              <>
                <Col
                  xs={12}
                  className={`${stylesConfirmacion.colContainerPorQueRefiere}`}
                >
                  <Row
                    className={`${stylesConfirmacion.rowContainerLabelPorQueRefiere}`}
                  >
                    <label
                      className={`${stylesConfirmacion.rowContainerLabelPorQueRefiere__label}`}
                    >
                      ¿Por que se esta refiriendo al paciente?
                    </label>
                  </Row>
                  <Row
                    className={`${stylesConfirmacion.rowContainerSelectPorQueRefiere}`}
                  >
                    <Col
                      xs={12}
                      className={`${stylesConfirmacion.colContainerSelectPadecimientos}`}
                    >
                      <select
                        className={`${stylesConfirmacion.rowContainerSelectPorQueRefiere__select}`}
                        onChange={(e) => this.handleChangeGDLVRefierePor(e)}
                      >
                        <option value="Abrasion corneal">Abrasion corneal</option>
                        <option value="Cuerpo extraño">Cuerpo extraño</option>
                        <option value="Absceso corneal">Absceso corneal</option>
                        <option value="Agujero macular">Agujero macular</option>
                        <option value="Ambliopia">Ambliopia</option>
                        <option value="Astigmatismo mixto">
                          Astigmatismo mixto
                        </option>
                        <option value="Astigmatismo hipermetrópico compuesto">
                          Astigmatismo hipermetrópico compuesto
                        </option>
                        <option value="Astigmatismo hipermetrópico simple">
                          Astigmatismo hipermetrópico simple
                        </option>
                        <option value="Astigmatismo miópico simple">
                          Astigmatismo miópico simple
                        </option>
                        <option value="Astigmatismo miópico compuesto">
                          Astigmatismo miópico compuesto
                        </option>
                        <option value="Catarata">Catarata</option>
                        <option value="Chalazión">Chalazión</option>
                        <option value="Conjuntivitis">Conjuntivitis</option>
                        <option value="Degeneración macular relacionada con la edad">
                          Degeneración macular relacionada con la edad
                        </option>
                        <option value="Desprendimiento de retina">
                          Desprendimiento de retina
                        </option>
                        <option value="Edema macular">Edema macular</option>
                        <option value="Edema palpebral">Edema palpebral</option>
                        <option value="Hemoragia vitrea">Hemoragia vitrea</option>
                        <option value="Lesiones en cornea">
                          Lesiones en cornea
                        </option>
                        <option value="Herida palpebral">Herida palpebral</option>
                        <option value="Dolor ocular">Dolor ocular</option>
                        <option value="Hipema">Hipema</option>
                        <option value="Hipermetrópia">Hipermetrópia</option>
                        <option value="Miopía">Miopía</option>
                        <option value="LIO de CA">LIO de CA</option>
                        <option value="Lesion predisponente">
                          Lesion predisponente
                        </option>
                        <option value="Orzuelo">Orzuelo</option>
                        <option value="Ojo seco">Ojo seco</option>
                        <option value="Oclusión de via lagrimal">
                          Oclusión de via lagrimal
                        </option>
                        <option value="Ojo unico funcional">
                          Ojo unico funcional
                        </option>
                        <option value="Otro problema en retina">
                          Otro problema en retina
                        </option>
                        <option value="Pinguecula">Pinguecula</option>
                        <option value="Papila sospechosa">
                          Papila sospechosa
                        </option>
                        <option value="Presbicia">Presbicia</option>
                        <option value="Pseudofaquia">Pseudofaquia</option>
                        <option value="Pterigion">Pterigion</option>
                        <option value="Ptisis bulbi">Ptisis bulbi</option>
                        <option value="Ptosis">Ptosis</option>
                        <option value="Queratoconjuntivitis">
                          Queratoconjuntivitis
                        </option>
                        <option value="Queratocono">Queratocono</option>
                        <option value="Retinipatia diabetica">
                          Retinipatia diabetica
                        </option>
                        <option value="Simblefaron">Simblefaron</option>
                        <option value="Sospecha de glaucoma">
                          Sospecha de glaucoma
                        </option>
                        <option value="Trauma ocular">Trauma ocular</option>
                        <option value="Ulcera corneal">Ulcera corneal</option>
                        <option value="Anisometropia">Anisometropia</option>
                        <option value="Estrabismo">Estrabismo</option>
                        <option value="Actualización de lentes">
                          Actualización de lentes
                        </option>
                        <option value="Otro">Otro</option>
                      </select>
                    </Col>
                    {/* <Col
                xs={12}
                className={`${stylesConfirmacion.colContainerSelectRefiereA}`}>
                <Row
                  className={`${stylesConfirmacion.rowContainerLabelPorQueRefiere}`}>
                  <label
                    className={`${stylesConfirmacion.rowContainerLabelPorQueRefiere__label}`}>
                    El doctor refiere a:
                  </label>
                </Row>
                <Row>
                  <select
                    className={`${stylesConfirmacion.rowContainerSelectPorQueRefiere__select}`}
                    onChange={(e)=>this.handleChangeGDLVRefiereA(e)}
                    >
                    <option value='Referible a Retina'>Retina</option>
                    <option value='Referible a Consulta general'>Consulta general</option>
                    <option value='No referible'>No referible</option>
                  </select>
                </Row>
              </Col> */}
                  </Row>
                </Col>
                <Col
                  xs={12}
                  className={`${stylesConfirmacion.colContainerComentarios}`}
                >
                  <textarea
                    className={`${stylesConfirmacion.colContainerComentarios__textarea}`}
                    placeholder={`Sección de comentarios`}
                    onChange={(e) => this.handleChangeComentariosGDLV(e)}
                  />
                </Col>
              </>
            )}
        </Row>
      </>
    );
  }

  getPatientInformation() {
    const {
      selectedDate,
      dropdownGender,
      gender,
      errorCP,
      errorNacim,
      patientName,
      patientLastname1,
      patientLastname2,
      cp,
    } = this.state;

    return (
      <>
        <Row className={`${stylesConfirmacion.rowContainerInfo}`}>
          <Col md={4} className={`${stylesConfirmacion.colContainerInfo}`}>
            <RoundInputTextDatosPaciente
              placeholder="Nombre"
              titulo="Nombre *"
              etiqueta="Alfabetic"
              value={patientName}
              id="Nombre"
              onChange={(input) => this.changeName(input)}
            />
          </Col>
          <Col md={4} className={`${stylesConfirmacion.colContainerInfo}`}>
            <RoundInputTextDatosPaciente
              placeholder="Apellido paterno"
              titulo="Apellido paterno *"
              etiqueta="Alfabetic"
              value={patientLastname1}
              id="ApellidoPat"
              onChange={(input) => this.changeLastname1(input)}
            />
          </Col>
          <Col md={4} className={`${stylesConfirmacion.colContainerInfo}`}>
            <RoundInputTextDatosPaciente
              placeholder="Apellido materno"
              titulo="Apellido materno"
              etiqueta="Alfabetic"
              value={patientLastname2}
              id="ApellidoMat"
              onChange={(input) => this.changeLastname2(input)}
            />
          </Col>
        </Row>
        <Row className={`${stylesConfirmacion.rowContainerInfo}`}>
          <Col md={4} className={`${stylesConfirmacion.colContainerInfo}`}>
            <RoundInputTextDatosPaciente
              id="date-picker-inline"
              placeholder="Fecha"
              titulo="Fecha de nacimiento *"
              etiqueta="Fecha"
              value={selectedDate}
              onChange={(selectedDate) => this.changeBirthday(selectedDate)}
              errorText={errorNacim}
            />
          </Col>
          <Col md={4} className={`${stylesConfirmacion.colContainerInfo}`}>
            <span className={`${stylesConfirmacion.colContainerInfo__span}`}>
              Género *
            </span>
            <Dropdown
              isOpen={dropdownGender}
              toggle={this.toggleDropdownGender}
              className={stylesDatos.dropdown}
            >
              <DropdownToggle
                caret
                color="white"
                className={stylesDatos.toogleDropdown}
              >
                {gender}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.changeGender("Masculino")}>
                  Masculino
                </DropdownItem>
                <DropdownItem onClick={() => this.changeGender("Femenino")}>
                  Femenino
                </DropdownItem>
                <DropdownItem onClick={() => this.changeGender("Otro")}>
                  Otro
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col md={4} className={`${stylesConfirmacion.colContainerInfo}`}>
            <RoundInputTextDatosPaciente
              placeholder="CP"
              titulo="Código postal *"
              etiqueta="CP"
              value={cp}
              onChange={this.changeCP}
              errorText={errorCP}
            />
          </Col>
        </Row>
      </>
    );
  }

  getComoTeEnteraste() {
    const { estiloEnteraste } = this.state;
    return (
      <div>
        <Row className={`${stylesDatos.row_ParaQuien}`}>
          <Col
            className={`${stylesDatos.col_ParaQuien}`}
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
          >
            <label className={stylesDatos.quien}>{TXT_ENTERASTE}</label>
          </Col>
        </Row>
        <Row className={`${stylesDatos.row_DatosPaciente}`}>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={`${stylesDatos.col_Nombre}`}
          >
            <Input
              defaultValue=""
              onChange={this.changeEnteraste}
              type="select"
              name="enteraste"
              id="enteraste"
              className={estiloEnteraste}
            >
              <option value="" disabled />
              <option value="Campaña">Campaña</option>
              <option value="Internet">Internet</option>
              <option value="Médico/Guardián">Médico/Guardián</option>
              <option value="Otro">Otro</option>
              <option value="Publicidad Exterior">Publicidad Exterior</option>
              <option value="Recomendado por Amigos">
                Recomendado por Amigos
              </option>
              <option value="Recomendado por Familiar">
                Recomendado por Familiar
              </option>
              <option value="Redes Sociales">Redes Sociales</option>
              <option value="TV/Prensa/Radio">TV/Prensa/Radio</option>
            </Input>
          </Col>
        </Row>
      </div>
    );
  }

  getClinicaJNH() {
    const { estiloJNH } = this.state;
    return (
      <div>
        <Row className={`${stylesDatos.row_ParaQuien}`}>
          <Col
            className={`${stylesDatos.col_ParaQuien}`}
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
          >
            <label className={stylesDatos.quien}>{TXT_CLINICAJNH}</label>
          </Col>
        </Row>
        <Row className={`${stylesDatos.row_DatosPaciente}`}>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={`${stylesDatos.col_Nombre}`}
          >
            <Input
              defaultValue=""
              onChange={this.changeJNH}
              type="select"
              name="jnh"
              id="enteraste"
              className={`inputLabel ${estiloJNH}`}
            >
              <option value="" disabled />
              <option value="C-RED-JNH-001">Churubusco</option>
              <option value="C-RED-JNH-005">División del Norte</option>
              <option value="C-RED-JNH-003">Lilas</option>
              <option value="C-RED-JNH-006">Loreto</option>
              <option value="C-RED-JNH-002">Revolución</option>
              <option value="C-RED-JNH-004">Río Churubusco</option>
            </Input>
          </Col>
        </Row>
        <Row className={`${stylesDatos.row_DatosPaciente}`}>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={`${stylesDatos.col_Nombre}`}
          >
            <AvForm>
              <AvRadioGroup
                name="radioCustomInputExample"
                className={stylesDatos.turnoJNH}
                label=""
              >
                <AvRadio
                  className={stylesRadio.respuestasCuestionario}
                  customInput
                  label="Semanal"
                  value="Semanal"
                />
                <AvRadio
                  className={stylesRadio.respuestasCuestionario}
                  customInput
                  label="Fin de Semana"
                  value="Fin de Semana"
                />
              </AvRadioGroup>
            </AvForm>
          </Col>
        </Row>
      </div>
    );
  }

  getInfoJNH() {
    // TODO
    const { estiloJNH } = this.state;
    return (
      <div>
        <Row className={`${stylesDatos.row_ParaQuien}`}>
          <Col
            className={`${stylesDatos.col_ParaQuien}`}
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
          >
            <label className={stylesDatos.quien}>{TXT_CLINICAJNH}</label>
          </Col>
        </Row>
        <Row className={`${stylesDatos.row_DatosPaciente}`}>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={`${stylesDatos.col_Nombre}`}
          >
            <Input
              defaultValue=""
              onChange={this.changeJNH}
              type="select"
              name="jnh"
              id="enteraste"
              className={`inputLabel ${estiloJNH}`}
            >
              <option value="" disabled />
              <option value="C-RED-JNH-001">Churubusco</option>
              <option value="C-RED-JNH-005">División del Norte</option>
              <option value="C-RED-JNH-003">Lilas</option>
              <option value="C-RED-JNH-006">Loreto</option>
              <option value="C-RED-JNH-002">Revolución</option>
              <option value="C-RED-JNH-004">Río Churubusco</option>
            </Input>
          </Col>
        </Row>
        <Row className={`${stylesDatos.row_DatosPaciente}`}>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={`${stylesDatos.col_Nombre}`}
          >
            <FormGroup>
              <Label for="exampleText">Motivo de Consulta</Label>
              <Input
                type="textarea"
                name="text"
                id="exampleText"
                className={`inputLabel ${estiloJNH}`}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }

  getMateInformation() {
    const {
      mateName,
      mateLastname1,
      mateLastname2,
      selectedDateAcompañante,
      cpAcompañante,
      genderAcompañante,
      errorCPAcompañante,
      errorNacim,
      dropdownGenderAcompañante,
    } = this.state;
    return (
      <React.Fragment>
        <Row className={cx("row_DatosPaciente")}>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={cx({ col_Nombre: true })}
          >
            <RoundInputTextDatosPaciente
              placeholder="Nombre"
              etiqueta="Alfabetic"
              value={mateName}
              id="Nombre-Acompañante"
              onChange={(input) => this.changeMateName(input)}
            />
          </Col>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={cx({ col_txtAp: true })}
          >
            <RoundInputTextDatosPaciente
              placeholder="Apellido Paterno"
              etiqueta="Alfabetic"
              value={mateLastname1}
              id="ApellidoPat-Acompañante"
              onChange={(input) => this.changeMateLastname1(input)}
            />
          </Col>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={cx({ col_txtAm: true })}
          >
            <RoundInputTextDatosPaciente
              placeholder="Apellido Materno"
              etiqueta="Alfabetic"
              value={mateLastname2}
              id="ApellidoMat-Acompañante"
              onChange={(input) => this.changeMateLastname2(input)}
            />
          </Col>
        </Row>
        <Row className={cx("row_DatosPaciente")}>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={cx({ col_Nombre: true })}
          >
            <RoundInputTextDatosPaciente
              id="date-picker-inline-Acompañante"
              placeholder="*Fecha de nacimiento"
              etiqueta="Fecha"
              value={selectedDateAcompañante}
              onChange={(selectedDateAcompañante) =>
                this.changeBirthdayAcompañante(selectedDateAcompañante)
              }
              errorText={errorNacim}
            />
          </Col>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={cx({ col_txtAp: true })}
          >
            <RoundInputTextDatosPaciente
              placeholder="*C.P."
              etiqueta="CP"
              value={cpAcompañante}
              onChange={this.changeCPAcompañante}
              errorText={errorCPAcompañante}
            />
          </Col>
          <Col
            xs={{ size: 12 }}
            md={{ size: 4 }}
            lg={{ size: 4 }}
            className={cx({ col_txtAm: true })}
          >
            <Dropdown
              isOpen={dropdownGenderAcompañante}
              toggle={this.toggleDropdownGenderAcompañante}
              className={stylesDatos.dropdown}
            >
              <DropdownToggle
                caret
                color="white"
                className={stylesDatos.toogleDropdown}
              >
                {genderAcompañante}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.changeGenderAcompañante("Masculino")}
                >
                  Masculino
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.changeGenderAcompañante("Femenino")}
                >
                  Femenino
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.changeGenderAcompañante("Otro")}
                >
                  Otro
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  getForm() {
    const {
      medioPost,
      termsCond,
      errorCupon,
      cupon,
      urlParams,
      numero_autorizacion,
      folio_de_solicitud_de_servicio,
      fecha_pase_fin_vigencia,
      fecha_pase_inicio_vigencia,
      dias_restantes_vigencia,
      errorDias,
      diagnostico,
    } = this.state;
    if (paraQuien === 3) {
      // PARA MÍ Y ALGUIEN MÁS
      return <Container fluid>{this.getGeneralInformation()}</Container>;
    }
    // PARA MÍ / PARA ALGUIEN MÁS
    return (
      <>
        <Row className={`${stylesConfirmacion.rowContainerAllInformation}`}>
          <Col
            xs={12}
            className={`${stylesConfirmacion.colContainerAllInformation}`}
          >
            {medioPost === "JNH" ? this.getClinicaJNH() : null}
            {this.getGeneralInformation()}
            {this.getContactInformation()}
          </Col>
        </Row>
        {paraQuien !== 3 ? (
          <>
            <Row
              className={
                urlParams.medio === "Metro"
                  ? stylesConfirmacion.rowContainerPaseFolio
                  : null
              }
            >
              {/* <Col xs={medioEncontrado === false ? 6 : 12}>
                <Row className={`${stylesConfirmacion.rowContainerCuponTitle}`}>
                  <Col xs={{ size: 12 }}>
                    <label
                      className={`${stylesConfirmacion.colContainerCuponTitle__label}`}
                    >
                      {TXT_CUPON}
                    </label>
                  </Col>
                </Row>
                <Row className={`${stylesConfirmacion.rowContainerCupon}`}>
                  <Col xs={{ size: 12 }}>
                    <RoundInputTextDatosPaciente
                      placeholder="Introduce cupón"
                      titulo=""
                      etiqueta="Cupon"
                      value={cupon}
                      id="Cupon"
                      errorText={errorCupon}
                      onChange={(input) => this.changeCupon(input)}
                    />
                  </Col>
                </Row>
              </Col> */}
              {medioEncontrado === false && (
                <Col xs={12}>
                  <Row
                    className={`${stylesConfirmacion.rowContainerCuponTitle}`}
                  >
                    <Col
                      xs={{ size: 12 }}
                      className={`${stylesConfirmacion.titleSelectComoSeEntero} no-gutters`}
                    >
                      <label
                        className={`${stylesConfirmacion.colContainerCuponTitle__label}`}
                      >
                        ¿Como se entero de nosotros?*
                      </label>
                    </Col>
                  </Row>
                  <Row className={`${stylesConfirmacion.rowContainerCupon}`}>
                    <select
                      onChange={(e) => this.handleChageComoSeEntero(e)}
                      className={`${stylesConfirmacion.selectComoSeEntero}`}
                    >
                      {comoSeEntero.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </Row>
                </Col>
              )}
              {urlParams.medio === "Metro" &&
                diagnostico.descripcion !== "Actualización de lentes" && (
                  <>
                    <Col xs={12} lg={4} className={stylesConfirmacion.colPase}>
                      <Container>
                        <Row className={stylesConfirmacion.rowLabelTitle}>
                          <label htmlFor="clinica_externa">
                            Clínica externa *
                          </label>
                        </Row>
                        <Row className={stylesConfirmacion.rowInput}>
                          <Container style={{ width: "100%", height: "auto" }}>
                            <Row
                              style={{
                                justifyContent: "center",
                                height: "25px",
                              }}
                            >
                              <select
                                name="select"
                                onChange={(input) =>
                                  this.changeClinicaExterna(input)
                                }
                              >
                                <option value="Seleccionar" selected>
                                  Seleccionar
                                </option>
                                <option value="Gerencia médica">
                                  Gerencia médica
                                </option>
                                <option value="Taxqueña">Taxqueña</option>
                                <option value="Ticomán">Ticomán</option>
                                <option value="Zaragoza">Zaragoza</option>
                                <option value="Cuauhtémoc">Cuauhtémoc</option>
                                <option value="Hospital Obregón">
                                  Hospital Obregón
                                </option>
                              </select>
                            </Row>
                          </Container>
                          {/* <input
                          id="clinica_externa"
                          type="text"
                          placeholder="Ingrese aqui la clínica externa"
                          className={stylesConfirmacion.rowInput__input}
                          onChange={(input) => this.changeClinicaExterna(input)}
                          value={clinica_externa}
                        /> */}
                        </Row>
                      </Container>
                    </Col>
                    <Col xs={12} lg={4} className={stylesConfirmacion.colPase}>
                      <Container>
                        <Row className={stylesConfirmacion.rowLabelTitle}>
                          <label htmlFor="numeroAutorizacion">
                            Número de autorización *
                          </label>
                        </Row>
                        <Row className={stylesConfirmacion.rowInput}>
                          <input
                            id="numeroAutorizacion"
                            type="text"
                            placeholder="Ingrese numero de autorización"
                            className={stylesConfirmacion.rowInput__input}
                            onChange={(input) =>
                              this.changeNumeroAutorizacion(input)
                            }
                            value={numero_autorizacion}
                          />
                        </Row>
                      </Container>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      className={stylesConfirmacion.folioPase}
                    >
                      <Container>
                        <Row className={stylesConfirmacion.rowLabelTitle}>
                          <label htmlFor="folioSolicitudServicio">
                            Folio del pase *
                          </label>
                        </Row>
                        <Row className={stylesConfirmacion.rowInput}>
                          <input
                            id="folioSolicitudServicio"
                            type="text"
                            placeholder="Ingrese el folio del pase"
                            className={stylesConfirmacion.rowInput__input}
                            onChange={(input) => this.changeFolioPase(input)}
                            value={folio_de_solicitud_de_servicio}
                          />
                        </Row>
                      </Container>
                    </Col>
                    <Row className={stylesConfirmacion.rowFolioPase}>
                      <Col
                        xs={12}
                        lg={4}
                        className={stylesConfirmacion.folioPase}
                      >
                        <Container>
                          <Row className={stylesConfirmacion.rowLabelTitle}>
                            <label htmlFor="fechainicioexpedicionpase">
                              Inicio de vigencia del pase
                            </label>
                          </Row>
                          <Row className={stylesConfirmacion.rowInput}>
                            <input
                              id="fechainicioexpedicionpase"
                              type="date"
                              placeholder="Ingrese el inicio de vigencia del pase"
                              className={stylesConfirmacion.rowInput__input}
                              onChange={(input) =>
                                this.changeInicioExpedicionPase(input)
                              }
                              value={fecha_pase_inicio_vigencia}
                            />
                          </Row>
                        </Container>
                      </Col>
                      <Col
                        xs={12}
                        lg={4}
                        className={stylesConfirmacion.folioPase}
                      >
                        <Container>
                          <Row className={stylesConfirmacion.rowLabelTitle}>
                            <label htmlFor="diasrestantespase">
                              Dias de vigencia del pase
                            </label>
                          </Row>
                          <Row className={stylesConfirmacion.rowInput}>
                            <input
                              id="diasrestantespase"
                              type="number"
                              placeholder="Ingrese los dias de vigencia"
                              errorText={errorDias}
                              className={stylesConfirmacion.rowInput__input}
                              onChange={(input) => this.changeDiasPase(input)}
                              value={dias_restantes_vigencia}
                            />
                          </Row>
                        </Container>
                      </Col>
                      <Col
                        xs={12}
                        lg={4}
                        className={stylesConfirmacion.folioPase}
                      >
                        <Container>
                          <Row className={stylesConfirmacion.rowLabelTitle}>
                            <label htmlFor="fechafinexpedicionpase">
                              Fin de vigencia del pase *
                            </label>
                          </Row>
                          <Row className={stylesConfirmacion.rowInput}>
                            <input
                              id="fechafinexpedicionpase"
                              type="date"
                              placeholder="Ingrese el fin de vigencia del pase"
                              className={stylesConfirmacion.rowInput__input}
                              onChange={(input) =>
                                this.changeFinExpedicionPase(input)
                              }
                              value={fecha_pase_fin_vigencia}
                            />
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </>
                )}
            </Row>
          </>
        ) : null}
        <Col className="justify-content-center" xs={12}>
          <FormGroup row className="justify-content-center">
            <FormControlLabel
              control={
                <YellowCheckBox
                  checked={termsCond === "yes"}
                  onChange={this.aceptoPrivacidadTerminos}
                  name="termsCondCheck"
                />
              }
              label={
                <span className={`${stylesConfirmacion.font_info_14}`}>
                  He leído y acepto el{" "}
                  <a
                    href="https://www.salauno.com.mx/legales/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={stylesDatos.checkbox}
                  >
                    Aviso de Privacidad
                  </a>
                  <span> y </span>
                  <a
                    href="https://www.salauno.com.mx/legales/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={stylesDatos.checkbox}
                  >
                    Términos y Condiciones
                  </a>
                </span>
              }
            />
          </FormGroup>
        </Col>
        {/* {!isIntegration ? this.getComoTeEnteraste() : null} */}
        {medioPost === "JNH" ? this.getInfoJNH() : null}
      </>
    );
  }

  aceptarOcupada = () => {
    window.history.back();
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  onErrorNacim = (error, value) => {
    /* this.setState({
      errorNacim: 'Ingresa una fecha válida'
    })*/
    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[DatosPaciente] onErrorNacim`,
        value: {
          error,
          value,
        },
      });
    }
  };

  onNacimFocus = () => {
    this.setState({
      errorNacim: "",
    });
  };

  changeName = (patientName) => {
    this.setState({ patientName });
  };
  changeClinicaExterna = (e) => {
    const { campaign } = this.state;
    this.setState({ clinica_externa: e.target.value });
    switch (e.target.value) {
      case "Gerencia médica":
        this.setState({ campaign: "METRO-GERENCIA-MEDICA" });
        break;
      case "Taxqueña":
        this.setState({ campaign: "METRO-TAXQUENIA" });
        break;
      case "Ticomán":
        this.setState({ campaign: "METRO-TICOMAN" });
        break;
      case "Zaragoza":
        this.setState({ campaign: "METRO-ZARAGOZA" });
        break;
      case "Cuauhtémoc":
        this.setState({ campaign: "METRO-CUAUHTEMOC" });
        break;
      case "Hospital Obregón":
        this.setState({ campaign: "METRO-HOSPITAL-OBREGON" });
        break;
      default:
        return;
    }
    console.log("clinica:", e.target.value);
    console.log("campaign:", campaign);
  };

  changeNumeroAutorizacion = (e) => {
    this.setState({ numero_autorizacion: e.target.value });
  };

  changeFolioPase = (e) => {
    this.setState({ folio_de_solicitud_de_servicio: e.target.value });
  };

  changeFinExpedicionPase = (e) => {
    console.log("Fechas expedicón pase: ", e.target.value);
    this.setState({ fecha_pase_fin_vigencia: e.target.value });
  };
  changeInicioExpedicionPase = (e) => {
    console.log("Fechas expedicón pase: ", e.target.value);
    let finVigencia = new Date(e.target.value);
    const { dias_restantes_vigencia } = this.state;
    if (dias_restantes_vigencia !== "") {
      console.log("Tiene dias restantes pase: ", dias_restantes_vigencia);
      finVigencia.setDate(
        finVigencia.getDate() + Number(dias_restantes_vigencia.toString())
      );
    } else {
      console.log("Dias restantes pase vacio: ", dias_restantes_vigencia);
      finVigencia.setDate(finVigencia.getDate() + 90);
    }
    this.setState({
      fecha_pase_inicio_vigencia: e.target.value,
      fecha_pase_fin_vigencia: moment(finVigencia)
        .format("YYYY-MM-DD")
        .toString(),
    });
    console.log(
      "Fechas vigencia pase: ",
      moment(finVigencia).format("YYYY-MM-DD").toString()
    );
    console.log("Fechas vigencia pase: ", finVigencia.toString());
  };

  changeDiasPase = (e) => {
    console.log("Dias restantes pase: ", e.target.value);
    let diasPase = e.target.value;
    const { fecha_pase_inicio_vigencia } = this.state;
    let finVigencia = new Date(fecha_pase_inicio_vigencia);

    if (diasPase === "") {
      finVigencia.setDate(finVigencia.getDate() + 90);
      this.setState({
        fecha_pase_fin_vigencia: moment(finVigencia)
          .format("YYYY-MM-DD")
          .toString(),
        dias_restantes_vigencia: "",
      });
    } else if (diasPase > 90) {
      this.setState({
        errorDias: "El maximo de dias es de 90",
        dias_restantes_vigencia: "",
      });
    } else if (diasPase <= 0) {
      this.setState({
        errorDias: "Los dias deben ser mayores a 0",
        dias_restantes_vigencia: "",
      });
    } else {
      if (fecha_pase_inicio_vigencia !== "") {
        finVigencia.setDate(
          finVigencia.getDate() + Number(diasPase.toString())
        );
        this.setState({
          errorDias: "",
          dias_restantes_vigencia: diasPase,
          fecha_pase_fin_vigencia: moment(finVigencia)
            .format("YYYY-MM-DD")
            .toString(),
        });
      } else {
        this.setState({
          errorDias: "",
          dias_restantes_vigencia: diasPase,
        });
      }
    }
  };

  changeLastname1 = (patientLastname1) => {
    this.setState({ patientLastname1 });
  };

  changeLastname2 = (patientLastname2) => {
    this.setState({ patientLastname2 });
  };
  changeBirthdayAcompañante = (selectedDateAcompañante) => {
    let errorYear = "";
    let year = selectedDateAcompañante.substring(0, 4);
    let currentYear = new Date().getFullYear();
    if (process.env.NODE_ENV !== "production") {
      console.tron.log(
        `[DatosPaciente] @changeBirthday Acompañante year ${year}`
      );
    }
    if (process.env.NODE_ENV !== "production") {
      console.tron.log(
        `[DatosPaciente] @changeBirthdayAcompañante currentYear ${currentYear}`
      );
    }
    if (year > currentYear - 0 || year < currentYear - 120) {
      errorYear = "Año inválido";
    } else {
      errorYear = "";
    }
    this.setState({
      selectedDateAcompañante,
      errorNacim: errorYear,
    });
  };

  changeBirthday = (selectedDate) => {
    let errorYear = "";
    let year = selectedDate.substring(0, 4);
    let currentYear = new Date().getFullYear();
    let currentDate = new Date();
    let selectedFullDate = new Date(selectedDate);
    if (process.env.NODE_ENV !== "production") {
      console.tron.log(`[DatosPaciente] @changeBirthday year ${year}`);
    }
    if (process.env.NODE_ENV !== "production") {
      console.tron.log(
        `[DatosPaciente] @changeBirthday currentYear ${currentYear}`
      );
    }
    if (year > currentYear - 0 || year < currentYear - 120) {
      errorYear = "Año inválido";
    } else {
      // Crear una fecha de referencia para 8 años atrás desde el día actual
      const date8YearsAgo = new Date();
      date8YearsAgo.setFullYear(currentYear - 8);

      // Validar si la fecha seleccionada es mayor a 8 años
      if (selectedFullDate > date8YearsAgo) {
        errorYear = "Debe ser mayor de 8 años";
      } else {
        errorYear = "";
      }
    }
    this.setState({
      selectedDate,
      errorNacim: errorYear,
    });
  };
  changeCPAcompañante = (cpAcompañante) => {
    if (cpAcompañante.length === 5) {
      this.setState({
        errorCPAcompañante: "",
        cpAcompañante,
      });
    } else {
      this.setState({
        errorCPAcompañante: "Ingresar un código de 5 dígitos",
        cpAcompañante,
      });
    }
  };

  changeCP = (cp) => {
    if (cp.length === 5) {
      this.setState({
        errorCP: "",
        cp,
      });
    } else {
      this.setState({
        errorCP: "Ingresar un código de 5 dígitos",
        cp,
      });
    }
  };

  changeGender = (gender) => {
    this.setState({ gender });
  };
  changeGenderAcompañante = (genderAcompañante) => {
    this.setState({ genderAcompañante });
  };

  changeMateName = (mateName) => {
    this.setState({ mateName });
  };

  changeMateLastname1 = (mateLastname1) => {
    this.setState({ mateLastname1 });
  };

  changeMateLastname2 = (mateLastname2) => {
    this.setState({ mateLastname2 });
  };

  changePhone = (phone) => {
    let mensajeError = "";
    mensajeError = this.checkValidPhone(phone);
    this.setState({ errorPhone: mensajeError, phone });
  };
  changePhoneAcompañante = (phoneAcompañante) => {
    let mensajeError = "";
    mensajeError = this.checkValidPhone(phoneAcompañante);
    this.setState({ errorPhoneAcompañante: mensajeError, phoneAcompañante });
  };

  changePhoneHome = (phoneHome) => {
    let mensajeError = "";
    mensajeError = this.checkValidPhone(phoneHome);
    this.setState({ errorPhoneHome: mensajeError, phoneHome });
  };
  changePhoneHomeAcompañante = (phoneHomeAcompañante) => {
    let mensajeError = "";
    mensajeError = this.checkValidPhone(phoneHomeAcompañante);
    this.setState({
      errorPhoneHomeAcompañante: mensajeError,
      phoneHomeAcompañante,
    });
  };

  checkValidPhone = (phoneNumber) => {
    let mensajeError = "";
    if (phoneNumber.length === 10) {
      const lastEightDigits = phoneNumber.substring(phoneNumber.length - 8);
      const sameNumbers = this.sameCharacters(lastEightDigits);
      if (sameNumbers) {
        mensajeError = "Número inválido";
      } else {
        mensajeError = "";
      }
      this.setState({ tieneTelefono: "no" });
    } else if (phoneNumber.length === 0) {
      mensajeError = "";
    } else {
      mensajeError = "Ingresar un número de 10 dígitos";
      this.setState({ tieneTelefono: "no" });
    }
    return mensajeError;
  };

  sameCharacters(input) {
    return input.split("").every((char) => char === input[0]);
  }

  changeCupon = (cupon) => {
    this.setState({
      errorCupon: "",
      cupon,
    });
  };
  handleChageComoSeEntero = (e) => {
    this.setState({
      como_se_entero_de_nosotros: e.target.value,
    });
  };

  // handleChangeGDLVRefiereA=(e)=>{
  //   this.setState({
  //     GDLV_refiere_a:e.target.value
  //   })
  // }

  handleChangeGDLVRefierePor = (e) => {
    this.setState({
      GDLV_refiere_por: e.target.value,
    });
  };

  handleChangeComentariosGDLV = (e) => {
    this.setState({
      comentarios_GDLV: e.target.value,
    });
  };

  changeEmail = (email) => {
    if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
      this.setState({
        errorEmail: "",
        email,
        tieneCorreo: "no",
      });
    } else if (email.length === 0) {
      this.setState({
        errorEmail: "",
        email: "",
      });
    } else {
      this.setState({
        errorEmail: "Verifique la dirección de correo",
        email: "",
        tieneCorreo: "no",
      });
    }
  };
  changeEmailAcompañante = (emailAcompañante) => {
    if (
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(emailAcompañante)
    ) {
      this.setState({
        errorEmailAcompañante: "",
        emailAcompañante,
      });
    } else {
      this.setState({
        errorEmailAcompañante: "Verifique la dirección de correo",
        emailAcompañante,
      });
    }
  };

  changeEnteraste = (e) => {
    const { estiloEnteraste } = this.state;
    if (estiloEnteraste === null) {
      this.setState({
        enteraste: e.target.value,
        estiloEnteraste: stylesDatos.datosEnterasteActive,
      });
    } else {
      this.setState({
        enteraste: e.target.value,
      });
    }
  };

  changeJNH = (e) => {
    const { options, selectedIndex, value } = e.target;
    const { estiloJNH } = this.state;

    if (estiloJNH === "datosJNH") {
      this.setState({
        jnhCampaign: value,
        jnhClinic: `${options[selectedIndex].text} (${value})`,
        estiloJNH: "datosJNHActive",
      });
    } else {
      this.setState({
        jnhCampaign: value,
        jnhClinic: `${options[selectedIndex].text} (${value})`,
      });
    }
  };

  handleKeyPress = () => {
    if (this.checkInfo()) {
      this.manejarClickContinuar();
    }
  };

  cambiarPromoACitaNormal = () => {
    this.manejarClickContinuar();
  };
  getObjectByService = (nameService) => {
    return Object.keys(DiagnosticosData).find(
      (key) => DiagnosticosData[key].nombreServicio === nameService
    );
  };
  setHistoryForNavigation = (index) => {
    indexDiagnostic = index;
  };
  navegaPorServicio = (servicio) => {
    let diagnosticoIndex = this.getObjectByService(servicio);
    this.setHistoryForNavigation(diagnosticoIndex);
  };

  manejarClickContinuar = () => {
    const { email, emailAcompañante } = this.state;

    this.setState({ loading: true });

    const {
      patientName,
      patientLastname1,
      patientLastname2,
      mateName,
      mateLastname1,
      mateLastname2,
      phone,
      phoneAcompañante,
      phoneHome,
      diabetes,
      hipertension,
      otras_enfermedades_sistematicas,
      motivo_de_la_cita,
      phoneHomeAcompañante,
      enteraste,
      selectedDate,
      selectedDateAcompañante,
      gender,
      genderAcompañante,
      cp,
      cpAcompañante,
      cupon,
      baseUrl,
      baseUrlSalesforce,
      termsCond,
      tieneCorreo,
      tieneTelefono,
      como_se_entero_de_nosotros,
      GDLV_refiere_por,
      comentarios_GDLV,
      clinica_externa,
      numero_autorizacion,
      folio_de_solicitud_de_servicio,
      fecha_pase_fin_vigencia,
      esValidoTel,
      esValidoEmail,
      modalOcupada,
    } = this.state;
    let { campaign, GDLV_refiere_a, medioPost } = this.state;
    const { history, location } = this.props;
    let {
      respuestasCuestionario,
      serviceId,
      calendarId,
      cita,
      prioridad,
      urlParams,
      medio,
      diagnostico,
    } = location.state;
    let { historialFlujo } = location.state;
    let { flujoStep } = historialFlujo;
    historialFlujo.flujoStep = flujoStep + 1;
    // if (urlParams.medio === 'CitaDeseada' || urlParams.isContactCenter === true) {
    if (urlParams.isContactCenter !== null) {
      historialFlujo.flujoStep = flujoStep + 2;
    }

    let fechaNacimiento = moment(selectedDate).format("YYYY-MM-DD").toString();
    let fechaNacimientoAcompañante = moment(selectedDateAcompañante)
      .format("YYYY-MM-DD")
      .toString();
    let diaDeHoy = moment();
    let anioscumplidosDelPaciente = diaDeHoy.diff(fechaNacimiento, "years");
    // if (anioscumplidosDelPaciente < 40) {
    //   this.toggleMenor40Promocion();
    // }
    const infoPx = [
      {
        paciente1: patientName,
        apellidoP1: patientLastname1,
        apellidoM1: patientLastname2,
        fechaNacim: fechaNacimiento,
        diabetes: diabetes,
        hipertension: hipertension,
        otras_enfermedades_sistematicas: otras_enfermedades_sistematicas,
        motivo_de_la_cita: motivo_de_la_cita,
        gender,
        como_se_entero_de_nosotros,
        GDLV_refiere_por,
        comentarios_GDLV,
        GDLV_refiere_a,
      },
      {
        //!Agregar genero y fecha de nacimiento a los datos del segunfo paciente
        paciente2: mateName,
        apellidoP2: mateLastname1,
        apellidoM2: mateLastname2,
        fechaNacim: fechaNacimientoAcompañante,
        genderAcompañante,
        telefono: phoneAcompañante,
        telefonoCasa: phoneHomeAcompañante,
        emailAcompañante,
        cpAcompañante,
      },
      {
        //!Datos de contacto del primer paciente
        telefono: phone,
        telefonoCasa: phoneHome,
        email,
        enteraste,
        cp,
        cupon,
      },
      //!Agregar datos de contacto del segundo paciente
    ];

    const salesforcePost = {
      action: "validaEmail",
      correoAgenda: email,
      nombre: patientName,
      apellidos: `${patientLastname1} ${patientLastname2}`,
      usaServicio: email !== "" ? true : false,
    };
    axios
      .post(`${baseUrlSalesforce}/enpointValidaEmailTel`, salesforcePost)
      .then((response) => {
        console.log(response.data);
        if (
          response.data.StatusCode === 200 &&
          response.data.StatusMessage === "Correo Valido"
        ) {
          this.setState({ esValidoEmail: true });

          const salesforcePost2 = {
            action: "validaTelefono",
            telefonoAgenda: phone,
            nombre: patientName,
            apellidos: `${patientLastname1} ${patientLastname2}`,
            usaServicio: phone !== "" ? true : false,
          };
          axios
            .post(`${baseUrlSalesforce}/enpointValidaEmailTel`, salesforcePost2)
            .then((response) => {
              console.log(response.data);
              if (
                response.data.StatusCode === 200 &&
                response.data.StatusMessage === "Telefono Valido"
              ) {
                this.setState({ esValidoTel: true });

                if (urlParams.medio === "Metro") {
                  medioPost = "Metro";
                }

                let utmCampaignSeleccionada = false;
                if (urlParams.utm_campaign === null) {
                  utmCampaignSeleccionada = true;
                } else if (urlParams.utm_campaign === "null") {
                  utmCampaignSeleccionada = true;
                } else if (medioConfigAliadosGral === null) {
                  utmCampaignSeleccionada = false;
                }
                else {
                  utmCampaignSeleccionada = false;
                }
                canalPost =
                  canalPost === "GeneralCampanas" ? "Campaña" : canalPost;

                console.log("utmCampaign: campaign/utlParams.utm_campaign", campaign, "/", urlParams.utm_campaign);
                console.log("utmSource:", medioPost);
                console.log("utmMedium:", urlParams.utm_medium);

                let utmMedioAgenda = false;
                if (urlParams.utm_medium === null) {
                  utmMedioAgenda = true;
                } else if (urlParams.utm_medium === "null") {
                  utmMedioAgenda = true;
                } else if (medioConfigAliadosGral === null) {
                  utmMedioAgenda = false;
                }
                else {
                  utmMedioAgenda = false;
                }

                let utmSourceAgenda = false;
                if (medioPost === null) {
                  utmSourceAgenda = true;
                } else if (medioPost === "null") {
                  utmSourceAgenda = true;
                } else if (medioPost === undefined) {
                  utmSourceAgenda = true;
                }
                else if (medioConfigAliadosGral === null) {
                  utmSourceAgenda = false;
                } else {
                  utmSourceAgenda = false;
                }

                const datosGenerales = {
                  enteraste,
                  ServiceID: serviceId, //ServiceId del servicio que solicito el usuario
                  calendarID: calendarId, //CalendarId del horario que solicito el usuario
                  canalagenda2: canalPost, //Agenda2.0 o AgendaPW
                  medioagenda2: utmSourceAgenda ? medioConfigAliadosGral : medioPost, //mediPost Medio por el cual viene el usuario
                  agenda2Campaign: campaign, //Capaña generica del medio del cual proviene el usuario o campaña de la promoción
                  gclid: urlParams.gclid !== null ? urlParams.gclid : "", //GCLID que proviene de google
                  certificate: cupon, //Cupon que introduce el usuario
                  expediente: urlParams.exp, //No aplica
                  fuente: urlParams.fuente, //No aplica
                  idPersonal: urlParams.idPersonal, //No aplica
                  utmSource: utmSourceAgenda ? medioConfigAliadosGral : medioPost, // medioPost utm_source que viene en los parametros de la url
                  utmMedium: utmMedioAgenda ? medioConfigAliadosGral : urlParams.utm_medium, //utm_medium que viene en los parametros de la url 
                  utmCampaign: utmCampaignSeleccionada
                    ? campaign
                    : urlParams.utm_campaign, //utm_campaign que viene en los parametros de la url
                  utmContent: urlParams.utm_content, //utm_content que viene en los parametros de la url
                  utmTerm: urlParams.utm_term, //utm_term que viene en los parametros de la url
                  clinica_externa,
                  numero_autorizacion,
                  folio_de_solicitud_de_servicio,
                  fin_vigencia_pase_metro: fecha_pase_fin_vigencia,
                  correo_no_proporcionado: tieneCorreo,
                  telefono_no_proporcionado: tieneTelefono,
                  comentarios_GDLV: comentarios_GDLV,
                };

                if (paraQuien !== 3) {
                  // Para Mí || Para Alguien Más
                  const { history, location } = this.props;
                  const { state } = location;
                  let { header } = state;
                  const { diagnostico, urlParams } = state;
                  if (
                    urlParams.medio === "GDLV-P" ||
                    urlParams.utm_source === "GDLV-P"
                  ) {
                    if (diagnostico.descripcion === "Consulta Adulto") {
                      GDLV_refiere_a = "Referible a Consulta general";
                    } else {
                      GDLV_refiere_a = "Referible a Retina";
                    }
                  }
                  console.log("VALOR DE DIABETES : ", diabetes);
                  console.log("VALOR DE hipertension : ", hipertension);
                  console.log(
                    "VALOR DE otras_enfermedades_sistematicas : ",
                    otras_enfermedades_sistematicas
                  );
                  console.log(
                    "VALOR DE motivo_de_la_cita : ",
                    motivo_de_la_cita
                  );

                  const citaData = {
                    firstName: patientName,
                    lastName: `${patientLastname1} ${patientLastname2}`,
                    datetime: cita.dateTime,
                    phone: phone == "" ? "5500000000" : phone,
                    email: email == "" ? "px_sincorreo@salauno.com.mx" : email,
                    fechaNacim: fechaNacimiento,
                    gender,
                    diabetes,
                    hipertension,
                    otras_enfermedades_sistematicas,
                    motivo_de_la_cita,
                    comentarios_GDLV: comentarios_GDLV,
                    como_se_entero_de_nosotros,
                    GDLV_refiere_por,
                    GDLV_refiere_a,
                    telefonoCasa: phoneHome,
                    cp,
                    formaContacto: cita.contacto,
                    termsCond,
                    respCuest: respuestasCuestionario,
                    ...datosGenerales,
                  };
                  header.push({
                    id: 4,
                    titulo: "Datos del paciente",
                    dato: "Datos del paciente",
                    ruta: "/cita/datosPaciente",
                  });
                  let newHistory = {
                    ...state,
                    header,
                    infoPx,
                    historialFlujo,
                  };
                  if (process.env.NODE_ENV !== "production") {
                    console.tron.display({
                      name: `[DatosPaciente] Appointment Post`,
                      value: citaData,
                    });
                  }
                  console.log("citaData:", citaData);

                  const bodySalesforce = {
                    action: "compruebaNumeroAutorizacion",
                    numeroAutorizacion: numero_autorizacion,
                    folioSolicitudServicio: folio_de_solicitud_de_servicio,
                    ConsultaSubsecunten: !!urlParams.exp ? true : false,
                    expediente: !!urlParams.exp ? urlParams.exp : null,
                  };

                  console.log("bodySalesforce:", bodySalesforce);
                  if (urlParams.medio === "Metro") {
                    if (diagnostico.descripcion !== "Actualización de lentes") {
                      axios
                        .post(
                          `${baseUrlSalesforce}/serviceMetro`,
                          bodySalesforce
                        )
                        .then((responseSalesforce) => {
                          console.log(responseSalesforce);
                          const {
                            data: {
                              StatusCode,
                              StatusMessage,
                              numeroAutorizacionExiste,
                            },
                          } = responseSalesforce;
                          if (numeroAutorizacionExiste !== true) {
                            this.setState({
                              errorModalPaseValido: StatusMessage,
                            });
                            this.toggleModalPaseValido();
                          } else {
                            this.navegaPorServicio(
                              state.diagnostico.nombreServicio
                            );
                            axios
                              .post(`${baseUrl}/Appointment`, citaData)
                              .then((responseAppointment) => {
                                console.log(
                                  "[DatosPaciente] Response Appointment",
                                  responseAppointment,
                                  citaData
                                );
                                if (
                                  responseAppointment.data === "not_available"
                                ) {
                                  this.toggleOcupada();
                                } else if (
                                  responseAppointment.data.status_code === 400
                                ) {
                                  if (
                                    responseAppointment.data.error ===
                                    "invalid_certificate"
                                  ) {
                                    this.toggleErrorCupon();
                                  } else {
                                    this.toggleErrorGeneral();
                                  }
                                } else {
                                  newHistory.cita.response =
                                    responseAppointment.data;
                                  console.log(
                                    "newHistory.cita.response:",
                                    newHistory.cita.response
                                  );
                                  if (urlParams.medio === "Metro") {
                                    if (serviceId === 20478366) {
                                      serviceId = 1747553;
                                    }
                                    newHistory.cita.response.price = "0.00";
                                  }

                                  console.log(
                                    "newHistory.cita.response.price:",
                                    newHistory.cita.response.price
                                  );

                                  history.push(
                                    newHistory.diagnostico.routes[
                                    location.pathname
                                    ] + location.search,
                                    newHistory
                                  );
                                }
                              })
                              .catch((err) => {
                                console.error(
                                  `[DatosPaciente] Error al crear cita: ${err}`
                                );
                                if (process.env.NODE_ENV !== "production") {
                                  console.tron.error(
                                    `[DatosPaciente] Error al crear cita: ${err}`
                                  );
                                }
                              });
                          }
                        })
                        .catch((err) => {
                          console.log(err.message);
                        });
                    } else {
                      this.navegaPorServicio(state.diagnostico.nombreServicio);
                      axios
                        .post(`${baseUrl}/Appointment`, citaData)
                        .then((responseAppointment) => {
                          console.log(
                            "[DatosPaciente] Response Appointment",
                            responseAppointment,
                            citaData
                          );
                          if (responseAppointment.data === "not_available") {
                            this.toggleOcupada();
                          } else if (
                            responseAppointment.data.status_code === 400
                          ) {
                            if (
                              responseAppointment.data.error ===
                              "invalid_certificate"
                            ) {
                              this.toggleErrorCupon();
                            } else {
                              this.toggleErrorGeneral();
                            }
                          } else {
                            newHistory.cita.response = responseAppointment.data;
                            console.log(
                              "newHistory.cita.response:",
                              newHistory.cita.response
                            );
                            if (urlParams.medio === "Metro") {
                              if (serviceId === 20478366) {
                                serviceId = 1747553;
                              }
                              newHistory.cita.response.price = "0.00";
                            }

                            console.log(
                              "newHistory.cita.response.price:",
                              newHistory.cita.response.price
                            );

                            history.push(
                              newHistory.diagnostico.routes[location.pathname] +
                              location.search,
                              newHistory
                            );
                          }
                        })
                        .catch((err) => {
                          console.error(
                            `[DatosPaciente] Error al crear cita: ${err}`
                          );
                          if (process.env.NODE_ENV !== "production") {
                            console.tron.error(
                              `[DatosPaciente] Error al crear cita: ${err}`
                            );
                          }
                        });
                    }
                  } else {
                    console.log(
                      "ESTE ES EL VALOR DE CITADATA ANTES DE LA PETICION: ",
                      citaData
                    );
                    //medioPost;

                    this.navegaPorServicio(state.diagnostico.nombreServicio);
                    axios
                      .post(`${baseUrl}/Appointment`, citaData)
                      .then((responseAppointment) => {
                        console.log(
                          "ESTAMOS DENTRO DE SERVICIO DISTINTO DE METRO"
                        );
                        if (process.env.NODE_ENV !== "production") {
                          console.tron.display({
                            name: `[DatosPaciente] Response Appointment`,
                            value: { responseAppointment, citaData },
                          });
                        }
                        console.log(
                          "El valor de citaData utmCampaign : ",
                          citaData.utmCampaign
                        );
                        console.log(
                          "El valor de citaData utm_source es : ",
                          citaData.utmSource
                        );
                        console.log(
                          "El valor de la respuesta es : ",
                          responseAppointment.data
                        );

                        if (responseAppointment.data === "not_available") {
                          if (
                            urlParams.medio === "GDLV" ||
                            urlParams.utm_source === "GDLV"
                          ) {
                            window.postMessage(
                              JSON.stringify({ message: "citaOcupada" })
                            );
                            this.aceptarOcupada();
                          } else {
                            this.toggleOcupada();
                          }
                        } else if (
                          responseAppointment.data.status_code === 400
                        ) {
                          if (
                            responseAppointment.data.error ===
                            "invalid_certificate"
                          ) {
                            this.toggleErrorCupon();
                          } else {
                            this.toggleErrorGeneral();
                          }
                        } else {
                          newHistory.cita.response = responseAppointment.data;

                          if (validCampaigns.includes(campaign)) {
                            if (serviceId === 20478366) {
                              serviceId = 1747553;
                            }
                            if (
                              Object.keys(campaigns[campaign].price).includes(
                                JSON.stringify(serviceId)
                              )
                            ) {
                              newHistory.cita.response.price =
                                campaigns[campaign].price[serviceId];
                            }
                          }

                          if (
                            urlParams.medio === "GDLV" ||
                            urlParams.utm_source === "GDLV" ||
                            urlParams.medio === "GDLV-P" ||
                            urlParams.utm_source === "GDLV-P"
                          ) {
                            let totalPagar;
                            if (anioscumplidosDelPaciente < 60) {
                              totalPagar = "150.00";
                            } else {
                              totalPagar = "0.00";
                            }
                            newHistory.cita.response.price = totalPagar;
                          }

                          if (
                            urlParams.medio === "GDLV-Optometria" ||
                            urlParams.medio === "GeneralCampanas" ||
                            urlParams.utm_source === "GDLV-Optometria"
                          ) {
                            let totalPagar;
                            if (
                              serviceId === 1747553 ||
                              serviceId === 20478366
                            ) {
                              if (anioscumplidosDelPaciente < 60) {
                                totalPagar = "299.00";
                              } else {
                                totalPagar = "0.00";
                              }
                            } else if (serviceId === 1747588) {
                              totalPagar = "150.00";
                            }
                            newHistory.cita.response.price = totalPagar;
                          }
                          if (
                            urlParams.medio === "GeneralCampanas" &&
                            citaData.utmSource === "Campaña"
                          ) {
                            let totalPagar;
                            if (
                              serviceId === 1747553 ||
                              serviceId === 20478366
                            ) {
                              if (anioscumplidosDelPaciente < 60) {
                                totalPagar = "0.00";
                              } else {
                                totalPagar = "0.00";
                              }
                            } else if (serviceId === 1747588) {
                              totalPagar = "150.00";
                            }
                            newHistory.cita.response.price = totalPagar;
                          }

                          if (
                            urlParams.medio === "Azucar" &&
                            diagnostico.descripcion === "Problemas de retina"
                          ) {
                            let totalPagar;
                            totalPagar = "0.00";
                            newHistory.cita.response.price = totalPagar;
                          }

                          if (
                            urlParams.medio === "Azucar" &&
                            diagnostico.descripcion === "Consulta Adulto"
                          ) {
                            let totalPagar;
                            totalPagar = "0.00";
                            newHistory.cita.response.price = totalPagar;
                          }

                          if (urlParams.medio === "Campanas") {
                            if (serviceId === 1747553) {
                              newHistory.cita.response.price = "150.00";
                            }
                          }

                          if (urlParams.medio === "SHCP") {
                            if (
                              serviceId === 1747553 ||
                              serviceId === 20478366
                            ) {
                              newHistory.cita.response.price = "0.00";
                            }
                          }

                          let ConfigAliadosParams = ConfigAliados[urlParams.medio];
                          console.log("ConfigAliadosParams DatosPaciente:", ConfigAliadosParams);

                          if (ConfigAliadosParams !== undefined) {
                            console.log("Encontró en el archivo ConfigAliadosParams");
                            if (urlParams.medio === ConfigAliados[urlParams.medio].nombreAgenda &&
                              ConfigAliados[urlParams.medio].servicios.consultaAdulto.descripcion === "Problemas de retina"
                            ) {
                              let totalPagar;
                              totalPagar = "0.00";
                              newHistory.cita.response.price = ConfigAliados[urlParams.medio].servicios.consultaRetina.precio;
                            }

                            if (urlParams.medio === ConfigAliados[urlParams.medio].nombreAgenda &&
                              ConfigAliados[urlParams.medio].servicios.consultaAdulto.descripcion === "Consulta Adulto"
                            ) {
                              let totalPagar;
                              totalPagar = "0.00";
                              newHistory.cita.response.price = ConfigAliados[urlParams.medio].servicios.consultaAdulto.precio;
                            }
                          }



                          /*if(urlParams.medio === "N-SOYMAS" &&
                            diagnostico.descripcion === "Problemas de retina"
                          ){
                            let totalPagar;
                            totalPagar = "450.00";
                            newHistory.cita.response.price = totalPagar;
                          }

                          if(urlParams.medio === "N-SOYMAS" &&
                            diagnostico.descripcion === "Consulta Adulto"
                          ){
                            let totalPagar;
                            totalPagar = "150.00";
                            newHistory.cita.response.price = totalPagar;
                          }*/

                          // if (
                          //   prioridad !== null &&
                          //   (urlParams.medio === 'CitaDeseada' || urlParams.isContactCenter===true)
                          // ) {
                          if (
                            prioridad !== null &&
                            urlParams.isContactCenter === true
                          ) {
                            newHistory.diagnostico.routes = {
                              ...diagnostico.routes,
                              ...FlowsData["omoCC"],
                            };
                          } else if (
                            prioridad !== null &&
                            urlParams.medio === "CitaDeseada"
                          ) {
                            newHistory.diagnostico.routes = {
                              ...diagnostico.routes,
                              ...FlowsData["omoCC"],
                            };
                          }

                          history.push(
                            newHistory.diagnostico.routes[location.pathname] +
                            location.search,
                            newHistory
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(
                          `[DatosPaciente] Error al crear cita: ${err}`
                        );
                        if (process.env.NODE_ENV !== "production") {
                          console.tron.error(
                            `[DatosPaciente] Error al crear cita: ${err}`
                          );
                        }
                      });
                  }
                } else {
                  //! PARA === 3 | Para Mí y Para Alguien Más
                  // const { selectedDate, selectedDateAcompañante } = this.state;
                  // const añoPaciente = selectedDate.substring(0, 4);
                  // const mesPaciente = selectedDate.substring(5, 7);
                  // const diaPaciente = selectedDate.substring(8, 10);
                  // const añoAcompañante = selectedDateAcompañante.substring(0, 4);
                  // const mesAcompañante = selectedDateAcompañante.substring(5, 7);
                  // const diaAcompañante = selectedDateAcompañante.substring(8, 10);
                  // const fechaPaciente = moment([añoPaciente, mesPaciente, diaPaciente]);
                  // const fechaAcompañante = moment([
                  //   añoAcompañante,
                  //   mesAcompañante,
                  //   diaAcompañante,
                  // ]);
                  // const añosCumplidosPaciente = moment().diff(fechaPaciente, "years");
                  // const añosCumplidosAcompañante = moment().diff(fechaAcompañante, "years");
                  let campaignPaciente = "";
                  let campaignAcompañante = "";
                  const apellidosPost = `${patientLastname1} ${patientLastname2}`;
                  const acmpApellidosPost = `${mateLastname1} ${mateLastname2}`;
                  let comentarioPost = "";
                  let comentarioPostAcmp = "";
                  let pxPromo = null;

                  if (serviceId === 1747553 && campaign === "cita2x1") {
                    campaignPaciente = "C-DIG-MKT-2X1";
                    campaignAcompañante = "C-DIG-MKT-2X1ACMP";
                    pxPromo = "response1";
                    comentarioPost = `Promoción 2x1 con paciente: ${mateName} ${acmpApellidosPost}`; //!Comentario para paciente
                    comentarioPostAcmp = `Promoción 2x1 con paciente: ${patientName} ${apellidosPost}`; //!comentario para acompañante
                  } else {
                    comentarioPost = `Opción "Para Mí y Para Alguien Más", con paciente: ${mateName} ${acmpApellidosPost}`; //!comentario para paciente
                    comentarioPostAcmp = `Opción "Para Mí y Para Alguien Más", con paciente: ${patientName} ${apellidosPost}`; //!comentario para acompañante
                  }

                  const cancelPost1 = {
                    id: cita.response1.id,
                  };
                  const cancelPost2 = {
                    id: cita.response2.id,
                  };
                  const citaData1 = {
                    ...datosGenerales,
                    firstName: patientName,
                    lastName: apellidosPost,
                    datetime: cita.citaApartada1.datetime,
                    phone,
                    email,
                    agenda2Comment: comentarioPost,
                    agenda2Campaign: campaignPaciente,
                    fechaNacim: fechaNacimiento,
                    gender,
                    telefonoCasa: phoneHome,
                    cp,
                  };
                  const citaData2 = {
                    ...datosGenerales,
                    firstName: mateName,
                    lastName: acmpApellidosPost,
                    datetime: cita.citaApartada2.datetime,
                    phone: phoneAcompañante,
                    email: emailAcompañante,
                    agenda2Comment: comentarioPostAcmp,
                    agenda2Campaign: campaignAcompañante,
                    fechaNacim: fechaNacimientoAcompañante,
                    gender: genderAcompañante,
                    telefonoCasa: phoneHomeAcompañante,
                    cp: cpAcompañante,
                  };

                  if (process.env.NODE_ENV !== "production") {
                    console.tron.display({
                      name: `[DatosPaciente] Parametros de citas`,
                      value: {
                        citaData1,
                        citaData2,
                      },
                    });
                  }

                  let newHistory = {
                    ...location.state,
                    infoPx,
                    historialFlujo,
                  };

                  axios
                    .post(`${baseUrl}/Cancel`, cancelPost1)
                    .then(() => {
                      axios
                        .post(`${baseUrl}/Appointment`, citaData1)
                        .then((responseAppo1) => {
                          if (process.env.NODE_ENV !== "production") {
                            console.tron.log(
                              `[DatosPaciente] Appointment1 response: ${JSON.stringify(
                                responseAppo1.data
                              )}`
                            );
                          }
                          newHistory.cita.response1 = responseAppo1.data;

                          axios
                            .post(`${baseUrl}/Cancel`, cancelPost2)
                            .then(() => {
                              axios
                                .post(`${baseUrl}/Appointment`, citaData2)
                                .then((responseAppo2) => {
                                  if (process.env.NODE_ENV !== "production") {
                                    console.tron.log(
                                      `[DatosPaciente] Appointment2 response: ${JSON.stringify(
                                        responseAppo2.data
                                      )}`
                                    );
                                  }
                                  appointmentConfirmed = true;
                                  newHistory.citaresponse2 = responseAppo2.data;

                                  if (pxPromo !== null) {
                                    newHistory.cita[pxPromo].price = "0.00";
                                  }

                                  history.push(
                                    `/${TXT_URL}${history.location.search}`,
                                    newHistory
                                  );
                                })
                                .catch((err) => {
                                  console.error(
                                    `[DatosPaciente] Error al crear la segunda cita: ${err}`
                                  );
                                  if (process.env.NODE_ENV !== "production") {
                                    console.tron.error(
                                      `[DatosPaciente] Error al crear la segunda cita: ${err}`
                                    );
                                  }
                                });
                            })
                            .catch((err) => {
                              console.error(
                                `[DatosPaciente] Error al cancelar la segunda cita apartada ${err}`
                              );
                              if (process.env.NODE_ENV !== "production") {
                                console.tron.error(
                                  `[DatosPaciente] Error al cancelar la segunda cita apartada ${err}`
                                );
                              }
                            });
                        })
                        .catch((err) => {
                          console.error(
                            `[DatosPaciente] Error agendar la priemra cita ${err}`
                          );
                          if (process.env.NODE_ENV !== "production") {
                            console.tron.error(
                              `[DatosPaciente] Error agendar la priemra cita ${err}`
                            );
                          }
                        });
                    })
                    .catch((err) => {
                      console.error(
                        `[DatosPaciente] Error al cancelar la primera cita apartada ${err}`
                      );
                      if (process.env.NODE_ENV !== "production") {
                        console.tron.error(
                          `[DatosPaciente] Error al cancelar la primera cita apartada ${err}`
                        );
                      }
                    });
                }
              } else {
                this.setState({ esValidoTel: false });
                this.toggleModalTelefonoInvalido();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.setState({ esValidoEmail: false });
          this.toggleModalCorreoInvalido();
        }
      })
      .catch((error) => {
        console.log(error);
      });

    //}
  };

  checkInfo = () => {
    const {
      cp,
      cpAcompañante,
      cupon,
      email,
      emailAcompañante,
      errorCP,
      errorCPAcompañante,
      errorCupon,
      errorEmail,
      errorEmailAcompañante,
      errorNacim,
      errorNacimAcompañante,
      errorPhone,
      errorPhoneAcompañante,
      gender,
      genderAcompañante,
      mateLastname1,
      mateLastname2,
      mateName,
      patientLastname1,
      patientLastname2,
      patientName,
      phone,
      phoneAcompañante,
      selectedDate,
      selectedDateAcompañante,
      termsCond,
      tieneCorreo,
      tieneTelefono,
      clinica_externa,
      numero_autorizacion,
      folio_de_solicitud_de_servicio,
      fecha_pase_fin_vigencia,
      diagnostico,
      esValidoTel,
      esValidoEmail,
    } = this.state;

    const { prioridad, urlParams } = this.props.location.state;

    console.log("Email: ", email);
    console.log("Phone: ", phone);
    console.log("CheckCorreo: ", tieneCorreo);
    console.log("CheckTelefono: ", tieneTelefono);
    console.log("errorEmail: ", errorEmail);
    console.log("errorPhone: ", errorPhone);
    if (prioridad) {
      if (process.env.NODE_ENV !== "production") {
        console.tron.display({
          name: `[DatosPaciente] Condicional botón`,
          value: {
            patientName,
            patientLastname1,
            patientLastname2,
            selectedDate,
            mateName,
            mateLastname1,
            mateLastname2,
            errorEmail,
            errorPhone,
            tieneCorreo,
            tieneTelefono,
            phone,
            email,
            errorNacim,
            errorCP,
            gender,
            cp,
            errorCupon,
            termsCond,
            cupon,
          },
        });
      }
      if (urlParams.medio === null || urlParams.utm_source === null) {
        if (
          patientName !== "" &&
          patientLastname1 !== "" &&
          errorEmail === "" &&
          errorPhone === "" &&
          (email !== "" || tieneCorreo === "yes") &&
          (phone !== "" || tieneTelefono === "yes") &&
          // cp !== '' &&
          errorCP === "" &&
          gender !== "*Género" &&
          termsCond === "yes" &&
          (errorCupon === "" || cupon === "") &&
          selectedDate !== "" &&
          selectedDate !== null &&
          errorNacim !== "Año inválido" &&
          errorNacim !== "Debe ser mayor de 8 años"
        ) {
          return true;
        }
      } else {
        if (
          urlParams.medio === "Metro" &&
          diagnostico.descripcion !== "Actualización de lentes"
        ) {
          if (
            patientName !== "" &&
            patientLastname1 !== "" &&
            errorEmail === "" &&
            errorPhone === "" &&
            (email !== "" || tieneCorreo === "yes") &&
            (phone !== "" || tieneTelefono === "yes") &&
            // cp !== '' &&
            errorCP === "" &&
            gender !== "*Género" &&
            termsCond === "yes" &&
            (errorCupon === "" || cupon === "") &&
            selectedDate !== "" &&
            selectedDate !== null &&
            errorNacim !== "Año inválido" &&
            errorNacim !== "Debe ser mayor de 8 años" &&
            clinica_externa !== "Seleccionar" &&
            numero_autorizacion !== "" &&
            folio_de_solicitud_de_servicio !== "" &&
            fecha_pase_fin_vigencia !== ""
          ) {
            return true;
          }
        } else {
          if (
            patientName !== "" &&
            patientLastname1 !== "" &&
            errorEmail === "" &&
            errorPhone === "" &&
            (email !== "" || tieneCorreo === "yes") &&
            (phone !== "" || tieneTelefono === "yes") &&
            // cp !== '' &&
            errorCP === "" &&
            gender !== "*Género" &&
            termsCond === "yes" &&
            (errorCupon === "" || cupon === "") &&
            selectedDate !== "" &&
            selectedDate !== null &&
            errorNacim !== "Año inválido" &&
            errorNacim !== "Debe ser mayor de 8 años"
          ) {
            return true;
          }
        }
      }
      return false;
    }
    if (paraQuien !== 3) {
      if (urlParams.medio === null || urlParams.utm_source === null) {
        if (
          urlParams.medio === "Metro" &&
          diagnostico.descripcion !== "Actualización de lentes"
        ) {
          if (
            patientName !== "" &&
            patientLastname1 !== "" &&
            errorEmail === "" &&
            errorPhone === "" &&
            (email !== "" || tieneCorreo === "yes") &&
            (phone !== "" || tieneTelefono === "yes") &&
            // cp !== '' &&
            errorCP === "" &&
            gender !== "*Género" &&
            termsCond === "yes" &&
            (errorCupon === "" || cupon === "") &&
            selectedDate !== "" &&
            selectedDate !== null &&
            errorNacim !== "Año inválido" &&
            errorNacim !== "Debe ser mayor de 8 años" &&
            clinica_externa !== "Seleccionar" &&
            numero_autorizacion !== "" &&
            folio_de_solicitud_de_servicio !== "" &&
            fecha_pase_fin_vigencia !== ""
          ) {
            return true;
          }
        } else {
          if (
            patientName !== "" &&
            patientLastname1 !== "" &&
            errorEmail === "" &&
            errorPhone === "" &&
            (email !== "" || tieneCorreo === "yes") &&
            (phone !== "" || tieneTelefono === "yes") &&
            // cp !== '' &&
            errorCP === "" &&
            gender !== "*Género" &&
            termsCond === "yes" &&
            (errorCupon === "" || cupon === "") &&
            selectedDate !== "" &&
            selectedDate !== null &&
            errorNacim !== "Año inválido" &&
            errorNacim !== "Debe ser mayor de 8 años"
          ) {
            return true;
          }
        }
      } else {
        if (
          urlParams.medio === "Metro" &&
          diagnostico.descripcion !== "Actualización de lentes"
        ) {
          if (
            patientName !== "" &&
            patientLastname1 !== "" &&
            errorEmail === "" &&
            errorPhone === "" &&
            (email !== "" || tieneCorreo === "yes") &&
            (phone !== "" || tieneTelefono === "yes") &&
            // cp !== '' &&
            errorCP === "" &&
            gender !== "*Género" &&
            termsCond === "yes" &&
            (errorCupon === "" || cupon === "") &&
            selectedDate !== "" &&
            selectedDate !== null &&
            errorNacim !== "Año inválido" &&
            errorNacim !== "Debe ser mayor de 8 años" &&
            clinica_externa !== "Seleccionar" &&
            numero_autorizacion !== "" &&
            folio_de_solicitud_de_servicio !== "" &&
            fecha_pase_fin_vigencia !== ""
          ) {
            return true;
          }
        } else {
          if (
            patientName !== "" &&
            patientLastname1 !== "" &&
            errorEmail === "" &&
            errorPhone === "" &&
            (email !== "" || tieneCorreo === "yes") &&
            (phone !== "" || tieneTelefono === "yes") &&
            // cp !== '' &&
            errorCP === "" &&
            gender !== "*Género" &&
            termsCond === "yes" &&
            (errorCupon === "" || cupon === "") &&
            selectedDate !== "" &&
            selectedDate !== null &&
            errorNacim !== "Año inválido" &&
            errorNacim !== "Debe ser mayor de 8 años"
          ) {
            return true;
          }
        }
      }
      return false;
    }
    //!Aqui esta el condicional para el caso paraquien=3
    if (paraQuien === 3) {
      if (urlParams.medio === null || urlParams.utm_source === null) {
        if (
          patientName !== "" &&
          patientLastname1 !== "" &&
          errorEmail === "" &&
          errorPhone === "" &&
          (email !== "" || tieneCorreo === "yes") &&
          (phone !== "" || tieneTelefono === "yes") &&
          // cp !== '' &&
          errorCP === "" &&
          gender !== "*Género" &&
          selectedDate !== "" &&
          selectedDate !== null &&
          errorNacim !== "Año inválido" && 
          errorNacim !== "Debe ser mayor de 8 años" &&
          mateLastname1 !== "" &&
          mateLastname2 !== "" &&
          mateName !== "" &&
          errorEmailAcompañante === "" &&
          emailAcompañante !== "" &&
          errorPhoneAcompañante === "" &&
          phoneAcompañante !== "" &&
          cpAcompañante !== "" &&
          errorCPAcompañante === "" &&
          genderAcompañante !== "*Género" &&
          selectedDateAcompañante !== "" &&
          selectedDateAcompañante !== null &&
          errorNacimAcompañante !== "Año inválido"
        ) {
          return true;
        }
      } else {
        if (
          urlParams.medio === "Metro" &&
          diagnostico.descripcion !== "Actualización de lentes"
        ) {
          if (
            patientName !== "" &&
            patientLastname1 !== "" &&
            errorEmail === "" &&
            errorPhone === "" &&
            (email !== "" || tieneCorreo === "yes") &&
            (phone !== "" || tieneTelefono === "yes") &&
            // cp !== '' &&
            errorCP === "" &&
            gender !== "*Género" &&
            selectedDate !== "" &&
            selectedDate !== null &&
            errorNacim !== "Año inválido" &&
            errorNacim !== "Debe ser mayor de 8 años" &&
            mateLastname1 !== "" &&
            mateLastname2 !== "" &&
            mateName !== "" &&
            errorEmailAcompañante === "" &&
            emailAcompañante !== "" &&
            errorPhoneAcompañante === "" &&
            phoneAcompañante !== "" &&
            cpAcompañante !== "" &&
            errorCPAcompañante === "" &&
            genderAcompañante !== "*Género" &&
            selectedDateAcompañante !== "" &&
            selectedDateAcompañante !== null &&
            errorNacimAcompañante !== "Año inválido" &&
            clinica_externa === "Seleccionar" &&
            numero_autorizacion === "" &&
            folio_de_solicitud_de_servicio !== "" &&
            fecha_pase_fin_vigencia !== ""
          ) {
            return true;
          }
        } else {
          if (
            patientName !== "" &&
            patientLastname1 !== "" &&
            errorEmail === "" &&
            errorPhone === "" &&
            (email !== "" || tieneCorreo === "yes") &&
            (phone !== "" || tieneTelefono === "yes") &&
            // cp !== '' &&
            errorCP === "" &&
            gender !== "*Género" &&
            selectedDate !== "" &&
            selectedDate !== null &&
            errorNacim !== "Año inválido" &&
            errorNacim !== "Debe ser mayor de 8 años" &&
            mateLastname1 !== "" &&
            mateLastname2 !== "" &&
            mateName !== "" &&
            errorEmailAcompañante === "" &&
            emailAcompañante !== "" &&
            errorPhoneAcompañante === "" &&
            phoneAcompañante !== "" &&
            cpAcompañante !== "" &&
            errorCPAcompañante === "" &&
            genderAcompañante !== "*Género" &&
            selectedDateAcompañante !== "" &&
            selectedDateAcompañante !== null &&
            errorNacimAcompañante !== "Año inválido"
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  toggleOcupada = () => {
    this.setState((prevState) => ({
      modalOcupada: !prevState.modalOcupada,
    }));
  };
  toggleMenor40Promocion = () => {
    this.setState((prevState) => ({
      modalMenor40Promocion: !prevState.modalMenor40Promocion,
    }));
  };

  toggleModalPaseValido = () => {
    const { clickContinuar } = this.state;
    this.setState((prevState) => ({
      modalPaseValido: !prevState.modalPaseValido,
    }));
    if (clickContinuar) {
      this.setState({ clickContinuar: false });
    }
  };

  toggleErrorCupon = () => {
    const { modalErrorCupon } = this.state;
    this.setState({
      modalErrorCupon: !modalErrorCupon,
      loading: false,
      errorCupon: "Ingresa un cupón válido",
    });
  };

  toggleErrorGeneral = () => {
    const { modalErrorGeneral } = this.state;
    this.setState({ modalErrorGeneral: !modalErrorGeneral, loading: false });
  };

  toggleModalCorreoInvalido = () => {
    const { clickContinuar } = this.state;
    this.setState((prevState) => ({
      modalCorreoInvalido: !prevState.modalCorreoInvalido,
    }));
    if (clickContinuar) {
      this.setState({ clickContinuar: false });
    }
  };

  toggleModalTelefonoInvalido = () => {
    const { clickContinuar } = this.state;
    this.setState((prevState) => ({
      modalTelefonoInvalido: !prevState.modalTelefonoInvalido,
    }));
    if (clickContinuar) {
      this.setState({ clickContinuar: false });
    }
  };

  regresar = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { historialFlujo } = state;
    const { flujoStep } = historialFlujo;
    let newHistory = {
      ...state,
      historialFlujo: {
        ...state.historialFlujo,
        flujoStep: flujoStep - 1,
      },
    };
    history.push("/cita/horario", newHistory);
  };
  irAsiguienteVista = () => {
    this.setState({ clickContinuar: true });
    this.manejarClickContinuar();
  };
  render() {
    const {
      modalOcupada,
      modalErrorCupon,
      modalErrorGeneral,
      modalMenor40Promocion,
      modalPaseValido,
      clickContinuar,
      errorModalPaseValido,
      modalCorreoInvalido,
      modalTelefonoInvalido,
    } = this.state;
    return (
      <Container
        fluid
        className={`${stylesConfirmacion.containerDatosPaciente}`}
      >
        <Container className={`${stylesConfirmacion.datosPacienteContainer}`}>
          {this.getForm()}
          <Row className={`${stylesConfirmacion.rowContainerButtons}`}>
            <Col
              xs={12}
              md={6}
              className={` d-flex justify-content-center align-items-center`}
            >
              <button
                className={`${stylesConfirmacion.colContainerButtons__regresar}`}
                onClick={this.regresar}
              >
                REGRESAR
              </button>
            </Col>
            <Col
              xs={12}
              md={6}
              className={`d-flex justify-content-center align-items-center`}
            >
              {clickContinuar === false ? (
                <button
                  className={`${stylesConfirmacion.colContainerButtons__continuar}`}
                  onClick={this.irAsiguienteVista}
                  disabled={this.checkInfo() === false ? true : false}
                >
                  <Container
                    fluid
                    className={`${stylesConfirmacion.buttonContainerParams}`}
                  >
                    <Row className={`${stylesConfirmacion.rowContainerParams}`}>
                      <Col
                        xs={8}
                        className={`${stylesConfirmacion.colContainerNombreBoton}`}
                      >
                        CONTINUAR
                      </Col>
                      <Col
                        xs={4}
                        className={`${stylesConfirmacion.colContainerSpanBoton}`}
                      >
                        <span
                          className={`${stylesConfirmacion.colContainerButtons__span}`}
                        >
                          <svg
                            className={`${stylesConfirmacion.colContainerButtons__svg}`}
                          >
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              ) : (
                <PulseLoader
                  sizeUnit="px"
                  size={15}
                  margin="4px"
                  color="#00748c"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {/* Modal de cita ocupada */}
              <Modal
                isOpen={modalOcupada}
                toggle={this.toggleOcupada}
                className={stylesConfirmacion.modalContainer}
                id="ocupada"
                backdropClassName={`${stylesConfirmacion.backdropModal}`}
                modalClassName={`${stylesConfirmacion.modal}`}
                contentClassName={`${stylesConfirmacion.modalContanet}`}
              >
                <ModalHeader
                  toggle={this.toggleOcupada}
                  className={`${stylesConfirmacion.modalHeader}`}
                  buttonClassClosed={`${stylesConfirmacion.modalButtonClosed}`}
                />
                <ModalBody className={`${stylesConfirmacion.modalBody}`}>
                  <>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          className={`d-flex justify-content-center align-items-center`}
                        >
                          <img src={Admiracion} alt="modal_icon" />
                        </Col>
                        <Col
                          xs={12}
                          className={`${stylesConfirmacion.colContainerTextModalCancelar} d-flex justify-content-center align-items-center`}
                        >
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            Lo sentimos, se ha ocupado el horario de la cita,
                            por favor da click en aceptar y elige un nuevo
                            horario.
                          </p>
                        </Col>
                        <Col
                          className={`${stylesConfirmacion.col_cancelarSi} d-flex justify-content-center`}
                          xs={12}
                        >
                          <button
                            onClick={this.aceptarOcupada}
                            className={`${stylesConfirmacion.col_cancelarSiButton}`}
                          >
                            ACEPTAR
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </>
                </ModalBody>
              </Modal>
              {/* Modal de otros errores */}
              <Modal
                isOpen={modalErrorGeneral}
                toggle={this.toggleErrorGeneral}
                className={stylesConfirmacion.modalContainer}
                id="idModalErrorGeneral"
                backdropClassName={`${stylesConfirmacion.backdropModal}`}
                modalClassName={`${stylesConfirmacion.modal}`}
                contentClassName={`${stylesConfirmacion.modalContanet}`}
              >
                <ModalHeader
                  toggle={this.toggleErrorGeneral}
                  className={`${stylesConfirmacion.modalHeader}`}
                  buttonClassClosed={`${stylesConfirmacion.modalButtonClosed}`}
                />
                <ModalBody className={`${stylesConfirmacion.modalBody}`}>
                  <>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          className={`d-flex justify-content-center align-items-center`}
                        >
                          <img src={Admiracion} alt="modal_icon" />
                        </Col>
                        <Col
                          xs={12}
                          className={`${stylesConfirmacion.colContainerTextModalCancelar} d-flex justify-content-center align-items-center`}
                        >
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            ¡Ops! Ocurrió un error, por favor da click en
                            aceptar y verifica tus datos.
                          </p>
                        </Col>
                        <Col
                          className={`${stylesConfirmacion.col_cancelarSi} d-flex justify-content-center`}
                          xs={12}
                        >
                          <button
                            onClick={this.toggleErrorGeneral}
                            className={`${stylesConfirmacion.col_cancelarSiButton}`}
                          >
                            ACEPTAR
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </>
                </ModalBody>
              </Modal>
              {/*  Modal error de cupon */}
              <Modal
                isOpen={modalErrorCupon}
                toggle={this.toggleErrorCupon}
                className={stylesConfirmacion.modalContainer}
                id="idModalErrorCupon"
                backdropClassName={`${stylesConfirmacion.backdropModal}`}
                modalClassName={`${stylesConfirmacion.modal}`}
                contentClassName={`${stylesConfirmacion.modalContanet}`}
              >
                <ModalHeader
                  toggle={this.toggleErrorCupon}
                  className={`${stylesConfirmacion.modalHeader}`}
                  buttonClassClosed={`${stylesConfirmacion.modalButtonClosed}`}
                />
                <ModalBody className={`${stylesConfirmacion.modalBody}`}>
                  <>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          className={`d-flex justify-content-center align-items-center`}
                        >
                          <img src={Admiracion} alt="modal_icon" />
                        </Col>
                        <Col
                          xs={12}
                          className={`${stylesConfirmacion.colContainerTextModalCancelar} d-flex justify-content-center align-items-center`}
                        >
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            Lo sentimos, el código del cupón es inválido. Por
                            favor da click en aceptar y verifica el código.
                          </p>
                        </Col>
                        <Col
                          className={`${stylesConfirmacion.col_cancelarSi} d-flex justify-content-center`}
                          xs={12}
                        >
                          <button
                            onClick={this.toggleErrorCupon}
                            className={`${stylesConfirmacion.col_cancelarSiButton}`}
                          >
                            ACEPTAR
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </>
                </ModalBody>
              </Modal>
              <Modal
                isOpen={modalMenor40Promocion}
                toggle={this.toggleMenor40Promocion}
                className={stylesConfirmacion.modalContainer}
                id="ocupada"
                backdropClassName={`${stylesConfirmacion.backdropModal}`}
                modalClassName={`${stylesConfirmacion.modal}`}
                contentClassName={`${stylesConfirmacion.modalContanet}`}
              >
                <ModalHeader
                  toggle={this.toggleMenor40Promocion}
                  className={`${stylesConfirmacion.modalHeader}`}
                  buttonClassClosed={`${stylesConfirmacion.modalButtonClosed}`}
                />
                <ModalBody className={`${stylesConfirmacion.modalBody}`}>
                  <>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          className={`d-flex justify-content-center align-items-center`}
                        >
                          <img src={Admiracion} alt="modal_icon" />
                        </Col>
                        <Col
                          xs={12}
                          className={`${stylesConfirmacion.colContainerTextModalCancelar} d-flex justify-content-center align-items-center`}
                        >
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            Por la edad que tienes te recomendamos una
                            asistencia por medio de llamada, de lo contrario
                            tendrias una asistencia
                          </p>
                        </Col>
                        <Col
                          className={`${stylesConfirmacion.col_cancelarSi} d-flex justify-content-center`}
                          xs={12}
                        >
                          <button
                            onClick={this.toggleMenor40Promocion}
                            className={`${stylesConfirmacion.col_cancelarSiButton}`}
                          >
                            ACEPTAR
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </>
                </ModalBody>
              </Modal>
              <Modal
                isOpen={modalPaseValido}
                toggle={this.toggleModalPaseValido}
                className={stylesConfirmacion.modalContainer}
                id="ocupada"
                backdropClassName={`${stylesConfirmacion.backdropModal}`}
                modalClassName={`${stylesConfirmacion.modal}`}
                contentClassName={`${stylesConfirmacion.modalContanet}`}
              >
                <ModalHeader
                  toggle={this.toggleModalPaseValido}
                  className={`${stylesConfirmacion.modalHeader}`}
                  buttonClassClosed={`${stylesConfirmacion.modalButtonClosed}`}
                />
                <ModalBody className={`${stylesConfirmacion.modalBody}`}>
                  <>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          className={`d-flex justify-content-center align-items-center`}
                        >
                          <img src={Admiracion} alt="modal_icon" />
                        </Col>
                        <Col
                          xs={12}
                          className={`${stylesConfirmacion.colContainerTextModalCancelar} d-flex justify-content-center align-items-center`}
                        >
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            {errorModalPaseValido}
                          </p>
                        </Col>
                        <Col
                          className={`${stylesConfirmacion.col_cancelarSi} d-flex justify-content-center`}
                          xs={12}
                        >
                          <button
                            onClick={this.toggleModalPaseValido}
                            className={`${stylesConfirmacion.col_cancelarSiButton}`}
                          >
                            ACEPTAR
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </>
                </ModalBody>
              </Modal>

              {/*  Modal error de validacion de correo */}
              <Modal
                isOpen={modalCorreoInvalido}
                toggle={this.toggleModalCorreoInvalido}
                className={stylesConfirmacion.modalContainer}
                id="correoInvalido"
                backdropClassName={`${stylesConfirmacion.backdropModal}`}
                modalClassName={`${stylesConfirmacion.modal}`}
                contentClassName={`${stylesConfirmacion.modalContanet}`}
              >
                <ModalHeader
                  toggle={this.toggleModalCorreoInvalido}
                  className={`${stylesConfirmacion.modalHeader}`}
                  buttonClassClosed={`${stylesConfirmacion.modalButtonClosed}`}
                />
                <ModalBody className={`${stylesConfirmacion.modalBody}`}>
                  <>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          className={`d-flex justify-content-center align-items-center`}
                        >
                          <img src={Admiracion} alt="modal_icon" />
                        </Col>
                        <Col
                          xs={12}
                          className={`${stylesConfirmacion.colContainerTextModalCancelar} d-flex justify-content-center align-items-center`}
                        >
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            ¡Ops! Este correo electronico no existe, por favor
                            da clic en aceptar e ingresado un correo valido.
                          </p>
                        </Col>
                        <Col
                          className={`${stylesConfirmacion.col_cancelarSi} d-flex justify-content-center`}
                          xs={12}
                        >
                          <button
                            onClick={this.toggleModalCorreoInvalido}
                            className={`${stylesConfirmacion.col_cancelarSiButton}`}
                          >
                            ACEPTAR
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </>
                </ModalBody>
              </Modal>

              {/*  Modal error de validacion de telefono */}
              <Modal
                isOpen={modalTelefonoInvalido}
                toggle={this.toggleModalTelefonoInvalido}
                className={stylesConfirmacion.modalContainer}
                id="telefonoInvalido"
                backdropClassName={`${stylesConfirmacion.backdropModal}`}
                modalClassName={`${stylesConfirmacion.modal}`}
                contentClassName={`${stylesConfirmacion.modalContanet}`}
              >
                <ModalHeader
                  toggle={this.toggleModalTelefonoInvalido}
                  className={`${stylesConfirmacion.modalHeader}`}
                  buttonClassClosed={`${stylesConfirmacion.modalButtonClosed}`}
                />
                <ModalBody className={`${stylesConfirmacion.modalBody}`}>
                  <>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          className={`d-flex justify-content-center align-items-center`}
                        >
                          <img src={Admiracion} alt="modal_icon" />
                        </Col>
                        <Col
                          xs={12}
                          className={`${stylesConfirmacion.colContainerTextModalCancelar} d-flex justify-content-center align-items-center`}
                        >
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            ¡Ops! El Telefono ingresado no existe, por favor da
                            clic en aceptar e ingresado un telefono valido.
                          </p>
                        </Col>
                        <Col
                          className={`${stylesConfirmacion.col_cancelarSi} d-flex justify-content-center`}
                          xs={12}
                        >
                          <button
                            onClick={this.toggleModalTelefonoInvalido}
                            className={`${stylesConfirmacion.col_cancelarSiButton}`}
                          >
                            ACEPTAR
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

DatosPaciente.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

DatosPaciente.defaultProps = {
  location: null,
  history: null,
};
