import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import FormaDePagoSpeiOxxo from './FormaDePago';
import DiagnosticosData from '../../../data/Diagnosticos.json';

const clienteAxios = axios.create({
	//COMBAK
	baseURL: 'https://formasdepago.azurewebsites.net/api', //!Producción
	// baseURL: "https://formasdepago-testing.azurewebsites.net/api", //!StageDev
	// baseURL: 'http://localhost:7071/api', //!Desarrollo local
});
let monto;
let clabe;
let reference;
let indexDiagnostic;
let peticionFormaDePago = 'Hacer peticion';

const FormaDePagoContainer = ({
	tipo,
	datosParaPago,
	state,
	history,
	location,
	pagoOrientacionFormaDePago,
	getPeticion,
	descargarComprobante,
}) => {
	const [datosPaciente, setDatosPaciente] = useState({
		name: datosParaPago.nombre,
		email: datosParaPago.email,
		phone: datosParaPago.telContact,
		totalPagar: datosParaPago.totalPagar,
		opcionPago: tipo,
		idCita: datosParaPago.idCita,
		error: '',
	});

	const [respuesta, setRespuesta] = useState({
		idOrder: '',
		bank: '',
		clabe: '',
		payment_method: '',
		reference: '',
		amount: '',
	});

	const realizarPago = async (e) => {
		e.preventDefault();
		peticionFormaDePago = 'Haciendo peticion';
		getPeticion(peticionFormaDePago);
		const res = await clienteAxios.post(
			`/FormaDePago`, //!Produccion y desarrollo local
			datosPaciente
		);
		if (res.data.details) {
			const { code } = res.data.details[0];
			code ===
				'conekta.errors.parameter_validation.customer_info.name.invalid' &&
				setDatosPaciente({
					...datosPaciente,
					error: 'El campo nombre es invalido, inserte su nombre corectamente',
				});
			peticionFormaDePago = 'Hacer peticion';
			getPeticion(peticionFormaDePago);
		}
		setRespuesta({
			idOrder: res.data.idOrder,
			bank: res.data.bank,
			clabe: res.data.clabe,
			amount: res.data.amount,
			payment_method: res.data.payment_method,
			reference: res.data.reference,
		});
		monto = res.data.amount;
		clabe = res.data.clabe;
		reference = res.data.reference;
		if (tipo === 'spei') {
			if (monto === undefined && clabe === undefined) {
				peticionFormaDePago = 'Hacer peticion';
			} else {
				peticionFormaDePago = 'Peticion confirmada';
			}
		} else {
			if (monto === undefined && reference === undefined) {
				peticionFormaDePago = 'Hacer peticion';
			} else {
				peticionFormaDePago = 'Peticion confirmada';
			}
		}
		getPeticion(peticionFormaDePago);
	};
	const handleChange = (e) => {
		setDatosPaciente({ ...datosPaciente, [e.target.name]: e.target.value });
	};
	const linkComprobante = () => {
		let { historialFlujo } = state;
		let { flujoStep } = historialFlujo;
		historialFlujo.flujoStep = flujoStep + 1;
		let tipoPagoCita = {
			tipo: '',
			clabe: '',
		};
		let { header } = state;
		const { diagnostico } = state;
		const rutaA = '/PagoDeCita';
		if (tipo === 'spei') {
			//!Codigo para abrir una nueva pestaña y descargar el pdf de SPEI
			// window.open(
			//   `/?amount=${monto}&clabe=${clabe}&tipo=spei`,
			//   "",
			//   "width=600,height=400"
			// );
			tipoPagoCita.tipo = 'spei';
			tipoPagoCita.clabe = clabe;
		} else {
			//!Codigo para abrir una nueva pestaña y descargar el pdf de OXXO
			// window.open(
			//   `/?amount=${monto}&reference=${reference}`,
			//   "",
			//   "width=600,height=400"
			// );
			tipoPagoCita.tipo = 'oxxo';
			tipoPagoCita.clabe = reference;
		}
		header.push({
			id: 5,
			titulo: 'Pado de la cita',
			datos: 'Datos del pago de la cita',
			ruta: rutaA,
		});
		let newHistory = {
			...location.state,
			...state,
			header,
			historialFlujo,
			tipoPagoCita,
		};
		newHistory.cita.pago = 'Pendiente';
		navegaPorServicio(state.diagnostico.nombreServicio);
		//!Manda al cliente a la pagina de confirmacion
		history.push(
			diagnostico.routes[location.pathname] + location.search,
			newHistory
		);
	};
	const getObjectByService = (nameService) => {
		return Object.keys(DiagnosticosData).find(
			(key) => DiagnosticosData[key].nombreServicio === nameService
		);
	};
	const setHistoryForNavigation = (index) => {
		indexDiagnostic = index;
	};
	const navegaPorServicio = (servicio) => {
		let diagnosticoIndex = getObjectByService(servicio);
		setHistoryForNavigation(diagnosticoIndex);
	};
	return (
		<FormaDePagoSpeiOxxo
			handleChange={handleChange}
			realizarPago={realizarPago}
			datosPaciente={datosPaciente}
			respuesta={respuesta}
			linkComprobante={linkComprobante}
			peticionFormaDePago={peticionFormaDePago}
			pagoOrientacionFormaDePago={pagoOrientacionFormaDePago}
			descargarComprobante={descargarComprobante}
			tipo={tipo}
			state={state}
		/>
	);
};

export default withRouter(FormaDePagoContainer);
