import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import styles from "../Tarjetas/Tarjetas.module.css";

const DEFAULT_OPTION = `Elije la hora aquí`;

export default function DropdownHoras(props) {
  const { options, descripcion, select } = props.data;
  let horariosTotales = [];
  let horariosFiltrados = [];

  horariosTotales = options.map((horario) => {
    return {
      ...horario,
      textual: horario.time.substring(11, 16),
    };
  });

  if (descripcion === "Promo 2x1") {
    horariosTotales.forEach((horario, index) => {
      if (horariosTotales[index + 1]) {
        const horarioActMili = new moment.duration(
          horario.textual
        ).asMilliseconds();
        const horarioSigMili = new moment.duration(
          horariosTotales[index + 1].textual
        ).asMilliseconds();
        const difference = horarioSigMili - horarioActMili;

        if (difference < 2400000) {
          horariosFiltrados.push({
            time: horario.time,
            textual: `${horariosTotales[index].textual} y ${
              horariosTotales[index + 1].textual
            }`,
            primera: { ...horario },
            segunda: { ...horariosTotales[index + 1] },
          });
        }
      }
    });
  } else {
    horariosFiltrados = horariosTotales;
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(DEFAULT_OPTION);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(()=> {
    console.log('La variable setSelected ha cambiado:', selected);
   setSelected(DEFAULT_OPTION);              
   }, [options]);

  /*   useEffect(() => {
    setSelected(
      descripcion !== "Promo 2x1"
        ? props.data.options
            .map((item) => item.time.substring(11, 16))
            .indexOf(selected) !== -1
          ? selected
          : DEFAULT_OPTION
        : selected
    );
  }); */

  if (horariosFiltrados.length === 0) {
    return (
      <div className={styles.divMartin}>
        {/* <img
          src={paraQuien === 3 ? imgNoDisponiblePromo2x1 : martinSinCitas}
          alt="no hay citas disponibles para este día"
          className={styles.martinSinHorario}
        /> */}
        <p className={`${styles.divMartin__p}`}>Horarios no disponibles</p>
      </div>
    );
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        caret
        color="white"
        className={`${styles.DropdownHorarios} px-5`}
      >
        {selected}
      </DropdownToggle>
      <DropdownMenu
        modifiers={{
          setMaxHeight: {
            enabled: true,
            order: 890,
            fn: (data) => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  overflow: "auto",
                  maxHeight: "200px",
                },
              };
            },
          },
        }}
      >
        {horariosFiltrados.map((horario, index) => (
          <DropdownItem
            key={horario.textual}
            className={`${styles.ItemHorarios}`}
            onClick={() => {
              setSelected(horario.textual);
              select(horario);
            }}
          >
            {horario.textual}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
