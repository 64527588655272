/*
  Props
    --rtiqueta={"Alfabetic","Phone","Email"}
    --placeholder={"Texto por default"}
    --errorText="Lo que debe decir el error"
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { isSafari, isIOS } from "react-device-detect";

let stylesRound = null;
let alfabeticRegEx = /^[A-Za-záéíóúÁÉÍÓÚñÑ\s]+$/;

export default class RoundInputText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      focused: false,
      errorStatus: false,
    };

    if (isSafari || isIOS) {
      stylesRound = require("./Styles.module.css");
    } else {
      stylesRound = require("./Styles.module.css");
    }
  }

  onBlur = () => {
    const { focused, value } = this.state;
    const { errorText } = this.props;
    setTimeout(() => {
      if (focused && value === "") {
        this.setState({
          focused: false,
        });
      }
      if (errorText !== "" && value !== "") {
        this.setState({
          errorStatus: true,
        });
      }
    }, 0);
  };

  onFocus = () => {
    const { focused } = this.state;
    if (!focused) {
      this.setState({
        focused: true,
      });
    }
    this.setState({
      errorStatus: false,
    });
  };

  handleChange = (input) => {
    const { onChange, etiqueta } = this.props;
    if (etiqueta === "Phone") {
      if (/^[0-9]+$/.test(input.target.value) || input.target.value === "") {
        if (input.target.value.length < 11) {
          this.setState({
            value: input.target.value,
          });
          onChange(input.target.value);
        }
      }
    } else if (etiqueta === "Alfabetic") {
      if (
        alfabeticRegEx.test(input.target.value) ||
        input.target.value === ""
      ) {
        this.setState({
          value: input.target.value,
        });
        onChange(input.target.value);
      }
    } else if (etiqueta === "CP") {
      if (/^[0-9]+$/.test(input.target.value) || input.target.value === "") {
        if (input.target.value.length < 6) {
          this.setState({
            value: input.target.value,
          });
          onChange(input.target.value);
        }
      }
    } else if (etiqueta === "Edad") {
      if (/^[0-9]+$/.test(input.target.value) || input.target.value === "") {
        if (input.target.value.length < 4) {
          this.setState({
            value: input.target.value,
          });
          onChange(input.target.value);
        }
      }
    } else if (etiqueta === "Fecha") {
      if (process.env.NODE_ENV !== "production") {
        console.tron.log(`[RoundInputText] Fecha ${input.target.value}`);
      }
      // if (/^[0-9]+$/.test(input.target.value) || input.target.value === '') {
      if (input.target.value.length < 11) {
        this.setState({
          value: input.target.value,
        });
        onChange(input.target.value);
      }
      // }
    } else {
      this.setState({
        value: input.target.value,
      });
      onChange(input.target.value);
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.props.handleKeyDP();
    }
  };

  render() {
    const { focused, errorStatus, value } = this.state;
    const { errorText, placeholder, id, name, etiqueta } = this.props;
    const labelStyle = stylesRound.inputLabel;

    const error = errorStatus ? (
      <label className={stylesRound.errorLabel}>{errorText}</label>
    ) : (
      <div className={stylesRound.errorLabel} />
    );

    return (
      <div>
        <div className={labelStyle}>
          <input
            type={
              etiqueta === "Phone"
                ? "tel"
                : etiqueta === "CP"
                ? "number"
                : etiqueta === "Fecha"
                ? "date"
                : "text"
            }
            name={name}
            id={id}
            placeholder={focused ? "" : placeholder}
            onChange={(input) => this.handleChange(input)}
            className={stylesRound.TextInputGeneral}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={value}
            ref={this.refTextInput}
            onKeyPress={
              etiqueta === "Email" || etiqueta === "Phone"
                ? this.handleKeyPress
                : null
            }
          />
        </div>
        {error}
      </div>
    );
  }
}

RoundInputText.propTypes = {
  etiqueta: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  errorText: PropTypes.string,
  onChange: PropTypes.func,
};

RoundInputText.defaultProps = {
  etiqueta: "Alfabetic",
  placeholder: "",
  id: "",
  name: "",
  errorText: "",
  onChange: () => {},
};
