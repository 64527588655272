import React, { Component } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Col, Row } from "reactstrap";
import moment from "moment";

import { BotonFecha } from "components/Botones";

import styles from "components/Tarjetas/Tarjetas.module.css";
import stylesBoton from "components/Botones/Botones.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import stylesGeneral from '../../css/Style.module.css'

let preselectedDate = "";
const preselectedId = 0;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ${styles.slick}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ${styles.slick}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export default class TarjetasDia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botonSeleccionado: null,
    };
  }

  componentDidMount() {
    this.loadHoursTarjetaDias(preselectedDate, preselectedId);
  }

  loadHoursTarjetaDias = (date, idBoton) => {
    const { loadHours } = this.props;
    const { botonSeleccionado } = this.state;

    loadHours(date);

    if (botonSeleccionado !== idBoton) {
      this.setState({ botonSeleccionado: idBoton });
    }
  };

  createFechas = () => {
    const { botonSeleccionado } = this.state;
    const { dias } = this.props;
    const nombreDias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const fechas = [];

    for (let i = 0; i < dias.length; i += 1) {
      if (i === 7) {
        break;
      }
      const dateObj = moment(dias[i]);
      const diasSplit = dias[i].split("-");

      fechas.push(
        <div
          key={dias[i].date}
          className={`d-flex justify-content-center ${styles.eliminaContorno}`}
        >
          <BotonFecha
            diaNumero={diasSplit[2]}
            diaSemana={nombreDias[dateObj.day()]}
            estiloClassname={
              i !== botonSeleccionado
                ? stylesBoton.fechaBtnElement
                : stylesBoton.fechaBtnElementActivo
            }
            id={i}
            loadHours={this.loadHoursTarjetaDias}
            mes={meses[parseInt(diasSplit[1] - 1, 10)]}
            date={dias[i]}
          />
        </div>
      );
    }
    preselectedDate = dias[0];
    return fechas;
  };

  render() {
    const slickSettings = {
      dots: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: false,
      speed: 500,
      accessibility: true,
      focusOnSelect: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <Row className={`${styles.rowContainer}`}>
        <Col
          lg={12}
          className={`${styles.colContainerSlider} justify-content-center`}
        >
          <Slider {...slickSettings}>{this.createFechas()}</Slider>
        </Col>
      </Row>
    );
  }
}

TarjetasDia.propTypes = {
  loadHours: PropTypes.any,
  dias: PropTypes.any,
  slick: PropTypes.any,
};

TarjetasDia.defaultProps = {
  loadHours: null,
  dias: null,
  slick: null,
};
