/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import bwipjs from "bwip-js";
import { PulseLoader } from "react-spinners";
import AddToCalendar from "react-add-to-calendar";
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import is from "is_js";
import { getUrlParameter } from "../../functions/GetUrlParams";

import styles from "../../css/ConfirmacionStyles.module.css";
import stylesBoton from "../../components/BotonGeneral/BotonGeneral.module.css";
import estilos from "../../css/Style.module.css";

import Festeja from "../../images/svg/confirmacion__Festeja.svg";
import Admiracion from "images/svg/confirmacion__admiracion.svg";
import FestejaSpeiOxxo from "../../images/svg/confirmacion__festejaOXXOSPEI.svg";

const TXT_TITULO_SINGULAR = "Tu Orientación en línea ha sido confirmada";
const TXT_TITULO_PLURAL = "Tu doctor de los ojos en línea";
const TXT_SUBTITULO_SINGULAR =
  "¡Nuestro profesional de la salud visual te esta esperando!";
const TXT_SUBTITULO_PLURAL =
  "Orientación Médica Oftalmológica sin salir de casa.";
const TXT_COSTO = "Confirmación de Pago:";
const TXT_NOMBRE = "Nombre Paciente:";
const TXT_EMAIL = "Email:";
const TXT_TELEFONO = "Teléfono de Contacto:";
const TXT_FECHA_HORA = "Fecha:";
const TXT_MEDIO_CONTACTO = "Medio de contacto:";
const TXT_MODAL_CANCEL = "¿Seguro que quieres cancelar tu cita?";
const TXT_TITLE_TARJETA =
  "¡Tu orientación médica oftalmológica en línea ya está agendada!";
const TXT_TITLE_SPEIOXXO =
  "¡Estas a un paso de terminar de agendar tu orientación en línea!";
const TXT_SUBTITLE_TARJETA = "Tu doctor de los ojos te contactará pronto.";
const TXT_SUBTITLE_SPEI = "Instrucciones para realizar tu transferencia SPEI:";
const TXT_SUBTITLE_OXXO = "Instrucciones para realizar tu pago en Oxxo.";
const TXT_PASO1_SPEI = "Accede a tu banca en línea";
const TXT_PASO2_SPEI = "Da de alta la siguiente CLABE:";
const TXT_PASO3_SPEI =
  "Realiza la transferencia correspondiente por la cantidad exacta de ";
const TXT_PASO3_CONTINUACION_SPEI =
  " pesos, de lo contrario se rechazara el cargo.";
const TXT_PASO4_SPEI =
  "Al confirmar tu pago, el portal de tu banco generará un comprobante digital*, en el podras verificar que se haya realizado correctamente tu transferencia.";
const TXT_PASO5_SPEI =
  "Al completar este paso recibiras un correo de salauno confirmando tu orientacion en linea.";
const TXT_PASO1_OXXO =
  "Acude a tu oxxo más cercano, y presenta al cajero el siguiente código de barras";
const TXT_PASO2_OXXO = "Paga en efectivo el total de ";
const TXT_PASO2_CONTINUACION_OXXO =
  " pesos, en algunas ocaciones Oxxo cobra una comisión adicional.";
const TXT_PASO3_OXXO =
  "Recibirás un correo de confirmación de parte de salauno en un lapso máximo de 60 minutos.";

const URL_CANCEL = "cita/Cancelada";
const URL_REAGENDAR = "reagendar";

let BOTONMODIFICAR = "";

let calEventName = null;
let calEventDesc = null;
let citaCancelada = false;

let detalle_Cita = null;

const params = new URLSearchParams(window.location.search);
let medio = params.get("medio");
let cmp = params.get("cmp");

export default class Confirmacion extends Component {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[Confirmación] History`,
        value: props.location.state,
        important: true,
      });
    }

    this.scrollToMap = React.createRef();

    //COMBAK
    // const baseUrl =
    //   window.location.hostname === "citas.salauno.com.mx"
    //     ? "https://agenda.salauno.com.mx/salauno"
    //     : "https://salauno-agenda-new-testing-agenda.azurewebsites.net/salauno";
    const baseUrl =
      window.location.hostname === "citas.salauno.com.mx"
        ? "https://agenda.salauno.com.mx/salauno"
        : "https://salauno-agenda-new-erika.azurewebsites.net/salauno";

    const { infoPx, para, cita, tipoPagoCita } = props.location.state;

    // const paciente2 =
    //   para.paraQuien === 3
    //     ? `${infoPx[1].paciente2} ${infoPx[1].apellidoP2} ${infoPx[1].apellidoM2}`
    //     : "";
    const titulo =
      para.paraQuien === 1 ? TXT_TITULO_SINGULAR : TXT_TITULO_PLURAL;
    const subtitulo =
      para.paraQuien === 1 ? TXT_SUBTITULO_SINGULAR : TXT_SUBTITULO_PLURAL;

    if (tipoPagoCita !== undefined) {
      if (tipoPagoCita.tipo === "Tarjeta") {
        detalle_Cita = [
          {
            etiqueta: TXT_FECHA_HORA,
            info: `${cita.diaSemana} ${cita.diaMes} de ${cita.mes}`,
          },
          {
            etiqueta: "Hora:",
            info: `${
              cita.horario === "PM" ? "Por la Tarde" : "Por la Mañana"
            }, ${cita.horaCita} ${cita.horario === "PM" ? "pm" : "am"}`,
          },
          {
            etiqueta: TXT_MEDIO_CONTACTO,
            info: `${
              cita.contacto === "Llamada" ? "Llamada" : "Llamada con Video"
            }`, // Remplazar variable
          },
          {
            etiqueta: TXT_NOMBRE,
            info: `${infoPx[0].paciente1} ${infoPx[0].apellidoP1} ${infoPx[0].apellidoM1}`,
          },
          {
            etiqueta: TXT_TELEFONO,
            info: `${infoPx[2].telefono}`,
          },
          {
            etiqueta: TXT_EMAIL,
            info: `${infoPx[2].email}`,
          },
          {
            etiqueta: TXT_COSTO,
            info: this.getPrecio(),
          },
          // {
          //   etiqueta: TXT_TIPO_CITA,
          //   info: "Orientación Médica Oftalmológica",
          // },
        ];
      } else if (tipoPagoCita.tipo === "spei") {
        detalle_Cita = [
          {
            etiqueta: TXT_PASO1_SPEI,
            info: "",
          },
          {
            etiqueta: TXT_PASO2_SPEI,
            info: tipoPagoCita.clabe,
          },
          {
            etiqueta: `${TXT_PASO3_SPEI} ${this.getPrecio()} ${TXT_PASO3_CONTINUACION_SPEI}`,
            info: "",
          },
          {
            etiqueta: TXT_PASO4_SPEI,
            info: "",
          },
          {
            etiqueta: TXT_PASO5_SPEI,
            info: "",
          },
        ];
      } else {
        detalle_Cita = [
          {
            etiqueta: TXT_PASO1_OXXO,
            info: "",
          },
          {
            etiqueta: "",
            info: "",
          },
          {
            etiqueta: `${TXT_PASO2_OXXO} ${this.getPrecio()} ${TXT_PASO2_CONTINUACION_OXXO}`,
            info: "",
          },
          {
            etiqueta: TXT_PASO3_OXXO,
            info: "",
          },
        ];
      }
    } else {
      detalle_Cita = [
        {
          etiqueta: TXT_FECHA_HORA,
          info: `${cita.diaSemana} ${cita.diaMes} de ${cita.mes}`,
        },
        {
          etiqueta: "Hora:",
          info: `${cita.horario === "PM" ? "Por la Tarde" : "Por la Mañana"}, ${
            cita.horaCita
          } ${cita.horario === "PM" ? "pm" : "am"}`,
        },
        {
          etiqueta: TXT_MEDIO_CONTACTO,
          info: `${
            cita.contacto === "Llamada" ? "Llamada" : "Llamada con Video"
          }`, // Remplazar variable
        },
        {
          etiqueta: TXT_NOMBRE,
          info: `${infoPx[0].paciente1} ${infoPx[0].apellidoP1} ${infoPx[0].apellidoM1}`,
        },
        {
          etiqueta: TXT_TELEFONO,
          info: `${infoPx[2].telefono}`,
        },
        {
          etiqueta: TXT_EMAIL,
          info: `${infoPx[2].email}`,
        },
        {
          etiqueta: TXT_COSTO,
          info: this.getPrecio(),
        },
        // {
        //   etiqueta: TXT_TIPO_CITA,
        //   info: "Orientación Médica Oftalmológica",
        // },
      ];
    }

    this.state = {
      width: window.innerWidth,
      modal: false,
      loadingCancel: false,
      titulo,
      subtitulo,
      baseUrl,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { tipoPagoCita } = location.state;
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.scrollTo(0, 0);
    if (citaCancelada) {
      window.location.reload(true);
    }

    /* Retrocompatibilidad con versiones viejas de navegadores */
    if (is.mobile() || is.tablet()) {
      // móviles y tablets
      if (
        is.ie() ||
        is.edge("<17") ||
        is.chrome("<70") ||
        is.firefox("<63") ||
        is.opera("<46") ||
        is.safari("<10.3") ||
        is.blackberry()
      ) {
        medio = getUrlParameter("medio");
        cmp = getUrlParameter("cmp");
      }
      // if (process.env.NODE_ENV !== 'production') { console.tron.log(`[Confirmacion] Es un celular, con chrome: ${is.chrome()}, con safari: ${is.safari()}, con firefox: ${is.firefox()}`) }
    } else if (
      is.ie() ||
      is.chrome("<49") ||
      is.firefox("<44") ||
      is.edge("<17") ||
      is.opera("<36") ||
      is.safari("<10.1")
    ) {
      // Navegadores web
      medio = getUrlParameter("medio");
      cmp = getUrlParameter("cmp");
    }
    try {
      // The return value is the canvas element
      bwipjs.toCanvas("mycanvas", {
        bcid: "code128", // Barcode type
        text: tipoPagoCita.clabe, // Text to encode
        scale: 2, // 3x scaling factor
        height: 10, // Bar height, in millimeters
        includetext: true, // Show human-readable text
        textxalign: "center", // Always good to set this
      });
    } catch (e) {}
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  cancelarCita = () => {
    this.setState({ loadingCancel: true });
    const { baseUrl } = this.state;
    const { location, history } = this.props;
    const { state } = location;
    let { para, header, cita, historialFlujo } = state;
    historialFlujo.flujo1 = [];
    historialFlujo.flujoStep = 0;
    let newHistory = { ...state, header, historialFlujo };

    if (para.paraQuien === 3) {
      const cancelData = { id: cita.response1.id };
      const cancelDataAcmp = { id: cita.response2.id };

      if (process.env.NODE_ENV !== "production") {
        console.tron.log(
          `[Confirmacion] cancelData: ${JSON.stringify(cancelData)}`
        );
      }
      axios
        .post(`${baseUrl}/Cancel`, cancelData)
        .then((responseCancel) => {
          if (process.env.NODE_ENV !== "production") {
            console.tron.log(
              `[Confirmacion] Cancel response 1: ${JSON.stringify(
                responseCancel.data
              )}`
            );
          }

          axios
            .post(`${baseUrl}/Cancel`, cancelDataAcmp)
            .then((responseCancelAcmp) => {
              if (process.env.NODE_ENV !== "production") {
                console.tron.log(
                  `[Confirmacion] Cancel response 2: ${JSON.stringify(
                    responseCancelAcmp.data
                  )}`
                );
              }
              citaCancelada = true;
              history.push(`/${URL_CANCEL}`, newHistory);
            })
            .catch((errorCancelAcmp) => {
              console.error(
                `[Confirmacion] Error al cancelar segunda cita ${errorCancelAcmp}`
              );
              if (process.env.NODE_ENV !== "production") {
                console.tron.error(
                  `[Confirmacion] Error al cancelar segunda cita ${errorCancelAcmp}`
                );
              }
            });
        })
        .catch((errorCancel) => {
          console.error(
            `[Confirmacion] Error al cancelar primera cita ${errorCancel}`
          );
          if (process.env.NODE_ENV !== "production") {
            console.tron.error(
              `[Confirmacion] Error al cancelar primera cita ${errorCancel}`
            );
          }
        });
    } else {
      const cancelData = { id: cita.response.id };

      if (process.env.NODE_ENV !== "production") {
        console.tron.log(
          `[Confirmacion] Cancel Post ${JSON.stringify(cancelData)}`
        );
      }
      axios
        .post(`${baseUrl}/Cancel`, cancelData)
        .then((response) => {
          if (process.env.NODE_ENV !== "production") {
            console.tron.log(
              `[Confirmacion] Cita cancelada. Cancel response: ${JSON.stringify(
                response.data
              )}`
            );
          }
          citaCancelada = true;
          history.push(`/${URL_CANCEL}`, newHistory);
        })
        .catch((errorCancel) => {
          console.error(`[Confirmacion] Error al cancelar cita ${errorCancel}`);
          if (process.env.NODE_ENV !== "production") {
            console.tron.log(
              `[Confirmacion] Error al cancelar cita ${errorCancel}`
            );
          }
        });
    }
  };

  reagendarCita = () => {
    const { location, history } = this.props;

    const newHistory = {
      ...location.state,
      reagendar: true,
    };

    history.push(`/${URL_REAGENDAR}${history.location.search}`, newHistory);
  };

  getDay = () => {
    const { para, cita } = this.props.location.state;
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    let datetime = null;

    if (para.paraQuien !== 3) {
      datetime = cita.dateTime;
    } else {
      datetime = cita.dateTime1;
    }

    const mes = datetime.substring(5, 7);
    const dia = datetime.substring(8, 10);
    const year = datetime.substring(0, 4);
    const mesTexto = meses[parseInt(mes, 10) - 1];

    return `${dia} ${mesTexto}, ${year}`;
  };

  getHour = () => {
    const { para, cita } = this.props.location.state;

    if (para.paraQuien !== 3) {
      const hora = cita.dateTime.substring(11, 16);
      return hora;
    }

    const hora1 = cita.dateTime1.substring(11, 16);
    const hora2 = cita.dateTime2.substring(11, 16);
    return `${hora1} y ${hora2}`;
  };

  getPrecio = () => {
    const { serviceId, header, cita } = this.props.location.state;

    if (cmp === "C-MKT-LASIK-001" && serviceId === 1748018) {
      return `$ 0.00 MXN`;
    }
    if (medio === 'Metro' && serviceId === 1747562) {
      return `$ 499.00 MXN`;
    }
    if (medio === "GDLV") {
      if (header[0].tarjetaID === 1) {
        // Consulta Adulto
        return `$ 150.00 MXN`;
      }
      // Consulta Niños, Lasik y Claravisión
      return `$ 300.00 MXN`;
    }

    if (cita.response1) {
      // Cuando es promocion 2x1
      return `$ ${cita.response1.price} MXN`;
    }
    if (cita.response2) {
      return `$ ${cita.response2.price} MXN`;
    }
    if(cita.response.price === undefined || cita.response.price === 'undefined' || cita.response.price === null){
      return `$ 0.00 MXN`;
    }

    return `$ ${cita.response.price} MXN`;
  };

  toggleModalCancel = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  printDocument = () => {
    window.print();
  };
  render() {
    const { modal, loadingCancel } = this.state;
    const { location } = this.props;
    const { datetime, para, cita, tipoPagoCita } = location.state;

    let calEvent = "";
    const endDatetime = new Date(datetime);
    endDatetime.setTime(endDatetime.getTime() + 90 * 60 * 1000);

    if (para.paraQuien !== 3) {
      if (calEventName === "Valoración") {
        calEventDesc +=
          " NOTA: Como es posible que te dilaten los ojos te recomendamos venir acompañado.";
      }
      calEvent = {
        title: calEventName,
        description: calEventDesc,
        startTime: datetime,
        endTime: endDatetime,
      };
    } else {
      if (calEventName === "Consulta Oftalmológica") {
        calEventName = `2x1 ${calEventName}`;
        calEventDesc +=
          " NOTA: Recuerda que si no vienes con tu acompañante o si alguno de los dos ya ha venido a salauno, no les podremos aplicar la promoción.";
      } else {
        calEventName += " (Para Ti y Para Alguien Más)";
      }

      const endDatetimeDoble = new Date(cita.dateTime2);
      endDatetimeDoble.setTime(endDatetimeDoble.getTime() + 90 * 60 * 1000);
      calEvent = {
        title: calEventName,
        description: calEventDesc,
        startTime: cita.dateTime1,
        endTime: endDatetimeDoble,
      };
    }
    const calItems = [
      { google: "Google" },
      { apple: "iCal" },
      { outlook: "Outlook" },
      { outlookcom: "Outlook.com" },
      { yahoo: "Yahoo" },
    ];
    const calIcon = { "calendar-plus-o": "left" };

    if (cita.paraQuien !== 3) {
      BOTONMODIFICAR = (
        <button
          className={styles.botonModificar}
          onClick={this.reagendarCita}
          type="button"
        >
          {"MODIFICAR"}
        </button>
      );
    }

    // const paciente2 =
    //   cita.paraQuien === 3
    //     ? `${infoPx[1].paciente2} ${infoPx[1].apellidoP2} ${infoPx[1].apellidoM2}`
    //     : "";

    return (
      <Container fluid className={`${styles.containerConfirmacionOMO}`}>
        <Container
          className={`${styles.confirmacionOMOContainer}`}
          id="printPdf"
        >
          <Row className={`${styles.rowContainerInfoRecomendOMO}`}>
            {/* //!Columna donde se muestra la informacion de la cita y los botones */}
            <Col xs={12} md={6} className={`${styles.colContainerInfoOMO}`}>
              <Row className={`${styles.rowContainerAllInfo}`}>
                <Col>
                  {/* //!Fila para el icono */}
                  <Row className={`${styles.rowContainerIcon}`}>
                    {/* //!Columna para el icono */}
                    <Col xs={12} className={`${styles.colContainerIcon}`}>
                      <img
                        src={
                          tipoPagoCita !== undefined
                            ? tipoPagoCita.tipo === "Tarjeta"
                              ? Festeja
                              : FestejaSpeiOxxo
                            : Festeja
                        }
                        className={`${styles.colContainerIcon__icon}`}
                        alt="icon_Spei_Oxxo"
                      />
                    </Col>
                  </Row>
                  {/* //!Fila para el titulo */}
                  <Row className={`${styles.rowContainerTitle}`}>
                    {/* //!olumna para el titulo */}
                    <Col xs={12} className={`${styles.colContainerTitle}`}>
                      <p className={`${styles.colContainerTitle__title}`}>
                        {tipoPagoCita !== undefined
                          ? tipoPagoCita.tipo === "Tarjeta"
                            ? TXT_TITLE_TARJETA
                            : TXT_TITLE_SPEIOXXO
                          : TXT_TITLE_TARJETA}
                      </p>
                    </Col>
                  </Row>
                  {/* //!Fila para el subtitulo */}
                  <Row className={`${styles.rowContainerSubTitle}`}>
                    {/* //!Columna para subtitulo */}
                    <Col xs={12} className={`${styles.colContainerSubTitle}`}>
                      <p className={`${styles.colContainerSubTitle__subTitle}`}>
                        {tipoPagoCita !== undefined
                          ? tipoPagoCita.tipo === "Tarjeta"
                            ? TXT_SUBTITLE_TARJETA
                            : tipoPagoCita.tipo === "spei"
                            ? TXT_SUBTITLE_SPEI
                            : TXT_SUBTITLE_OXXO
                          : TXT_SUBTITLE_TARJETA}
                      </p>
                    </Col>
                  </Row>
                  {/* //!Fila para imprimir los datos de la cita */}
                  <Row className={`${styles.rowContainerDetallesDeCita}`}>
                    {detalle_Cita.map((obj, index) => {
                      if (tipoPagoCita !== undefined) {
                        if (tipoPagoCita.tipo === "Tarjeta") {
                          if (obj.info !== "sincorreo@salauno.com.mx") {
                            return (
                              <Col
                                xs={12}
                                className={`${styles.colContainerDetalleDeCita}`}
                              >
                                <p
                                  className={`${styles.colContainerDetalleDeCita__p}`}
                                >{`${obj.etiqueta} ${obj.info}`}</p>
                              </Col>
                            );
                          } else {
                            return null;
                          }
                        } else if (tipoPagoCita.tipo === "spei") {
                          if (obj.info !== "sincorreo@salauno.com.mx") {
                            return (
                              <Col
                                xs={12}
                                className={`${styles.colContainerDetalleDeCita}`}
                              >
                                <p
                                  className={`${styles.colContainerDetalleDeCita__p}`}
                                >
                                  {`${index + 1}. ${obj.etiqueta}`}
                                </p>
                                {obj.info !== "" && (
                                  <p
                                    className={`${styles.colContainerDetalleDeCita__pInfoSpei}`}
                                  >
                                    {obj.info}
                                  </p>
                                )}
                              </Col>
                            );
                          } else {
                            return null;
                          }
                        } else {
                          if (obj.info !== "sincorreo@salauno.com.mx") {
                            return (
                              <Col
                                xs={12}
                                className={`${styles.colContainerDetalleDeCita}`}
                              >
                                {obj.etiqueta !== "" ? (
                                  <p
                                    className={`${styles.colContainerDetalleDeCita__p}`}
                                  >
                                    {`${index + 1}. ${obj.etiqueta}`}
                                  </p>
                                ) : (
                                  <div
                                    className={`${styles.divContainerCanvas}`}
                                  >
                                    <canvas
                                      id="mycanvas"
                                      className={
                                        styles.colContainerReferenceOxxo__canvas
                                      }
                                    ></canvas>
                                  </div>
                                )}
                              </Col>
                            );
                          } else {
                            return null;
                          }
                        }
                      } else {
                        if (obj.info !== "sincorreo@salauno.com.mx") {
                          return (
                            <Col
                              xs={12}
                              className={`${styles.colContainerDetalleDeCita}`}
                            >
                              <p
                                className={`${styles.colContainerDetalleDeCita__p}`}
                              >{`${obj.etiqueta} ${obj.info}`}</p>
                            </Col>
                          );
                        } else {
                          return null;
                        }
                      }
                    })}
                  </Row>
                  {tipoPagoCita !== undefined
                    ? tipoPagoCita.tipo === "spei" && (
                        <Row className={`${styles.rowContainerInfoSpei}`}>
                          <Col
                            xs={12}
                            className={`${styles.colContainerInfoSpei}`}
                          >
                            <p className={`${styles.colContainerInfoSpei__p}`}>
                              *Conserva este comprobante digital para cualquier
                              aclaración.
                            </p>
                          </Col>
                        </Row>
                      )
                    : null}
                  {/* //!Fila para boton cancelar y modificar */}
                  <Row
                    className={`${styles.rowContainerCancelModif} d-print-none`}
                  >
                    <Col
                      xs={12}
                      md={6}
                      className={`${styles.colContainerCancelar}`}
                    >
                      <button
                        className={stylesBoton.BotonGeneralActivo_blanco}
                        onClick={this.toggleModalCancel}
                        type="button"
                      >
                        Cancelar
                      </button>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      className={`${styles.colContainerModificar}`}
                    >
                      {BOTONMODIFICAR}
                    </Col>
                  </Row>
                  {/* //!Fila para separador horizontal */}
                  <Row
                    className={`${styles.rowContainerSeparador} d-print-none`}
                  >
                    {/* //!Columna para separador */}
                    <Col xs={12} className={`${styles.colContainerSeparador}`}>
                      <div
                        className={`${styles.colContainerSeparador__separadorOmo}`}
                      />
                    </Col>
                  </Row>
                  {/* //!Fila para boton agendar a calendario */}
                  <Row
                    className={`${styles.rowContainerCalendario} d-print-none`}
                  >
                    <Col xs={12} className={`${styles.colContainerCalendario}`}>
                      {tipoPagoCita !== undefined ? (
                        tipoPagoCita.tipo === "Tarjeta" ? (
                          <button
                            className={stylesBoton.BotonGeneralActivo_blanco}
                            type="button"
                          >
                            <AddToCalendar
                              event={calEvent}
                              buttonTemplate={calIcon}
                              buttonLabel="Agregar a mi calendario"
                              buttonClassClosed={
                                estilos.reactAddToCalendarButton
                              }
                              useFontAwesomeIcons
                              buttonWrapperClass={
                                estilos.reactAddToCalendarWrapper
                              }
                              displayItemIcons
                              dropdownClass={estilos.reactAddToCalendarDropdown}
                              listItems={calItems}
                              rootClass={estilos.reactAddToCalendar}
                            />
                          </button>
                        ) : (
                          <button
                            className={styles.BotonDescargarPdf}
                            onClick={this.printDocument}
                          >
                            {tipoPagoCita.tipo === "spei"
                              ? "DESCARGAR INSTRUCCIONES TRANSFERENCIA SPEI"
                              : "DESCARGAR EN FORMATO PDF"}
                          </button>
                        )
                      ) : (
                        <button
                          className={stylesBoton.BotonGeneralActivo_blanco}
                          type="button"
                        >
                          <AddToCalendar
                            event={calEvent}
                            buttonTemplate={calIcon}
                            buttonLabel="Agregar a mi calendario"
                            buttonClassClosed={estilos.reactAddToCalendarButton}
                            useFontAwesomeIcons
                            buttonWrapperClass={
                              estilos.reactAddToCalendarWrapper
                            }
                            displayItemIcons
                            dropdownClass={estilos.reactAddToCalendarDropdown}
                            listItems={calItems}
                            rootClass={estilos.reactAddToCalendar}
                          />
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {/* //!Columna donde se mustran recomendaciones */}
            <Col
              xs={12}
              md={6}
              className={`${styles.colContainerRecomendaciones}`}
            >
              <Row className={`${styles.rowContainerCorreoInfo}`}>
                <Col
                  xs={12}
                  className={`${styles.colContainerCorreoInfo} d-flex align-items-center`}
                >
                  <Row className={`${styles.rowCorreo}`}>
                    <Col
                      xs={12}
                      className={`${styles.colCorreo} d-flex align-items-center`}
                    >
                      <Row className={`${styles.rowContainerCorreoTitles}`}>
                        <Col
                          xs={12}
                          className={`${styles.colContainerCorreoTitle} d-flex justify-content-center align-items-center`}
                        >
                          <p
                            className={`${styles.colContainerCorreoTitle__title}`}
                          >
                            <span>Revisa tu Correo </span>
                            <span>Electrónico</span>
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          className={`${styles.colContainerCorreoP} d-flex justify-content-center align-items-center`}
                        >
                          <p className={`${styles.colContainerCorreoP__p}`}>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              Te enviamos un correo electrónico con los pasos
                              que
                            </p>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              debes seguir para prepararte para recibir tu
                            </p>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              orientación médica oftalmológica de manera remota.
                            </p>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              Si no lo recibiste o tienes cualquier duda llama
                              al
                            </p>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              55 4777 8383
                            </p>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  className={`${styles.colContainerInfoRecomendaciones} d-flex align-items-center`}
                >
                  <Row className={`${styles.rowContainerInfoRecomendaciones}`}>
                    <Col
                      xs={12}
                      className={`${styles.colRecomendaciones} d-flex align-items-center`}
                    >
                      <Row className={`${styles.rowRecomendaciones}`}>
                        <Col
                          xs={12}
                          className={`${styles.colRecomendacionesTitle} d-flex justify-content-center align-items-center`}
                        >
                          <p
                            className={`${styles.colRecomendacionesTitle__title}`}
                          >
                            <span>Sigue estas recomendaciones al</span>
                            <span>momento de recibir la llamada</span>
                            <span>de tu doctor de los ojos:</span>
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          className={`${styles.colContainerP} d-flex justify-content-center align-items-center`}
                        >
                          <p className={`${styles.colContainerP__p}`}>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              Se puntual al recibir tu llamada de voz o video.
                            </p>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              Al hablar con el especialista, hazle saber todos
                              tus
                            </p>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              síntomas e información que le ayude a identificar
                              tu
                            </p>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              padecimiento e historial médico. Recibe las
                            </p>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              indicaciones para el seguimiento de tu
                              padecimiento y
                            </p>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              si es necesario programar tu visita a la clínica
                              de
                            </p>
                            <p
                              className={`${styles.colConainerP__lineaParrafo}`}
                            >
                              manera presencial.
                            </p>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Modal
                isOpen={modal}
                toggle={this.toggleModalCancel}
                className={styles.modalContainer}
                id="modal_cancelarCita"
                backdropClassName={`${styles.backdropModal}`}
                modalClassName={`${styles.modal}`}
                contentClassName={`${styles.modalContanet}`}
              >
                <ModalHeader
                  toggle={this.toggleModalCancel}
                  className={`${styles.modalHeader}`}
                  buttonClassClosed={`${styles.modalButtonClosed}`}
                />
                <ModalBody className={`${styles.modalBody}`}>
                  <>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          className={`d-flex justify-content-center align-items-center`}
                        >
                          <img src={Admiracion} alt="modal_Admiracion" />
                        </Col>
                        <Col
                          xs={12}
                          className={`${styles.colContainerTextModalCancelar} d-flex justify-content-center align-items-center`}
                        >
                          <p className={styles.textModalCancelar}>
                            {TXT_MODAL_CANCEL}
                          </p>
                        </Col>
                      </Row>
                    </Container>
                    {loadingCancel ? (
                      <Container>
                        <Row>
                          <Col xs={12}>
                            <div
                              className={`${styles.col_cancelarSi} d-flex justify-content-center`}
                            >
                              <PulseLoader
                                sizeUnit="px"
                                size={15}
                                margin="0"
                                color="#00748c"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    ) : (
                      <Container>
                        <Row>
                          <Col
                            className={`${styles.col_cancelarSi} d-flex justify-content-center`}
                            xs={12}
                          >
                            <button
                              onClick={this.cancelarCita}
                              className={`${styles.col_cancelarSiButton}`}
                            >
                              SI, CANCELAR MI CITA
                            </button>
                          </Col>
                        </Row>
                      </Container>
                    )}
                  </>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

Confirmacion.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  className: PropTypes.any,
};

Confirmacion.defaultProps = {
  location: null,
  history: null,
  className: null,
};
