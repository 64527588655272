import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { getParams } from "functions/GetUrlParams";

import DiagnosticosData from "data/Diagnosticos.json";

import clinicas from "data/clinica.json";
import FlowsData from "data/flows.json";
import pilotoProsperia from "data/pilotoProsperiaGDLV.json";
import guardianesSHCP from "data/guardianesSHCP.json";
import optometriaGDLV from "data/optometriaGDLV.json";
import pilotoLinkCampanas from "data/pilotoLinkCampanas.json";

import AzucarConfig from "data/AzucarConfig.json";
import SOYMASConfig from "data/SOYMASConfig.json";
import CLIVIConfig from "data/CLIVIConfig.json";
import ConfigAliados from "data/ConfigAliados.json";
//import GDLVConfig from "data/GDLVConfig.json";

import Bienvenido from "./Bienvenido";
import TamizarPacienteAgendaGral from "../CreaPacienteTamizado/TamizarPacienteAgendaGral";

const urlParams = getParams();
// Variables que guardan el id de diagnostico y clinica para realizar redireccionamiento
let indexDiagnostic;
let indexClinic;
// Se declara new history como variable global para poder hacer cambios segun el diagnostico y clinica
let newHistory = {
  historialFlujo: {
    flujoIsActive: false,
    flujoStep: 0,
    flujo1: [],
    tipoConsulta: "",
  },
  urlParams,
  sinPromocion: false,
  header: [],
  regresion: null,
  prioridad: null,
  para: {},
  diagnostico: {},
  promocion: null,
  clinica: {
    cambiarClinica: false,
  },
  reagendar: null,
  cita: {},
  calendarId: null,
  serviceId: null,
  tiempoAntesDeCita: null,
  infoPx: [],
  // gdlvCampaign: '',
  gdlvTipoConsulta: "",
  utm_campaing: "",
  edadPromocion: null,
};

const baseUrl =
  window.location.hostname === "citas.salauno.com.mx"
    ? "https://salauno-nodeserver.azurewebsites.net/salesforce"
    : "https://salauno-nodeserver-testing.azurewebsites.net/salesforce";

const BienvenidoContainer = ({ history, history: { location } }) => {
  const [campaign, setCampaign] = React.useState(null);
  const [region, setRegion] = React.useState(null);
  const [name, setName] = React.useState("CP-PROSPERIA-RED-");
  const [nameOpto, setNameOpto] = React.useState("CP-GDLV-");
  const [nameEsfuerzo, setEsfuerzo] = React.useState("");
  const [nameCampana, setNameCampana] = React.useState("");
  const [renderButtons, setRenderButtons] = React.useState(false);
  const [consultaCodigo, setConsultaCodigo] = React.useState("");
  const [nombreGuardian, setNombreGuardian] = React.useState("");
  const [idCampaign, setIdCampana] = React.useState("");
  const [esfuerzo, setEsf] = React.useState("");
  console.log("Nombre guardian: ", nombreGuardian);
  console.log("Nombre esfuerzo: ", esfuerzo);
  console.log("Nombre region: ", region);

  useEffect(() => {
    console.log("urlParams.cirugia:", urlParams.cirugia);
    if (!!urlParams.cirugia) {
      if (urlParams.cirugia !== null || urlParams.cirugia !== "") {
        if (urlParams.cirugia.includes("CX-")) {
          history.push("/graph" + location.search);
        } else {
          history.push("/PDFRecuperacion" + location.search);
        }
      }
    }
  }, []);

  // Se obtienen los parametros url necesarios para la cita
  const { clinicCode, ageUser, diagnostico, ruta } = newHistory.urlParams;
  // Funcion que obtiene el id del diagnostico
  const getObjectByDiagnosticName = (nameDiagnostic) =>
    Object.keys(DiagnosticosData).find(
      (key) => DiagnosticosData[key].descripcionUnida === nameDiagnostic
    );
  // Funcion que ontiene el id de la clinica
  const getObjectByClinicCodeName = () => {
    return Object.keys(clinicas).find(
      (key) => clinicas[key].ShortClinicName === clinicCode
    );
  };
  // Funcion que guarda el id del diagnostico en la variable global indexDiagnostic
  const setHistoryForNavigation = (index) => {
    indexDiagnostic = index;
  };
  // Funcion que guarda el id de la clinica en la variable global indexClinic
  const setClinicForNavigation = (index) => {
    indexClinic = index;
  };
  // Funcion que busca los datos necesarios sefun el diagnostico y la clinica
  const navegaSegunDiagnostico = (diagnostico) => {
    let diagnosticoIndex = getObjectByDiagnosticName(diagnostico);
    let clinicaIndex = getObjectByClinicCodeName();
    setHistoryForNavigation(diagnosticoIndex);
    setClinicForNavigation(clinicaIndex);
    navega();
  };
  // Funcion que hace el redirecionamiento por parametros
  const navega = () => {
    // Si el parametro ageUser es menor a 40 años se redirige a omo y se cambia el diagnostico por una cita de omo
    if (ageUser < 40) {
      // Se busca la prioridad correspondiente segun el diagnostico
      const { prioridad, id } =
        DiagnosticosData[
          DiagnosticosData[indexDiagnostic].alternativa.idDiagnostico
        ];
      // Se cambia la prioridad segun el diagnostico que viene por parametros
      newHistory.prioridad = prioridad;
      // Se busca el diagnostico correspondiente de omo segun el diagnostico que viene por parametros
      newHistory.diagnostico = {
        ...DiagnosticosData[id],
      };
      // Se cambian las opciones de flujo a omo
      newHistory.historialFlujo = {
        flujoIsActive: true,
        flujoStep: 1,
        flujo1: [
          "Indica tus síntomas",
          "Medio de contacto",
          "Fecha y hora",
          "Datos de contacto",
          "Forma de pago",
          "Confirmación de cita",
        ],
        tipoConsulta: "remoto",
      };
      // La ruta se cambia a WebsiteOMO para que el se navege solo por determinadas vistas
      newHistory.diagnostico.routes = "WebsiteOMO";
      // Se agregan las rutas de WebsiteOMO
      newHistory.diagnostico.routes = {
        ...FlowsData[newHistory.diagnostico.routes],
      };
    } else {
      // Si ageUser es mayor o igual a 40 años
      // La regresion es false para que sepamos que no debemos de regresar a la pagina anterior
      newHistory.regresion = false;
      // Se obtiene la prioridad del diagnostico
      newHistory.prioridad = DiagnosticosData[indexDiagnostic].prioridad;
      // Para quien = 1 para saber que es solo una persona la que hara la cita
      newHistory.para = { paraQuien: 1 };
      // Se obtiene el diagnostico correspodniente segun la opcion de diagnostico presencial que viene por parametros urll
      newHistory.diagnostico = { ...DiagnosticosData[indexDiagnostic] };
      newHistory.promocion = null;
      // Se indica que viene del sitio web y la ruta es presencial
      newHistory.diagnostico.routes = "WebsitePresencial";
      newHistory.diagnostico.type = "WebsitePresencial";
      // Se incluye la clinica dentro del estado segun la nomenclatura que viene por parametros url
      newHistory.clinica = {
        id: indexClinic,
        nombreClinica: clinicas[indexClinic].ClinicName,
        coordClinica: clinicas[indexClinic].ClinicGeoLocation,
        como: clinicas[indexClinic].comoLlego,
        direccion: clinicas[indexClinic].ClinicAddress,
      };
      // Se agregan las rutas de las vistas correspondientes
      newHistory.diagnostico.routes = {
        ...FlowsData[newHistory.diagnostico.routes],
      };
    }
    // Se redirige a la siguiente vista sin necesidad de oprimir el boton empezar
    history.push(
      newHistory.diagnostico.routes[history.location.pathname] +
        history.location.search,
      newHistory
    );
  };
  // Si todos los parametros requeridos vienen diferentes de null se puede comenzar el redireccinamiento para la cita proveniente del website
  if (
    diagnostico !== null &&
    clinicCode !== null &&
    ageUser !== null &&
    diagnostico !== null &&
    ruta !== null
  ) {
    // Se busca el id del diagnostico
    let diagnosticoIndex = getObjectByDiagnosticName(diagnostico);
    let clinicIndex = getObjectByClinicCodeName(clinicCode);
    // Si el diagnostico y la clinica se encuentran dentro de las opciones que se tienen comenzamos el redireccionamiento
    if (diagnosticoIndex !== undefined && clinicIndex !== undefined) {
      // Si el servicio no esta como servicio no disponible
      if (DiagnosticosData[diagnosticoIndex].servicioDisponible !== false) {
        // Se verifica que venga del website
        if (ruta === "WebsitePresencial") {
          // Se le asignan valores al estado
          newHistory.historialFlujo.flujo1 = [
            "Selecciona tu clínica",
            "Elige la fecha y hora",
            "Confirma tus datos de contacto",
            "Confirmación de cita",
          ];
          newHistory.historialFlujo.flujoStep = 1;
          newHistory.historialFlujo.tipoConsulta = "presencial";
          // Se navega segun el diagnostico elegido
          navegaSegunDiagnostico(diagnostico);
        }
      } else {
        // En caso de que el servicio este como no disponible
        // Redirigimos al usuario a la vista servicio no disponible
        history.push(
          "/cita/servicioNoDisponible" + history.location.search,
          newHistory
        );
      }
    }
  }

  const comenzar = () => {
    // Funcion que redirige a diagnosticos cuando no tiene parametros que provienen del website
    history.push("/cita/diagnosticos" + history.location.search, newHistory);
  };
  const comenzarConFlujo = (tipoConsulta) => {
    console.log("El valor de tipoConsulta en switch es: ", tipoConsulta);
    console.log("El valor de campaign en switch es: ", campaign);
    switch (tipoConsulta) {
      case "Guardianes de la vista candidato":
        newHistory.gdlvCampaign = campaign;
        history.push("/cita/candidato" + history.location.search, newHistory);
        break;

      case "Guardianes de la vista tamizaje":
        newHistory.gdlvCampaign = campaign;
        history.push("/cita/tamizaje" + history.location.search, newHistory);
        break;
      case "Consulta Adulto":
        newHistory.gdlvCampaign = campaign;
        newHistory.gdlvTipoConsulta = tipoConsulta;
        history.push(
          "/cita/diagnosticos" + history.location.search,
          newHistory
        );
        break;
      case "Agenda General":
        newHistory.gdlvCampaign = campaign;
        history.push(
          "/cita/tamizajeGeneral" + history.location.search,
          newHistory
        );
        break;
      default:
        newHistory.gdlvCampaign = campaign;
        newHistory.gdlvTipoConsulta = tipoConsulta;

        if (urlParams.medio === "Azucar") {
          if (
            tipoConsulta.includes("Retina") || tipoConsulta.includes("retina")
          ) {
            newHistory.defaultSucursal =
              AzucarConfig[urlParams.sucursal].clinicaSalaunoMasCercanaRetina;
              console.log("entra aqui porque es consulta retina default");
          } else {
            newHistory.defaultSucursal =
              AzucarConfig[urlParams.sucursal].clinicaSalaunoMasCercanaGeneral;
              console.log("entra aqui porque es consulta general");
          }
        }
        else if(urlParams.medio === "CLIVI"){
          if(tipoConsulta.includes("Retina") || tipoConsulta.includes("retina")){
            newHistory.defaultSucursal = CLIVIConfig[urlParams.sucursal].clinicaSalaunoMasCercanaRetina;
            console.log("entra aqui porque es consulta retina default");
          }
          else{
            newHistory.defaultSucursal = CLIVIConfig[urlParams.sucursal].clinicaSalaunoMasCercanaGeneral;
            console.log("entra aqui porque es consulta general CLIVI");
          }
        }
        else if(urlParams.medio === "N-SOYMAS"){
          if(tipoConsulta.includes("Retina") || tipoConsulta.includes("retina")){
            newHistory.defaultSucursal = SOYMASConfig[urlParams.sucursal].clinicaSalaunoMasCercanaRetina;
            console.log("entra aqui porque es ", tipoConsulta);
          }
          else{
            newHistory.defaultSucursal = SOYMASConfig[urlParams.sucursal].clinicaSalaunoMasCercanaGeneral;
            console.log("entra aqui porque es consulta general");
          }
        }
        else if(urlParams.medio === "JNH"){
          let parametros = new URLSearchParams(window.location.search);
          let medioparams = parametros.get('medio');
          if(tipoConsulta.includes("Retina") || tipoConsulta.includes("retina")){
            newHistory.defaultSucursal = ConfigAliados[medioparams].ClinicasConfig[urlParams.sucursal].clinicaSalaunoMasCercanaRetina;;
            console.log("entra aqui porque es ", tipoConsulta);
          }
          else{
            newHistory.defaultSucursal = ConfigAliados[medioparams].ClinicasConfig[urlParams.sucursal].clinicaSalaunoMasCercanaGeneral;
            console.log("entra aqui porque es consulta general JNH");
          }
        }

        /*if(urlParams.medio === "Azucar" || urlParams.medio  === "CLIVI" || urlParams.medio === "N-SOYMAS"){
          console.log("El valor de urlParams.medio es: ", urlParams.medio);
          if (urlParams.medio === "Azucar") {
            if (
              tipoConsulta.includes("Retina") || tipoConsulta.includes("retina")
            ) {
              newHistory.defaultSucursal =
                AzucarConfig[urlParams.sucursal].clinicaSalaunoMasCercanaRetina;
                console.log("entra aqui porque es consulta retina default");
            } else {
              newHistory.defaultSucursal =
                AzucarConfig[urlParams.sucursal].clinicaSalaunoMasCercanaGeneral;
                console.log("entra aqui porque es consulta general");
            }
          }
          else if(urlParams.medio === "CLIVI"){
            if(tipoConsulta.includes("Retina") || tipoConsulta.includes("retina")){
              newHistory.defaultSucursal = CLIVIConfig[urlParams.sucursal].clinicaSalaunoMasCercanaRetina;
              console.log("entra aqui porque es consulta retina default");
            }
            else{
              newHistory.defaultSucursal = CLIVIConfig[urlParams.sucursal].clinicaSalaunoMasCercanaGeneral;
              console.log("entra aqui porque es consulta general");
            }
          }
          else if(urlParams.medio === "N-SOYMAS"){
            if(tipoConsulta.includes("Retina") || tipoConsulta.includes("retina")){
              newHistory.defaultSucursal = SOYMASConfig[urlParams.sucursal].clinicaSalaunoMasCercanaRetina;
              console.log("entra aqui porque es ", tipoConsulta);
            }
            else{
              newHistory.defaultSucursal = SOYMASConfig[urlParams.sucursal].clinicaSalaunoMasCercanaGeneral;
              console.log("entra aqui porque es consulta general");
            }
          }
        }
        else{
          let parametros = new URLSearchParams(window.location.search);
          let medioparams = parametros.get('medio');
          console.log("El valor de urlParams.medio es: ", urlParams.medio);
          console.log(medioparams);
          if (ConfigAliados[medioparams] !== undefined){
            console.log("si existe agenda aliados: ", urlParams.medio);
            console.log("medioparams de agenda aliados: ", medioparams);
            if (
              tipoConsulta.includes("Retina") || tipoConsulta.includes("retina")
            ) {
              newHistory.defaultSucursal =
              ConfigAliados[medioparams].ClinicasConfig[urlParams.sucursal].clinicaSalaunoMasCercanaRetina;
                console.log("entra aqui porque es consulta retina default ALIADOS: ", ConfigAliados[medioparams].ClinicasConfig[urlParams.sucursal].clinicaSalaunoMasCercanaRetina);
            } else {
              newHistory.defaultSucursal =
              ConfigAliados[medioparams].ClinicasConfig[urlParams.sucursal].clinicaSalaunoMasCercanaRetina;
                console.log("entra aqui porque es consulta general ALIADOS: ", ConfigAliados[medioparams].ClinicasConfig[urlParams.sucursal].clinicaSalaunoMasCercanaGeneral);
            }
          }
        }*/

        
        history.push(
          "/cita/diagnosticos" + history.location.search,
          newHistory
        );
        break;
    }
  };

  const getArrayByTipoBienvenida = (tipoBienvenida) => {

    switch (tipoBienvenida.medio) {
      case "Azucar":
        // console.log("urlparams:", tipoBienvenida.sucursal);
        // console.log("Azucar object:", AzucarConfig[tipoBienvenida.sucursal]);
        setCampaign(AzucarConfig[tipoBienvenida.sucursal].campañaRelacionada);
        break;
      case "GDLV-P":
        // setCampaign(pilotoProsperia[0].campañaGuardian);
        break;
      case "Campanas":
        setCampaign(pilotoLinkCampanas[0].codigoCampana);
        break;
      case "GeneralCampanas":
        //setCampaign("METRO-OPTICA");
        break;
      case "Metro":
        setCampaign("METRO-OPTICA");
        break;
      case "SHCP":
        setCampaign(guardianesSHCP[0].campañaGuardian);
        break;
      case "GDLV-Optometria":
        // setCampaign(optometriaGDLV[0].nombreRegion);
        break;
      case "CLIVI":
        setCampaign(CLIVIConfig[urlParams.sucursal].campañaRelacionada);
        console.log("CLIVI:", CLIVIConfig[urlParams.sucursal].campañaRelacionada);
        break;
      case "N-SOYMAS":
        setCampaign(SOYMASConfig[urlParams.sucursal].campañaRelacionada);
        console.log("N-SOYMAS:", SOYMASConfig[urlParams.sucursal].campañaRelacionada);
        break;
      case "JNH":
        setCampaign(ConfigAliados[urlParams.medio].ClinicasConfig[urlParams.sucursal].campañaRelacionada);
        console.log("JNH:",ConfigAliados[urlParams.medio].ClinicasConfig[urlParams.sucursal].campañaRelacionada);
      default:
        setCampaign(null);
        break;
    }
    /*let parametros = new URLSearchParams(window.location.search);
    let medioparams = parametros.get('medio');
    console.log(medioparams);
    if (ConfigAliados[medioparams] !== undefined){
      console.log("entra a ConfigAliados: ", urlParams.medio);
      setCampaign(ConfigAliados[medioparams].ClinicasConfig[urlParams.sucursal].campañaRelacionada);
      console.log("CONFIGALIADOS-setCampaign:", ConfigAliados[medioparams].ClinicasConfig[urlParams.sucursal].campañaRelacionada);
    }
    else{
      switch (tipoBienvenida.medio) {
        case "Azucar":
          // console.log("urlparams:", tipoBienvenida.sucursal);
          // console.log("Azucar object:", AzucarConfig[tipoBienvenida.sucursal]);
          setCampaign(AzucarConfig[tipoBienvenida.sucursal].campañaRelacionada);
          break;
        case "GDLV-P":
          // setCampaign(pilotoProsperia[0].campañaGuardian);
          break;
        case "Campanas":
          setCampaign(pilotoLinkCampanas[0].codigoCampana);
          break;
        case "GeneralCampanas":
          //setCampaign("METRO-OPTICA");
          break;
        case "Metro":
          setCampaign("METRO-OPTICA");
          break;
        case "SHCP":
          setCampaign(guardianesSHCP[0].campañaGuardian);
          break;
        case "GDLV-Optometria":
          // setCampaign(optometriaGDLV[0].nombreRegion);
          break;
        case "CLIVI":
          setCampaign(CLIVIConfig[urlParams.sucursal].campañaRelacionada);
          console.log("CLIVI:", CLIVIConfig[urlParams.sucursal].campañaRelacionada);
          break;
        case "N-SOYMAS":
          setCampaign(SOYMASConfig[urlParams.sucursal].campañaRelacionada);
          console.log("N-SOYMAS:", SOYMASConfig[urlParams.sucursal].campañaRelacionada);
          break;
        default:
          setCampaign(null);
          break;
      }
    }*/
    
  };

  useEffect(() => {
    console.log("DENTRO DE useEffect.");
    if (urlParams.medio === "CI") {
      history.push(`/cita/bienvenidaCI`, newHistory);
    }
    if (urlParams.medio === "GDLV") {
      document.addEventListener("message", (messageData) => {
        newHistory.gdlvCampaign = messageData.data;
      });
    }
    getArrayByTipoBienvenida(urlParams);
    console.log("history:", history);

    if (!!history.location.state) {
      if (
        history.location.state.gdlvCampaign != null &&
        history.location.state.nombreGuardian != null
      ) {
        console.log(
          "*** EL NOMBRE DEL GUARDIAN ES :",
          history.location.state.nombreGuardian
        );
        console.log(
          "*** EL NOMBRE DEL ESFUERZO :",
          history.location.state.esfuerzo
        );
        setCampaign(history.location.state.gdlvCampaign);
        setName(history.location.state.gdlvCampaign);
        setNameOpto(history.location.state.gdlvCampaign);
        setEsfuerzo(history.location.state.gdlvCampaign);
        setNameCampana(history.location.state.gdlvCampaign);
        setNombreGuardian(history.location.state.nombreGuardian);
        setEsf(history.location.state.esfuerzo);
        setRenderButtons(true);
      }
    } else {
      setRenderButtons(false);
    }
  }, []);

  const busquedaCampaign = (event) => {
    setName(event.target.value);
    let auxGuardian = event.target.value.split("");
    console.log("auxGuardian : ", auxGuardian);
    console.log(
      "auxGuardian includes GRIN-P : ",
      event.target.value.includes("GRIN-P")
    );
    console.log("auxGuardian.length : 16", auxGuardian.length === 16);

    if (auxGuardian.length >= 16 || event.target.value.includes("GRIN-P")) {
      const salesforcePost = {
        action: "consultaCampaignGDLV",
        codGuardian: event.target.value,
      };
      axios
        .post(`${baseUrl}/endpointsGDLV`, salesforcePost)
        .then((response) => {
          console.log(response.data);
          if (
            response.data.StatusCode === 404 &&
            response.data.StatusMessage === "Campaña prosperia no encontrada"
          ) {
            if (
              response.data.campaign[0].nombreCampaign.includes("PROSPERIA")
            ) {
              setCampaign(response.data.campaign[0].nombreCampaign);
              setNombreGuardian(response.data.campaign[0].nombreGuardian);
              setConsultaCodigo("Campaña encontrada");
              setIdCampana(response.data.campaign[0].idCampana);
            } else {
              setCampaign(response.data.campaign[0].nombreCampaign);
              setNombreGuardian(response.data.campaign[0].nombreGuardian);
              setConsultaCodigo("Campaña no encontrada");
              setIdCampana(response.data.campaign[0].idCampana);
            }
          } else if (
            response.data.StatusCode === 404 &&
            response.data.StatusMessage ===
              "No Existen campañas con este guardian"
          ) {
            setConsultaCodigo("No Existen campañas");
            setCampaign(null);
          } else {
            setConsultaCodigo("");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setConsultaCodigo("");
    }

    pilotoProsperia.map((guardian) => {
      if (guardian.nombreGuardian === event.target.value) {
        if (guardian.campañaGuardian === null) {
          guardian.campañaGuardian = "sin campaña";
        }
        auxGuardian.push(guardian);
      } else {
        console.log("no coinciden");
      }
    });
    console.log(auxGuardian);
    setCampaign(auxGuardian.length > 0 ? auxGuardian[0].campañaGuardian : null);
    setNombreGuardian(
      auxGuardian.length > 0 ? auxGuardian[0].campañaGuardian : null
    );
  };

  const handleChange = (event) => {
    setCampaign(event.target.value);
  };
  const handleChangeOpto = (event) => {
    setRegion(event.target.value);
  };
  const handleCodigoOpto = (event) => {
    setNameOpto(event.target.value);
  };
  const handleEsfuerzo = (event) => {
    setEsfuerzo(event.target.value);
  };
  const handleCodigoCampana = (event) => {
    setNameCampana(event.target.value);
  };

  const busquedaCampaignOpto = (nameOpto, region) => {
    setNameOpto(nameOpto);
    setRegion(region);
    console.log("Region: ", region);
    let auxGuardian = nameOpto.split("");
    console.log(auxGuardian.length === 13);

    if (region !== null) {
      if (region !== "-- Selecciona --") {
        if (auxGuardian.length >= 13) {
          const salesforcePost = {
            action: "consultaCampaignOPTO",
            codGuardian: nameOpto,
            regionOpto: region,
          };
          axios
            .post(`${baseUrl}/endpointsGDLV`, salesforcePost)
            .then((response) => {
              console.log("response.data", response.data);
              if (
                response.data.StatusCode === 200 &&
                response.data.StatusMessage === "Campaña prosperia encontrada"
              ) {
                setCampaign(response.data.campaign[0].nombreCampaign);
                setNombreGuardian(response.data.campaign[0].nombreGuardian);
                setConsultaCodigo("Campaña encontrada");
                setIdCampana(response.data.campaign[0].idCampana);
                newHistory.nombreGuardian =
                  response.data.campaign[0].nombreGuardian;
              } else if (
                response.data.StatusCode === 404 &&
                response.data.StatusMessage ===
                  "Campaña prosperia no encontrada"
              ) {
                if (
                  response.data.campaign[0].nombreCampaign.includes("PROSPERIA")
                ) {
                  setCampaign(response.data.campaign[0].nombreCampaign);
                  setNombreGuardian(response.data.campaign[0].nombreGuardian);
                  setConsultaCodigo("Campaña encontrada");
                  setIdCampana(response.data.campaign[0].idCampana);
                } else {
                  setCampaign(response.data.campaign[0].nombreCampaign);
                  setNombreGuardian(response.data.campaign[0].nombreGuardian);
                  setConsultaCodigo("Campaña no encontrada");
                  setIdCampana(response.data.campaign[0].idCampana);
                }
              } else if (
                response.data.StatusCode === 404 &&
                response.data.StatusMessage ===
                  "No Existen campañas con este guardian"
              ) {
                setConsultaCodigo("No Existen campañas");
                setCampaign(null);
              } else {
                setConsultaCodigo("");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setConsultaCodigo("");
        }
      } else {
        setConsultaCodigo("");
      }
    }

    pilotoProsperia.map((guardian) => {
      if (guardian.nombreGuardian === nameOpto) {
        if (guardian.campañaGuardian === null) {
          guardian.campañaGuardian = "sin campaña";
        }
        auxGuardian.push(guardian);
      } else {
        console.log("no coinciden");
      }
    });
    console.log(auxGuardian);
    setCampaign(auxGuardian.length > 0 ? auxGuardian[0].campañaGuardian : null);
    setNombreGuardian(
      auxGuardian.length > 0 ? auxGuardian[0].campañaGuardian : null
    );
  };

  //////////////////////  --------------------------------              --------------------------------- //

  const busquedaCampaignGeneral = (nameOpto, region) => {
    setNameOpto(nameOpto);
    setRegion(region);
    console.log("Region: ", region);
    console.log("Name Opto: ", nameOpto);
    let auxGuardian = nameOpto.split("");
    console.log(auxGuardian.length === 6);
    if (nameOpto.includes("GDLV")) {
      console.log("La palabra SI contiene la subcadena 'GDLV'.");
      if (region !== null) {
        if (region !== "-- Selecciona --") {
          if (auxGuardian.length >= 6) {
            const salesforcePost = {
              action: "consultaCampaignOPTO",
              //action: "consultaCampaignOPTOCampanas",

              codGuardian: nameOpto,
              regionOpto: region,
            };
            axios
              .post(`${baseUrl}/endpointsGDLV`, salesforcePost)
              .then((response) => {
                console.log("Este es response.data: ", response.data);
                if (
                  response.data.StatusCode === 200 &&
                  response.data.StatusMessage === "Campaña prosperia encontrada"
                ) {
                  setCampaign(response.data.campaign[0].nombreCampaign);
                  setNombreGuardian(response.data.campaign[0].nombreGuardian);
                  setConsultaCodigo("Campaña encontrada");
                  setIdCampana(response.data.campaign[0].idCampana);
                  setEsf(response.data.campaign[0].esfuerzo);
                } else {
                  setCampaign(response.data.campaign[0].nombreCampaign);
                  setNombreGuardian(response.data.campaign[0].nombreGuardian);
                  setConsultaCodigo("Campaña no encontrada");
                  setIdCampana(response.data.campaign[0].idCampana);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            setConsultaCodigo("");
          }
        } else {
          setConsultaCodigo("");
        }
      }
    } else {
      // No es campaña GDLV
      console.log("La palabra NO contiene la subcadena 'GDLV'.");
      if (region !== null) {
        if (region !== "-- Selecciona --") {
          if (auxGuardian.length >= 2) {
            const salesforcePost = {
              action: "consultaCampaignGeneral",
              codGuardian: nameOpto,
            };
            axios
              .post(`${baseUrl}/endpointsGDLV`, salesforcePost)
              .then((response) => {
                console.log(response.data);

                if (
                  response.data.StatusCode === 200 &&
                  response.data.StatusMessage === "Campaña prosperia encontrada"
                ) {
                  console.log(
                    "VALOR DE ESFUERZO :",
                    response.data.campaign[0].esfuerzo
                  );
                  setCampaign(response.data.campaign[0].nombreCampaign);
                  setNombreGuardian(response.data.campaign[0].nombreGuardian);
                  setEsf(response.data.campaign[0].esfuerzo);
                  setConsultaCodigo("Campaña encontrada");
                  setIdCampana(response.data.campaign[0].idCampana);
                  newHistory.nombreGuardian =
                    response.data.campaign[0].nombreGuardian;
                } else if (
                  response.data.StatusCode === 404 &&
                  response.data.StatusMessage ===
                    "Campaña prosperia no encontrada"
                ) {
                  if (
                    response.data.campaign[0].nombreCampaign.includes(
                      "PROSPERIA"
                    )
                  ) {
                    setCampaign(response.data.campaign[0].nombreCampaign);
                    setNombreGuardian(response.data.campaign[0].nombreGuardian);
                    setConsultaCodigo("Campaña encontrada");
                    setIdCampana(response.data.campaign[0].idCampana);
                  } else {
                    setCampaign(response.data.campaign[0].nombreCampaign);
                    setNombreGuardian(response.data.campaign[0].nombreGuardian);
                    setConsultaCodigo("Campaña no encontrada");
                    setIdCampana(response.data.campaign[0].idCampana);
                  }
                } else if (
                  response.data.StatusCode === 404 &&
                  response.data.StatusMessage ===
                    "No Existen campañas con este guardian"
                ) {
                  setConsultaCodigo("No Existen campañas");
                  setCampaign(null);
                } else {
                  setConsultaCodigo("");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            setConsultaCodigo("");
          }
        } else {
          setConsultaCodigo("");
        }
      }
    }

    pilotoProsperia.map((guardian) => {
      if (guardian.nombreGuardian === nameOpto) {
        if (guardian.campañaGuardian === null) {
          guardian.campañaGuardian = "sin campaña";
        }
        auxGuardian.push(guardian);
      } else {
        console.log("no coinciden");
      }
    });
    console.log(auxGuardian);
    setCampaign(auxGuardian.length > 0 ? auxGuardian[0].campañaGuardian : null);
    setNombreGuardian(
      auxGuardian.length > 0 ? auxGuardian[0].campañaGuardian : null
    );
  };

  //////////////////////  --------------------------------              --------------------------------- //

  const selecCampaign = () => {
    setRenderButtons(true);
  };
  const returnBusqueda = () => {
    setRenderButtons(false);
    setName("CP-PROSPERIA-RED-");
    setConsultaCodigo("");
  };
  const returnBusquedaOpto = () => {
    setRenderButtons(false);
    setNameOpto("CP-GDLV-");
    setNameCampana("");
    setConsultaCodigo("");
  };
  const returnBusquedaCampaña = () => {
    setRenderButtons(false);
    setNameOpto("");
    setEsfuerzo("");
    setConsultaCodigo("");
    setEsf("");
  };

  const creaCampaign = () => {
    const salesforcePost = {
      action: "creaCampaignGDLV",
      idCampanaGuardian: idCampaign,
    };
    axios
      .post(`${baseUrl}/endpointsGDLV`, salesforcePost)
      .then((response) => {
        console.log(response.data);
        if (
          response.data.StatusCode === 200 &&
          response.data.StatusMessage === "La campaña de prosperia existe"
        ) {
          setCampaign(response.data.campaign.nombreCampaign);
          setNombreGuardian(response.data.campaign.nombreGuardian);
          setIdCampana(response.data.campaign.idCampana);
        } else if (
          response.data.StatusCode === 200 &&
          response.data.StatusMessage === "Campaña creada correctamente"
        ) {
          console.log(response.data.campaign.nombreCampaign);
          console.log(response.data.campaign.nombreGuardian);
          setCampaign(response.data.campaign.nombreCampaign);
          setNombreGuardian(response.data.campaign.nombreGuardian);
          setIdCampana(response.data.campaign.idCampana);
        } else {
          setCampaign(null);
          setNombreGuardian("");
          setIdCampana("");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setRenderButtons(true);
  };

  const creaCampaignOpto = () => {
    const salesforcePost = {
      action: "creaCampaignOPTO",
      idCampanaGuardian: idCampaign,
      regionOpto: region,
    };
    axios
      .post(`${baseUrl}/endpointsGDLV`, salesforcePost)
      .then((response) => {
        console.log(response.data);
        if (
          response.data.StatusCode === 200 &&
          response.data.StatusMessage === "La campaña de prosperia existe"
        ) {
          setCampaign(response.data.campaign.nombreCampaign);
          setNombreGuardian(response.data.campaign.nombreGuardian);
          setIdCampana(response.data.campaign.idCampana);
        } else if (
          response.data.StatusCode === 200 &&
          response.data.StatusMessage === "Campaña creada correctamente"
        ) {
          console.log(response.data.campaign.nombreCampaign);
          console.log(response.data.campaign.nombreGuardian);
          setCampaign(response.data.campaign.nombreCampaign);
          setNombreGuardian(response.data.campaign.nombreGuardian);
          setIdCampana(response.data.campaign.idCampana);
        } else {
          setCampaign(null);
          setNombreGuardian("");
          setIdCampana("");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setRenderButtons(true);
  };

  return (
    <Bienvenido
      comenzar={comenzar}
      urlParams={urlParams}
      handleChange={handleChange}
      handleChangeOpto={handleChangeOpto}
      handleCodigoOpto={handleCodigoOpto}
      handleEsfuerzo={handleEsfuerzo}
      handleCodigoCampana={handleCodigoCampana}
      campaign={campaign}
      comenzarConFlujo={comenzarConFlujo}
      setCampaign={setCampaign}
      region={region}
      busquedaCampaign={busquedaCampaign}
      busquedaCampaignOpto={busquedaCampaignOpto}
      busquedaCampaignGeneral={busquedaCampaignGeneral}
      creaCampaign={creaCampaign}
      creaCampaignOpto={creaCampaignOpto}
      name={name}
      nameOpto={nameOpto}
      nameEsfuerzo={nameEsfuerzo}
      nameCampana={nameCampana}
      renderButtons={renderButtons}
      consultaCodigo={consultaCodigo}
      nombreGuardian={nombreGuardian}
      selecCampaign={selecCampaign}
      returnBusqueda={returnBusqueda}
      returnBusquedaOpto={returnBusquedaOpto}
      idCampaign={idCampaign}
      esfuerzo={esfuerzo}
    />
  );
};
export default withRouter(BienvenidoContainer);
