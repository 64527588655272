import React from "react";
import GeneralHeader from "./GeneralHeader";
import { getParams } from "functions/GetUrlParams";
import { withRouter } from "react-router-dom";

const urlParams = getParams();

let newHistory = {
  historialFlujo: {
    flujoIsActive: false,
    flujoStep: 0,
    flujo1: [],
    tipoConsulta: "",
  },
  urlParams,
  sinPromocion: false,
  header: [],
  regresion: null,
  prioridad: null,
  para: {},
  diagnostico: {},
  promocion: null,
  clinica: {
    cambiarClinica: false,
  },
  reagendar: null,
  cita: {},
  calendarId: null,
  serviceId: null,
  tiempoAntesDeCita: null,
  infoPx: [],
  // gdlvCampaign: '',
  gdlvTipoConsulta: "",
  edadPromocion: null,
};

const GeneralHeaderContainer = ({ location, history }) => {
  let contactCenter;

  if (
    history.location.state &&
    history.location.state.urlParams &&
    history.location.state.urlParams.medio === "GeneralCampanas"
  ) {
    contactCenter = "&isContactCenter=true";
  } else {
    contactCenter = "";
  }
  const regresarAlInicio = () => {
    newHistory.gdlvCampaign = history.location.state.gdlvCampaign;
    newHistory.nombreGuardian = history.location.state.nombreGuardian;
    console.log(newHistory.gdlvCampaign);
    //history.push('/?medio=GDLV-P'+ history.location.search,newHistory);
    history.push({
      pathname: "/cita/bienvenida",
      search:
        "?medio=" + history.location.state.urlParams.medio + contactCenter,
      state: newHistory,
    });
  };
  return (
    <GeneralHeader location={location} regresarAlInicio={regresarAlInicio} />
  );
};

export default withRouter(GeneralHeaderContainer);
