import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import StepConnector from "@material-ui/core/StepConnector";
import sprite from "../../../images/svg/SVG_FormasDePago/sprite.svg";
import styles from "./FlujoHistorial.module.css";

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,#FFD83D 0%,#FFD83D 50%,#FFD83D 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,#FFD83D 0%,#FFD83D 50%,#FFD83D 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#fff",
    borderRadius: 1,
  },
})(StepConnector);

export const ColorlibConnectorOMO = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,#FFD83D 0%,#FFD83D 50%,#FFD83D 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,#FFD83D 0%,#FFD83D 50%,#FFD83D 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#fff",
    borderRadius: 1,
  },
})(StepConnector);

export const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    zIndex: 1,
    color: "#10798b",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fill: "rgba(0,116,140,0.25)",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, #00788A 0%, #00788A 50%, #00788A 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    fill: "#fff",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, #00788A 0%, #00788A 50%, #00788A 100%)",
    color: "#fff",
    fill: "#fff",
    position: "relative",
    "&:before": {
      borderColor: "#ffcd00",
      display: "block",
      position: "absolute",
      width: 15,
      height: 15,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      backgroundColor: "#FFD83D",
      content: '""',
      top: 0,
      right: 0,
      borderRadius: "50%",
    },
  },
});

export function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons = {
    1: (
      <svg className={`${styles.stepperIconActive}`}>
        <use xlinkHref={`${sprite}#icon-map`} x={7} y={2} />
      </svg>
    ),
    2: (
      <svg className={`${styles.stepperIconActive}`}>
        <use xlinkHref={`${sprite}#icon-calendar`} x={5} y={2} />
      </svg>
    ),
    3: (
      <svg className={`${styles.stepperIconActive}`}>
        <use xlinkHref={`${sprite}#icon-notas`} x={7} y={2} />
      </svg>
    ),
    4: (
      <svg className={`${styles.stepperIconActive}`}>
        <use xlinkHref={`${sprite}#icon-confirmacion`} x={5} y={1} />
      </svg>
    ),
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {/* {icons[String(props.icon)]} */}
      {icons[String(props.icon)]}
    </div>
  );
}

export function ColorlibStepIconOMO(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons = {
    1: (
      <svg className={`${styles.stepperIconActive}`}>
        <use xlinkHref={`${sprite}#icon-indicaSintomas`} x={4} y={1} />
      </svg>
    ),
    2: (
      <svg className={`${styles.stepperIconActive}`}>
        <use xlinkHref={`${sprite}#icon-medioContacto`} x={9} y={2} />
      </svg>
    ),
    3: (
      <svg className={`${styles.stepperIconActive}`}>
        <use xlinkHref={`${sprite}#icon-calendar`} x={6} y={2} />
      </svg>
    ),
    4: (
      <svg className={`${styles.stepperIconActive}`}>
        <use xlinkHref={`${sprite}#icon-notas`} x={7} y={1} />
      </svg>
    ),
    5: (
      <svg className={`${styles.stepperIconActive}`}>
        <use xlinkHref={`${sprite}#icon-formaPago`} x={0} y={1} />
      </svg>
    ),
    6: (
      <svg className={`${styles.stepperIconActive}`}>
        <use xlinkHref={`${sprite}#icon-confirmacion`} x={5} y={1} />
      </svg>
    ),
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {/* {icons[String(props.icon)]} */}
      {icons[String(props.icon)]}
    </div>
  );
}

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "75%",
    backgroundColor: "rgba(102, 113, 128, -0.1)",
    ["@media (min-width:1500px)"]: {
      width: "65%",
      paddingBottom:"55px"
    },
    ["@media (max-width:992px)"]: {
      width:"75%"
    },
    ["@media (max-width:767px)"]: {
      width:"100% !important"
    }
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const useStylesOMO = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "rgba(102, 113, 128, -0.1)",
    ["@media (min-width:1500px)"]: {
      width: "100%",
    },
    ["@media (max-width:443px)"]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    ["@media (max-width:400px)"]: {
      width: "82%",
      overflowX: "auto",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
