import React from "react";
import { Container, Row, Col } from "reactstrap";
import pilotoProsperia from "data/pilotoProsperiaGDLV.json";
import pilotoLinkCampanas from "data/pilotoLinkCampanas.json";
import guardianesSHCP from "data/guardianesSHCP.json";
import optometriaGDLV from "data/optometriaGDLV.json";
import sprite from "images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";

import styles from "./BienvenidoAliadosStyles.module.css";

import ConfigAliados from "data/ConfigAliados.json";

export default ({
  comenzar,
  urlParams,
  handleChange,
  handleChangeOpto,
  handleCodigoOpto,
  handleEsfuerzo,
  nameEsfuerzo,
  esfuerzo,
  handleCodigoCampana,
  comenzarConFlujo,
  region,
  busquedaCampaign,
  busquedaCampaignOpto,
  busquedaCampaignGeneral,
  creaCampaign,
  creaCampaignOpto,
  name,
  nameOpto,
  nameCampana,
  campaign,
  renderButtons,
  consultaCodigo,
  nombreGuardian,
  selecCampaign,
  returnBusqueda,
  returnBusquedaOpto,
  idCampaign,
}) => {
  const renderTipoBienvenidaButtons = (tipoBienvenida) => {
    //ConfigAliados[urlParams.medio].ClinicasConfig[urlParams.sucursal].clinicaSalaunoMasCercanaGeneral;
    let tipoFlujoAdulto = ConfigAliados[urlParams.medio].servicios.consultaAdulto.flujo;
    console.log("tipoFlujo Adulto:", tipoFlujoAdulto);

    let tipoFlujoRetina = ConfigAliados[urlParams.medio].servicios.consultaRetina.flujo;
    console.log("Tipo flujo Pediatria:", tipoFlujoRetina);

    let paramAaliados = ConfigAliados[urlParams.medio].parameter;
  console.log("PARA IMAGEN ALIADOS",paramAaliados);
  /*const promoImg = require("images/png/promociones/" +
    promoData.parameter +
    ".jpg");*/
  const promoImg = require("images/png/promociones/" +
    paramAaliados +
    ".jpg");
    return (
        
        <Row className={`${styles.rowContainerButtonsGDLV}`}>
            <Col xs={2} />
            <Col className={`justify-content-center py-5`} xs={6}>
            <img
                src={promoImg}
                alt={ConfigAliados[urlParams.medio].titulo}
                className={`img-fluid mx-auto d-block`}
            />
            </Col>
            <Col xs={2} />
          <Col className={`${styles.colContainerButtonGDLV}`} lg={12} xs={12}>
            {" "}
            <button
              className={`${styles.colContainerButton__button}`}
              onClick={() => comenzarConFlujo(tipoFlujoRetina)}
              type="button"
            >
              <Container fluid>
                <Row>
                  <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                    CONSULTA RETINA
                  </Col>
                  <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                    <span>
                      <svg className={`${styles.svg}`}>
                        <use xlinkHref={`${sprite}#icon-arrow-right`} />
                      </svg>
                    </span>
                  </Col>
                </Row>
              </Container>
            </button>
          </Col>
          {}
          <Col lg={12} xs={12} className={styles.spacingCol}><br></br></Col>
          {<Col className={`${styles.colContainerButtonGDLV}`} lg={6} xs={12}>
            {" "}
            <button
              className={`${styles.colContainerButton__button}`}
              onClick={() =>
                comenzarConFlujo(tipoFlujoAdulto)
              }
              type="button"
            >
              <Container fluid>
                <Row>
                  <Col xs={7} className={`${styles.colContainerNombreBoton}`}>
                    CONSULTA GENERAL
                  </Col>
                  <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                    <span>
                      <svg className={`${styles.svg}`}>
                        <use xlinkHref={`${sprite}#icon-arrow-right`} />
                      </svg>
                    </span>
                  </Col>
                </Row>
              </Container>
            </button>
          </Col> }
        </Row>
      );
  };

  const renderPickListTipoBienvenida = (tipoBienvenida) => {
    switch (tipoBienvenida) {
      case "Campanas":
        return (
          <Col xs={12} className={`${styles.colContainerListGuardianes}`}>
            <h5>Nombre del guardian</h5>
            <select onChange={(e) => handleChange(e)}>
              {pilotoLinkCampanas.map((campana) => (
                <option value={campana.codigoCampana}>
                  {campana.nombreCampana}
                </option>
              ))}
            </select>
          </Col>
        );
        break;
      case "SHCP":
        return (
          <Col xs={12} className={`${styles.colContainerListGuardianes}`}>
            <h5>Nombre de usuario </h5>
            <select onChange={(e) => handleChange(e)}>
              {guardianesSHCP.map((campana) => (
                <option value={campana.campañaGuardian}>
                  {campana.nombreGuardian}
                </option>
              ))}
            </select>
          </Col>
        );
        break;
      default:
        return null;
        break;
    }
  };

  return (
    <Container fluid className={`${styles.containerBienvenido}`}>
      <Container className={`${styles.bienvenidoContainer}`}>
        <Row className={`${styles.rowContainer}`}>
          <Col xs={12} className={`${styles.colContantainer}`}>
            <Row className={`${styles.rowContainerBienvenido}`}>
              <Col xs={12} className={`${styles.colContainerTitleSubTitle}`}>
                <Row className={`${styles.rowContainerTitleSubtitle}`}>
                  <Col xs={12} className={`${styles.colContainerTitle}`}>
                    <p className={`${styles.colContainerTitle__title}`}>
                      Bienvenido a salauno
                    </p>
                  </Col>
                  <Col xs={12} className={`${styles.colContainerSubTitle}`}>
                    <p className={`${styles.colContainerSubTitle__subTitle}`}>
                      ¡Agenda una cita!
                    </p>
                  </Col>
                  {renderPickListTipoBienvenida(urlParams.medio)}
                  <Col xs={12} className={`${styles.colContainerButton}`}>
                    {renderTipoBienvenidaButtons(urlParams.medio)}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
