import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import DiagnosticosData from "../../data/Diagnosticos.json";
import LlamadaVideoLlamada from "./LlamadaVideoLlamada";
import FlowsData from "../../data/flows.json";

const serviceIdOMO = [0, 14115481, 14693076, 14693178];
const serviceIdVidOMO = [0, 14115481, 14693166, 14693207];
let indexDiagnostic;

const LlamadaVideoLlamadaContainer = ({ history, location }) => {
  if (process.env.NODE_ENV !== "production") {
    console.tron.display({
      name: `[TipoLlamada] History`,
      value: location.state,
      important: true,
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { state } = location;
  let { header, historialFlujo } = state;
  let { prioridad } = state;
  const [tipoContacto, setTipoContacto] = useState("Llamada");
  const [serviceIdContacto, setServiceIdContacto] = useState(
    serviceIdOMO[prioridad]
  );

  //!Funcion que actualiza el tipo de contacto que va a tener el paciente Llamada o Video Llamada
  const setContacto = (contacto) => {
    setTipoContacto(contacto);
    let serviceId =
      contacto === "Llamada"
        ? serviceIdOMO[prioridad]
        : serviceIdVidOMO[prioridad];
    setServiceIdContacto(serviceId);
  };

  //!Funcion que extrae de DiagnosticosData el servicio que se esta ofreciendo segun el nombre del servicio
  const getObjectByService = (nameService) => {
    return Object.keys(DiagnosticosData).find(
      (key) => DiagnosticosData[key].nombreServicio === nameService
    );
  };

  //!Funcion que guarda el index del servicio que se esta buscando
  const setHistoryForNavigation = (index) => {
    indexDiagnostic = index;
  };

  //! Funcion que obtiene la ruta hacia la que se navegara
  const navegaPorServicio = (servicio) => {
    let diagnosticoIndex = getObjectByService(servicio);
    setHistoryForNavigation(diagnosticoIndex);
  };

  //!Funcion para navegar a la siguiente pagina
  const irSiguientePagina = () => {
    let { flujoStep } = historialFlujo;
    historialFlujo.flujoStep = flujoStep + 1;
    const rutaA = "/cita/tipoLlamada";
    header.push({
      id: 3,
      titulo: "Tipo de llamada de contacto",
      dato: tipoContacto,
      ruta: rutaA,
    });

    let newHistory = {
      ...state,
      header,
      cita: {
        contacto: tipoContacto,
      },
      historialFlujo,
      serviceId: serviceIdContacto,
    };

    navegaPorServicio(state.diagnostico.nombreServicio);

    history.push(
      FlowsData[DiagnosticosData[indexDiagnostic].routes][location.pathname] +
        location.search,
      newHistory
    );
  };

  return (
    <LlamadaVideoLlamada
      contacto={tipoContacto}
      setContacto={setContacto}
      irSiguientePagina={irSiguientePagina}
    />
  );
};

export default withRouter(LlamadaVideoLlamadaContainer);
