import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import TipoConsultaPresencialOmo from './TipoConsultaPresencialOmo';
import DiagnosticosData from '../../data/Diagnosticos.json';
import FlowsData from '../../data/flows.json';

let indexDiagnostic;
let trueIds = 0;
let muestraModal = false;

const TipoConsultaPresencialOmoContainer = ({ history, location }) => {
	if (process.env.NODE_ENV !== 'production') {
		console.tron.display({
			name: `[TipoConsulta] History`,
			value: location.state,
		});
	}
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const { state } = location;
	const {
		diagnostico,
		historialFlujo,
		EdadPaciente,
		idsSeleccionadosOMO,
	} = state;
	const { prioridad } = diagnostico;
	const { type, alternativa } = state.diagnostico;
	const { idDiagnostico } = alternativa;
	const prioridadAlternativa =
		idDiagnostico !== '' && DiagnosticosData[idDiagnostico].prioridad;
	const [tipoConsultaState, setTipoConsultaState] = useState('');
	const getObjectByService = (nameService) => {
		return Object.keys(DiagnosticosData).find(
			(key) => DiagnosticosData[key].nombreServicio === nameService
		);
	};

	if (idsSeleccionadosOMO) {
		muestraModal = false;
		idsSeleccionadosOMO.map((id, index) => {
			if (id) {
				trueIds = trueIds + 1;
				if (trueIds > 1) {
					muestraModal = false;
				} else {
					muestraModal = false;
					if (index === 11 || index === 12 || index === 13) {
						muestraModal = true;
					}
				}
			}
		});
	}
	trueIds = 0;
	const navegaPorServicio = (servicio) => {
		let diagnosticoIndex = getObjectByService(servicio);
		setHistoryForNavigation(diagnosticoIndex);
	};
	const setHistoryForNavigation = (index) => {
		indexDiagnostic = index;
	};
	const switchPrecencialOmo = (tipoConsulta) => {
		setTipoConsultaState(tipoConsulta);
		let { header, diagnostico } = state;
		const rutaA = '/cita/tipoConsulta';
		header.push({
			id: 2,
			titulo: 'Seleccionar tipo de consulta',
			dato: 'Seleccionar tipo de consulta',
			ruta: rutaA,
		});
		if (tipoConsulta === 'opcionOMO') {
			//!Cuando elige omo
			//! Cuando viene de una omo y elige una omo
			const { tipoConsulta } = historialFlujo;
			if (tipoConsulta === 'remoto') {
				let newHistory = {
					...state,
				};
				history.push(
					state.diagnostico.routes[location.pathname] + history.location.search,
					newHistory
				);
				return;
			}
			//!Codigo cuando viene de presencial y elige OMO
			let newHistory = {
				...state,
				header,
				prioridad:
					idDiagnostico === ''
						? state.prioridad
						: DiagnosticosData[idDiagnostico].prioridad,
				diagnostico: {
					...DiagnosticosData[idDiagnostico],
				},
				historialFlujo: {
					flujoIsActive: true,
					flujoStep: 1,
					flujo1: [
						'Indica tus síntomas',
						'Medio de contacto',
						'Fecha y hora',
						'Datos de contacto',
						'Forma de pago',
						'Confirmación de cita',
					],
					tipoConsulta: 'remoto',
				},
			};

			newHistory.diagnostico.routes = {
				...FlowsData['presencial'],
				...FlowsData['opcionOMO'],
			};

			navegaPorServicio(state.diagnostico.nombreServicio);
			history.push(
				FlowsData[DiagnosticosData[idDiagnostico].routes][location.pathname] +
					history.location.search,
				newHistory
			);
		} else {
			//!Cuando elige presencial
			//!Codigo cuando viene de una omo y elige una presencial
			const { tipoConsulta } = historialFlujo;
			if (tipoConsulta === 'remoto') {
				const { idDiagnosticoOptometrista, idDiagnosticoGeneral } = alternativa;
				if (state.prioridad === 2) {
					let newHistory = {
						...state,
						header,
						prioridad: DiagnosticosData[idDiagnosticoGeneral].prioridad,
						diagnostico: {
							...DiagnosticosData[idDiagnosticoGeneral],
						},
						historialFlujo: {
							flujoIsActive: true,
							flujoStep: 0,
							flujo1: [
								'Selecciona tu clínica',
								'Elige la fecha y hora',
								'Confirma tus datos de contacto',
								'Confirmación de cita',
							],
							tipoConsulta: 'presencial',
						},
					};
					newHistory.diagnostico.routes = {
						...FlowsData['opcionPresencial'],
					};
					history.push(
						FlowsData[DiagnosticosData[idDiagnosticoGeneral].routes][
							location.pathname
						] + history.location.search,
						newHistory
					);
					return;
				}
				if (state.prioridad === 3) {
					let newHistory = {
						...state,
						header,
						prioridad: DiagnosticosData[idDiagnosticoOptometrista].prioridad,
						diagnostico: {
							...DiagnosticosData[idDiagnosticoOptometrista],
						},
						historialFlujo: {
							flujoIsActive: true,
							flujoStep: 0,
							flujo1: [
								'Selecciona tu clínica',
								'Elige la fecha y hora',
								'Confirma tus datos de contacto',
								'Confirmación de cita',
							],
							tipoConsulta: 'presencial',
						},
					};
					newHistory.diagnostico.routes = {
						...FlowsData['opcionPresencial'],
					};
					history.push(
						FlowsData[DiagnosticosData[idDiagnosticoOptometrista].routes][
							location.pathname
						] + history.location.search,
						newHistory
					);
					return;
				}
			}

			//! Codigo cuando viene de presencial y elige una presencial
			diagnostico.routes = {
				...FlowsData['presencial'],
				...FlowsData['opcionPresencial'],
			};

			let newHistory = {
				...state,
				header,
				diagnostico,
				historialFlujo: {
					flujoIsActive: true,
					flujoStep: 0,
					flujo1: [
						'Selecciona tu clínica',
						'Elige la fecha y hora',
						'Confirma tus datos de contacto',
						'Confirmación de cita',
					],
					tipoConsulta: 'presencial',
				},
			};
			history.push(
				newHistory.diagnostico.routes[location.pathname] +
					history.location.search,
				newHistory
			);
		}
	};
	const modalConsultaAEspecialista = () => {
		if (idsSeleccionadosOMO) {
			idsSeleccionadosOMO.map((id, index) => {
				if (id) {
					trueIds = trueIds + 1;
					if (trueIds === 1) {
						if (index === 11 || index === 12 || index === 13) {
							const { idDiagnosticoGeneral } = alternativa;
							let { header, diagnostico } = state;
							const rutaA = '/cita/tipoConsulta';
							header.push({
								id: 2,
								titulo: 'Seleccionar tipo de consulta',
								dato: 'Seleccionar tipo de consulta',
								ruta: rutaA,
							});
							const newHistory = {
								...state,
								header,
								prioridad: DiagnosticosData[2].prioridad,
								diagnostico: {
									...DiagnosticosData[2],
								},
								historialFlujo: {
									flujoIsActive: true,
									flujoStep: 0,
									flujo1: [
										'Selecciona tu clínica',
										'Elige la fecha y hora',
										'Confirma tus datos de contacto',
										'Confirmación de cita',
									],
									tipoConsulta: 'presencial',
								},
							};
							newHistory.diagnostico.routes = {
								...FlowsData['opcionPresencial'],
							};
							// console.log(newHistory);
							history.push(
								FlowsData[DiagnosticosData[idDiagnosticoGeneral].routes][
									location.pathname
								] + history.location.search,
								newHistory
							);
						}
					}
				}
			});
		}
	};
	return (
		<TipoConsultaPresencialOmo
			switchPrecencialOmo={switchPrecencialOmo}
			tipoConsultaState={tipoConsultaState}
			type={type}
			prioridad={prioridad}
			prioridadAlternativa={prioridadAlternativa}
			state={location.state}
			EdadPaciente={EdadPaciente}
			muestraModal={muestraModal}
			modalConsultaAEspecialista={modalConsultaAEspecialista}
		/>
	);
};

export default withRouter(TipoConsultaPresencialOmoContainer);
