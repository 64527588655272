import React from "react";
import { Container, Row, Col } from "reactstrap";

import Cirugias from "./Cirugias.json";
import Estudios from "./Estudios.json";
import Inyecciones from "./Inyecciones.json";
import Lasers from "./Laser.json";

import sprite from "images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";

import styles from "./CategoriasStyles.module.css";

const CategoriasCI = ({
  handleChangeCategoria,
  selectCategoria,
  handleChangeServicio,
  selectServicio,
  continuar,
}) => {
  const renderCategoriaItems = (categoria) => {
    if (categoria === "Estudio") {
      return Estudios.map((estudio) => (
        <Col xs={12} md={4} className={`${styles.colCategoria}`}>
          <button
            className={[
              selectServicio === estudio.nombreServicio
                ? styles.colCategoria_Button_hover
                : styles.colCategoria_Button,
            ]}
            type="button"
            onClick={() => handleChangeServicio(estudio)}
            key={estudio.key}
          >
            {estudio.nombreDisplay}
          </button>
        </Col>
      ));
    }
    if (categoria === "Cirugía") {
      return Cirugias.map((cirugia) => (
        <Col xs={12} md={4} className={`${styles.colCategoria}`}>
          <button
            className={[
              selectServicio === cirugia.nombreServicio
                ? styles.colCategoria_Button_hover
                : styles.colCategoria_Button,
            ]}
            type="button"
            onClick={() => handleChangeServicio(cirugia)}
            key={cirugia.key}
          >
            {cirugia.nombreDisplay}
          </button>
        </Col>
      ));
    }

    if (categoria === "Inyecciones") {
      return Inyecciones.map((inyeccion) => (
        <Col xs={12} className={`${styles.colCategoria}`}>
          <button
            className={[
              selectServicio === inyeccion.nombreServicio
                ? styles.colCategoria_Button_hover
                : styles.colCategoria_Button,
            ]}
            type="button"
            onClick={() => handleChangeServicio(inyeccion)}
            key={inyeccion.key}
          >
            {inyeccion.nombreDisplay}
          </button>
        </Col>
      ));
    }

    if (categoria === "Laser") {
      return Lasers.map((laser) => (
        <Col xs={12} className={`${styles.colCategoria}`}>
          <button
            className={[
              selectServicio === laser.nombreServicio
                ? styles.colCategoria_Button_hover
                : styles.colCategoria_Button,
            ]}
            type="button"
            onClick={() => handleChangeServicio(laser)}
            key={laser.key}
          >
            {laser.nombreDisplay}
          </button>
        </Col>
      ));
    }
  };
  return (
    <Container
      fluid
      className={[
        selectCategoria !== "Cirugía"
          ? styles.containerBienvenido
          : styles.containerBienvenidoCirugia,
      ]}
    >
      <Container className={`${styles.bienvenidoContainer}`}>
        <Row className={`${styles.rowContainer}`}>
          <Col className={`${styles.colContainer}`}>
            <Row className={`${styles.rowContainerTitle}`}>
              ¿Qué servicio estas buscando?
            </Row>
            <Row className={`${styles.rowContainerButtons}`}>
              <Col xs={3} className={`${styles.colButton}`}>
                <button
                  type="button"
                  className={[
                    selectCategoria === "Estudio"
                      ? styles.colButton_button__hover
                      : styles.colButton_button,
                  ]}
                  onClick={() => handleChangeCategoria("Estudio")}
                >
                  Estudio
                </button>
              </Col>
              <Col xs={3} className={`${styles.colButton}`}>
                <button
                  type="button"
                  className={[
                    selectCategoria === "Cirugía"
                      ? styles.colButton_button__hover
                      : styles.colButton_button,
                  ]}
                  onClick={() => handleChangeCategoria("Cirugía")}
                >
                  Cirugía
                </button>
              </Col>
              <Col xs={3} className={`${styles.colButton}`}>
                <button
                  type="button"
                  className={[
                    selectCategoria === "Inyecciones"
                      ? styles.colButton_button__hover
                      : styles.colButton_button,
                  ]}
                  onClick={() => handleChangeCategoria("Inyecciones")}
                >
                  Inyecciones
                </button>
              </Col>
              <Col xs={3} className={`${styles.colButton}`}>
                <button
                  type="button"
                  className={[
                    selectCategoria === "Laser"
                      ? styles.colButton_button__hover
                      : styles.colButton_button,
                  ]}
                  onClick={() => handleChangeCategoria("Laser")}
                >
                  Laser
                </button>
              </Col>
            </Row>
            {selectCategoria !== null && (
              <Row
                className={[
                  selectCategoria === "Cirugía"
                    ? styles.rowCategoriasCIrugia
                    : styles.rowCategorias,
                ]}
              >
                {renderCategoriaItems(selectCategoria)}
              </Row>
            )}
            {selectServicio !== null && (
              <Row className={`${styles.rowButtonContinuar}`}>
                <Col className={`${styles.colButtonContinuar}`} xs={12}>
                  <button
                    className={`${styles.colContainerButton__button}`}
                    onClick={() => continuar()}
                    type="button"
                  >
                    <Container fluid>
                      <Row>
                        <Col
                          xs={7}
                          className={`${styles.colContainerNombreBoton}`}
                        >
                          Agenda tu cirugia
                        </Col>
                        <Col
                          xs={4}
                          className={`${styles.colContainerSpanBoton}`}
                        >
                          <span>
                            <svg className={`${styles.svg}`}>
                              <use xlinkHref={`${sprite}#icon-arrow-right`} />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default CategoriasCI;
