import React, { useState } from "react";
import CategoriasCI from "./CategoriasCI";

const CategoriasCIContainer = ({ history: { location }, history }) => {
  const { state, search } = location;

  console.log("state:", state);

  const newHistory = {
    ...state,
  };

  const [selectCategoria, setSelectCategoria] = useState(null);
  const [selectServicio, setSelectServicio] = useState(null);

  const handleChangeCategoria = (categoria) => {
    setSelectCategoria(categoria);
  };

  const handleChangeServicio = (servicio) => {
    setSelectServicio(servicio);
  };

  const continuar = () => {
    newHistory.CategoriaCI = selectCategoria;
    newHistory.ServicioCI = selectServicio;
    history.push(`/cita/datosCI${search}`, newHistory);
  };

  return (
    <CategoriasCI
      handleChangeCategoria={handleChangeCategoria}
      selectCategoria={selectCategoria}
      handleChangeServicio={handleChangeServicio}
      selectServicio={selectServicio}
      continuar={continuar}
    />
  );
};

export default CategoriasCIContainer;
