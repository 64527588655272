import React from "react";
import { Container, Row, Col } from "reactstrap";
import promociones from "../../data/promociones.json";

import ConfigAliados from "data/ConfigAliados.json";

import styles from "./Promociones.module.css";

export default ({
  goToPromo,
  backToHome,
  state,
  promoData,
  history,
  handleEdad,
  edad,
  location,
}) => {
  let {
    state: {
      urlParams: { promo },
    },
  } = location;
  let serviciosDePromocion;
  let promoImg;
  let parametros = new URLSearchParams(window.location.search);
  let medio = parametros.get('medio');
  console.log(medio);
  let usaConfigAliados;
  if(ConfigAliados[medio] !== undefined){//checar si es undefined
    usaConfigAliados = "serviciosPromos";
    let paramAaliados = ConfigAliados[medio].parameter;
    console.log("es la imagen-->",paramAaliados);
    promoImg = require("images/png/promociones/" +
      paramAaliados +
      ".jpg");
    serviciosDePromocion = ConfigAliados[medio];
    console.log("serviciosDePromocionALIADOS",serviciosDePromocion);
  }
  else{
    usaConfigAliados = "servicios";
    promoImg = require("images/png/promociones/" +
      promoData.parameter +
      ".jpg");
    serviciosDePromocion = promociones[promo];
    console.log("serviciosDePromocionPROMOCIONES",serviciosDePromocion);
  }
  /*const promoImg = require("images/png/promociones/" +
    promoData.parameter +
    ".jpg");*/
  
  //let serviciosDePromocion = promociones[promo];
  //let serviciosDePromocionConfigAliados = ConfigAliados[promo];
  const isDisable = (location, edad) => {
    let respuesta = false;
    if (promo === "ACOMPANANTE") {
      respuesta = edad === 0 ? true : edad < 60 ? true : false;
    } else {
      respuesta = false;
    }
    return respuesta;
  };
  return (
    <Container fluid className={`${styles.container}`}>
      <Row className={"no-gutters"}>
        <Col xs={2} />
        <Col className={`justify-content-center py-5`} xs={8}>
          <img
            src={promoImg}
            alt={serviciosDePromocion.titulo}
            className={`img-fluid mx-auto d-block`}
          />
        </Col>
        <Col xs={2} />
      </Row>
      <Row className={`${styles.rowContainerIncluye} no-gutters`}>
        <Col xs={2} />
        <Col className={`d-flex justify-content-center py-1`} xs={8}>
          <h2 className={`${styles.rowContainerIncluye__title}`}>
            Nuestro propósito:
          </h2>
        </Col>
        <Col xs={2} />
      </Row>
      <Row className={`${styles.rowContainerIncluye} no-gutters`}>
        <Col xs={2} />
        <Col
          className={`${styles.rowContainerIncluye__colIncluye} d-flex justify-content-center`}
          xs={8}
        >
          <ul className={`${styles.rowContainerIncluye__ul}`}>
            <li className={`${styles.rowContainerIncluye__li}`}>
              Dar a todos los mexicanos la posibilidad de ver bien y transformar
              su vida.
            </li>
          </ul>
        </Col>
        <Col xs={2} />
      </Row>
      <Row className={`${styles.rowContainerIncluye} no-gutters`}>
        <Col xs={2} />
        <Col className={`d-flex justify-content-center py-1`} xs={8}>
          <h2 className={`${styles.rowContainerIncluye__title}`}>
            ¿Qué es salauno?
          </h2>
        </Col>
        <Col xs={2} />
      </Row>
      <Row className={`${styles.rowContainerIncluye} no-gutters`}>
        <Col xs={2} />
        <Col
          className={`${styles.rowContainerIncluye__colIncluye} d-flex justify-content-center`}
          xs={8}
        >
          <ul className={`${styles.rowContainerIncluye__ul}`}>
            <li className={`${styles.rowContainerIncluye__li}`}>
              Es la red más grande de clínicas oftalmológicas en México, con 13 años de experiencia. Está conformada por un equipo altamente capacitado, enfocado en brindar un servicio oftalmológico integral.
            </li>
          </ul>
        </Col>
        <Col xs={2} />
      </Row>
      <Row className={`${styles.rowContainerIncluye} no-gutters py-5`}>
        <Col className={`d-flex justify-content-center`} xs={12}>
          <h2 className={`${styles.rowContainerIncluye__title}`}>
            La promoción aplica en consulta para adultos e incluye: 
          </h2>
        </Col>
        <Col
          className={`${styles.rowContainerIncluye__colIncluye} d-flex justify-content-center`}
          xs={12}
        >
          <ul className={`${styles.rowContainerIncluye__ul}`}>
            {serviciosDePromocion.incluye.map((item) => (
              <li className={`${styles.rowContainerIncluye__li}`}>{item}</li>
            ))}
          </ul>
        </Col>
      </Row>
      {(promo === "ACOMPANANTE" || promo === "SANOFI") && (
        <Row className={`${styles.rowContainerTextInputEdad}`}>
          <Col xs={12} className={`${styles.colContainerLabelEdad}`}>
            <label
              htmlFor="edad"
              className={`${styles.colContainerLabelEdad__label}`}
            >
              Ingresa tu edad
            </label>
          </Col>
          <Col xs={12} className={`${styles.colContainerTextInputEdad}`}>
            <input
              type="text"
              id="edad"
              name="edad"
              className={`${styles.colContainerTextInputEdad__TextInputEdad}`}
              onChange={handleEdad}
            />
          </Col>
        </Row>
      )}

      <Row className={`d-flex justify-content-center py-5 no-gutters`}>
        {  /*      
        serviciosDePromocion[usaConfigAliados].map((serviciosPromos) => {
          return (
            <Col
              className={`d-flex justify-content-center`}
              xs={12}
              md={5}
              lg={4}
            >
              <button
                type="button"
                onClick={() => goToPromo(serviciosPromos.idServicio)}
                className={`${styles.rowContainerButtons__promo2x1}`}
                disabled={isDisable(location, edad)}
              >
                {serviciosPromos.nombreBoton}
              </button>
            </Col>
          );
        })*/}
        <Col
          className={`${styles.rowContainerButtons__colButtonCitaNormal} d-flex justify-content-center py-0 py-sm-3 py-md-0`}
          xs={12}
          md={5}
          lg={4}
        >
          <button
            type="button"
            onClick={backToHome}
            className={`${styles.rowContainerButtons__citaNormal}`}
          >
            Agenda tu cita
          </button>
        </Col>
      </Row>
      <Row className={"no-gutters"}>
        <Col className={`d-flex justify-content-center py-5`} xs={12}>
        <ul className={`${styles.rowContainerRestricciones__ul}`}>
          {serviciosDePromocion.parameter !== "N-SOYMAS" && (
            <>
              <h3 className={`${styles.rowContainerRestricciones__h3}`}>
                Restricciones:
              </h3>
              {serviciosDePromocion.restricciones.map((restriccion, index) => (
                <li key={index} className={`${styles.rowContainerRestricciones__li}`}>
                  {restriccion}
                </li>
              ))}
            </>
          )}
          <div className={`${styles.datosAdicionalesSeparacion}`}>
            {serviciosDePromocion.datosAdicionales.map((dato, index) => (
              <label key={index}>{dato}</label>
            ))}
          </div>
        </ul>
         
        </Col>
      </Row>
    </Container>
  );
};
