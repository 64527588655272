// ? Firebase npm packages requeried
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getDatabase, ref, get } from "firebase/database";

// ? Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBNJubxl7vS0ovW81mMUhnPE6NR995Ifjk",
  authDomain: "salaunoapp.firebaseapp.com",
  databaseURL: "https://salaunoapp.firebaseio.com",
  projectId: "salaunoapp",
  storageBucket: "salaunoapp.appspot.com",
  messagingSenderId: "446581281300",
  appId: "1:446581281300:web:5cb6af8d94b204ebb8cd01",
  measurementId: "G-3HPJK4LWRN",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

console.log("consfiguración firebase:", firebaseApp);

const db = firebaseApp.firestore();
const database = getDatabase(firebaseApp);

export { db, firebaseApp, firebase, database };
