import React from "react";
import { Row, Col } from "reactstrap";
import styles from "../../css/ConfirmacionStyles.module.css";

export default ({ state }) => {
  const { diagnostico, clinica, cita, infoPx } = state;
  return (
    <>
      <Row className="px-5">
        <Col lg={3} sm={12}>
          <h1 className={styles.etiquetaConfirmacionPromo}>
            Su cita está programada para el servicio de:
          </h1>
          <h1 className={styles.informacionConfirmacionPromo}>
            {diagnostico.nombreServicio}
          </h1>
        </Col>
        <Col lg={3} sm={12}>
          <h1 className={styles.etiquetaConfirmacionPromo}>Clínica:</h1>
          <h1 className={styles.informacionConfirmacionPromo}>
            {clinica.nombreClinica}
          </h1>
        </Col>
        <Col lg={3} sm={12}>
          <h1 className={styles.etiquetaConfirmacionPromo}>
            El día y horario de tu cita es:
          </h1>
          <h1
            className={styles.informacionConfirmacionPromo}
          >{cita.fechaTextual}</h1>
        </Col>
        <Col lg={3} sm={12}>
          <h1 className={styles.etiquetaConfirmacionPromo}>
            Dirección de la clinica:
          </h1>
          <h1 className={styles.informacionConfirmacionPromo}>
            {clinica.direccion}
          </h1>
        </Col>
      </Row>
      <Row className="px-5 py-5">
        {/* //! Para el primer paciente */}
        <Col md="6" sm="6">
          <Row>
            <Col xs={12}>
              <h1 className={styles.etiquetaConfirmacionPromo}>
                Nombre del primer paciente:
              </h1>
            </Col>
            <Col xs={12}>
              <h1 className={styles.informacionConfirmacionPromo}>
                {`${infoPx[0].paciente1} ${infoPx[0].apellidoP1} ${infoPx[0].apellidoM1}`}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h1 className={styles.etiquetaConfirmacionPromo}>
                Telefono para contactarte:
              </h1>
            </Col>
            <Col xs={12}>
              <h1 className={styles.informacionConfirmacionPromo}>
                {infoPx[2].telefono}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h1 className={styles.etiquetaConfirmacionPromo}>
                Tu consulta tiene un precio de:
              </h1>
            </Col>
            <Col xs={12}>
              <h1 className={styles.informacionConfirmacionPromo}>
                {cita.response1.price !== "0.00"
                  ? `$ ${cita.response1.price} MXN`
                  : "$ 0 promoción 2x1"}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h1 className={styles.etiquetaConfirmacionPromo}>
                Correo electronico:
              </h1>
            </Col>
            <Col xs={12}>
              <h1 className={styles.informacionConfirmacionPromo}>
                {infoPx[2].email}
              </h1>
            </Col>
          </Row>
        </Col>
        {/* //!PAra el segundo paciente */}
        <Col md="6" sm="6">
          <Row>
            <Col xs={12}>
              <h1 className={styles.etiquetaConfirmacionPromo}>
                Nombre del segundo paciente:
              </h1>
            </Col>
            <Col xs={12}>
              <h1 className={styles.informacionConfirmacionPromo}>
                {`${infoPx[1].paciente2} ${infoPx[1].apellidoP2} ${infoPx[1].apellidoM2}`}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h1 className={styles.etiquetaConfirmacionPromo}>
                Telefono para contactarte:
              </h1>
            </Col>
            <Col xs={12}>
              <h1 className={styles.informacionConfirmacionPromo}>
                {infoPx[1].telefono}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h1 className={styles.etiquetaConfirmacionPromo}>
                Tu consulta tiene un precio de:
              </h1>
            </Col>
            <Col xs={12}>
              <h1 className={styles.informacionConfirmacionPromo}>
                {cita.response2.price !== "0.00"
                  ? `$ ${cita.response2.price} MXN`
                  : "$ 0 promoción 2x1"}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h1 className={styles.etiquetaConfirmacionPromo}>
                Correo electronico:
              </h1>
            </Col>
            <Col xs={12}>
              <h1 className={styles.informacionConfirmacionPromo}>
                {infoPx[1].emailAcompañante}
              </h1>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
