/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable semi */
/* eslint-disable arrow-body-style */
import React from "react";
import { Container, Row, Col } from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  // Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import EXEReports from "data/EXEReports.json";
import styles from "./SignosVitalesStyles.module.css";

// Inizialización de chart.js

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  // Filler,
  Legend
);

const SignosVitales = ({
  labels,
  dataSignosVitales,
  onHandleSelect,
  handleSelect,
}) => {
  // Funciones del componente "Separar en web hooks"
  const getDataByFilter = (reportTypeAttribute) => {
    let dataSignoVital = [];
    dataSignosVitales.map((signoVital) => {
      if (reportTypeAttribute === "sistolica") {
        const sistolica = signoVital.tensionArterial.split("/");
        dataSignoVital.push(sistolica[0]);
      } else if (reportTypeAttribute === "diastolica") {
        const diastolica = signoVital.tensionArterial.split("/");
        dataSignoVital.push(diastolica[1]);
      } else {
        dataSignoVital.push(signoVital[reportTypeAttribute]);
      }
    });

    return dataSignoVital;
  };

  const getTitleByFilter = (reportTypeAttribute) => {
    let reportTitleSelected = "";
    EXEReports.map((reportType) => {
      if (reportType.typeAttribute === reportTypeAttribute) {
        reportTitleSelected = reportType.reportType;
      }
    });
    return reportTitleSelected;
  };

  const getBorderColorByReport = (reportTypeAttribute) => {
    let reportBorderColor = "";
    EXEReports.map((reportType) => {
      if (reportType.typeAttribute === reportTypeAttribute) {
        reportBorderColor = reportType.borderColor;
      }
    });
    return reportBorderColor;
  };

  const getBackgroundColorByReport = (reportTypeAttribute) => {
    let reportBackgroundColor = "";
    EXEReports.map((reportType) => {
      if (reportType.typeAttribute === reportTypeAttribute) {
        reportBackgroundColor = reportType.backgroundColor;
      }
    });
    return reportBackgroundColor;
  };

  const getMetricasByReport = (reportTypeAttribute) => {
    let reportMetrics = "";
    if (reportTypeAttribute != "tensionArterial") {
      EXEReports.map((reportType) => {
        if (reportType.typeAttribute === reportTypeAttribute) {
          reportMetrics = reportType.metrica;
        }
      });
    } else {
      reportMetrics = "mmHg";
    }
    return reportMetrics;
  };

  const suggestedMinByReport = (reportTypeAttribute) => {
    let suggestedMin = null;
    EXEReports.map((reportType) => {
      if (reportType.typeAttribute === reportTypeAttribute) {
        suggestedMin = reportType.suggestedMin;
      }
    });
    return suggestedMin;
  };

  const suggestedMaxByReport = (reportTypeAttribute) => {
    let suggestedMax = null;
    EXEReports.map((reportType) => {
      if (reportType.typeAttribute === reportTypeAttribute) {
        suggestedMax = reportType.suggestedMax;
      }
    });
    return suggestedMax;
  };

  const getTitleByReport = (reportTypeAttribute) => {
    let title = null;
    if (reportTypeAttribute !== "tensionArterial") {
      EXEReports.map((reportType) => {
        if (reportType.typeAttribute === reportTypeAttribute) {
          title = reportType.reportType;
        }
      });
    } else {
      title = "Tensión Arterial";
    }
    return title;
  };

  // Construcción del componente dinamicamente
  let aux = 0;
  let scales = {};
  EXEReports.map((scaleData) => {
    if (handleSelect !== "tensionArterial") {
      if (handleSelect === scaleData.typeAttribute) {
        scales = {
          ...scales,
          [scaleData.yAxisID]: {
            type: "linear",
            display: true,
            position: "left",
            beginAtZero: true,
            grid: {
              drawOnChartArea: true,
            },
            suggestedMin: suggestedMinByReport(scaleData.typeAttribute),
            suggestedMax: suggestedMaxByReport(scaleData.typeAttribute),
          },
        };
      }
    } else {
      if (
        scaleData.typeAttribute === "sistolica" ||
        scaleData.typeAttribute === "diastolica"
      ) {
        scales = {
          ...scales,
          [scaleData.yAxisID]: {
            type: "linear",
            display: true,
            position: aux === 0 ? "left" : "right",
            beginAtZero: true,
            grid: {
              drawOnChartArea: true,
            },
            suggestedMin: suggestedMinByReport(scaleData.typeAttribute),
            suggestedMax: suggestedMaxByReport(scaleData.typeAttribute),
          },
        };
        aux = aux === 0 ? 1 : 0;
      }
    }
  });

  let datasets = [];

  EXEReports.map((reportType) => {
    if (handleSelect !== "tensionArterial") {
      if (handleSelect === reportType.typeAttribute) {
        let dataSet = {
          label: reportType.reportType,
          data: getDataByFilter(reportType.typeAttribute),
          borderColor: reportType.borderColor,
          backgroundColor: reportType.backgroundColor,
          yAxisID: reportType.yAxisID,
          tension: 0.05,
        };
        datasets.push(dataSet);
      }
    } else {
      if (
        reportType.typeAttribute === "sistolica" ||
        reportType.typeAttribute === "diastolica"
      ) {
        let dataSet = {
          label: reportType.reportType,
          data: getDataByFilter(reportType.typeAttribute),
          borderColor: reportType.borderColor,
          backgroundColor: reportType.backgroundColor,
          yAxisID: reportType.yAxisID,
          tension: 0.05,
        };
        datasets.push(dataSet);
      }
    }
  });

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: true,
    },
    stacked: true,
    plugins: {
      title: {
        display: true,
        text: getTitleByReport(handleSelect),
      },
    },
    scales,
    // scales: {
    //   y: {
    //     type: "linear",
    //     display: true,
    //     position: "left",
    //     beginAtZero: false,
    //     grid: {
    //       drawOnChartArea: true,
    //     },
    //     suggestedMin: suggestedMinByReport(handleSelect),
    //     suggestedMax: suggestedMaxByReport(handleSelect),
    //   },
    // },
  };

  const data = {
    labels,
    datasets,
    // datasets: [
    //   {
    //     fill: true,
    //     label: getTitleByFilter(handleSelect),
    //     data: getDataByFilter(handleSelect),
    //     borderColor: getBorderColorByReport(handleSelect),
    //     backgroundColor: getBackgroundColorByReport(handleSelect),
    //     yAxisID: "y",
    //   },
    // ],
  };

  // Renderización del componente
  return (
    <Container fluid className={styles.containerSignosVitales}>
      <Row className={styles.rowContainerGraph}> 
        <div className={styles.divContainerGraph}>
          <Col xs={12} className={styles.colContainerGraph}>
            <Row className={styles.rowContainerMetrics}>
              <Col xs={1} className={styles.colContainerMetrics}>
                <label
                  className={`${styles.rotate90Degrees} ${styles.fontMetrics}`}
                >
                  {getMetricasByReport(handleSelect)}
                </label>
              </Col>
              <Col xs={11} className={styles.colContainerGraphPicture}>
                <Line options={options} data={data} />
              </Col>
            </Row>
          </Col>
        </div>
      </Row>
      {/* <Row className={styles.rowContainerSelectOptions}>
        <Col xs={3} className={styles.colContainerFontText}>
          <p className={styles.fontText}>
            Elige la grafica que quieres visualizar:
          </p>
        </Col>
        <Col xs={9} className={styles.colContainerSelectOptions}>
          <select
            name="signos vitales"
            id="signosVitales"
            onChange={onHandleSelect}
            className={styles.selectStyles}
          >
            {EXEReports.map((signoVital) => {
              return (
                <option value={signoVital.typeAttribute}>
                  {signoVital.reportType}
                </option>
              );
            })}
          </select>
        </Col>
      </Row> */}
    </Container>
  );
};

export default SignosVitales;
