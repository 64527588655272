import React, { Component } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Row,
  Col,
} from "reactstrap";
import PropTypes, { number } from "prop-types";

import ComoLlego from "../ComoLlego";

import imgBeforeMap from "../../images/svg/elegirSucursar_imgMap.svg";
import infoMap from "../../images/svg/elegirSucursal_map.svg";
import infoCalendar from "../../images/svg/elegirSucursal__calendario.svg";
import sprite from "images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";

import { GoogleApiWrapper as MapaSucursales } from "../MapaSucursales";

import styles from "./SucursalesCercanas.module.css";

import ConfigAliados from "data/ConfigAliados.json";

const TXT_SELECT_CLINIC = "-";

export default class SucursalesCercanas extends Component {
  constructor(props) {
    super(props);
    const { state } = this.props;
    const { clinica, urlParams, diagnostico } = state;

    let clinicaSeleccionadaNombre =
      clinica.cambiarClinica !== false
        ? clinica.nombreClinica
        : TXT_SELECT_CLINIC;
    let clinicaSeleccionadaID =
      clinica.cambiarClinica !== false ? clinica.id : null;
    let clinicaSeleccionadaCoord =
      clinica.cambiarClinica !== false ? clinica.coordClinica : "Default";
    let { showMartin } = props;

    if (props.conocemosUbicacion === true) {
      clinicaSeleccionadaID = props.clinicKey;
      clinicaSeleccionadaNombre =
        props.clinicas[clinicaSeleccionadaID].ClinicName;
      clinicaSeleccionadaCoord =
        props.clinicas[clinicaSeleccionadaID].ClinicGeoLocation;
      showMartin = false;
    }

    this.state = {
      dropdownOpen: false,
      clinicsKeys: props.clinicsKeys,
      clinicaSeleccionadaNombre,
      clinicaSeleccionadaID,
      clinicaSeleccionadaCoord,
      showMartin,
      urlParams,
      diagnostico,
    };
  }

  componentDidMount() {
    const { state } = this.props;
    const { defaultSucursal, urlParams } = state;
    let clinicDefaultId;
    if (!!urlParams.sucursal) {
      const { clinicas, clinicsKeys } = this.props;
      console.log("clinics keys sucursales cercanas: ", clinicsKeys);
      Object.values(clinicas).map((clinica) => {
        if (clinica.ClinicName.includes(defaultSucursal)) {
          clinicDefaultId = clinica.ClinicID;
        }
      });
      this.cambiaClinica(clinicDefaultId);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.clinicsKeys !== state.clinicsKeys) {
      return {
        clinicsKeys: props.clinicsKeys,
        clinicas: props.clinicas,
      };
    }
    return null;
  }

  toggleDropdownClinicas = () => {
    const { dropdownOpen } = this.state;
    this.setState({
      dropdownOpen: !dropdownOpen,
    });
  };
  cambiaClinica = (clinicKey) => {
    const { onChangeSucursal, clinicas } = this.props;
    const { showMartin } = this.state;
    const { ClinicName, ClinicID, ClinicGeoLocation } = clinicas[clinicKey];
    this.setState({
      clinicaSeleccionadaNombre: ClinicName,
      clinicaSeleccionadaID: ClinicID,
      clinicaSeleccionadaCoord: ClinicGeoLocation,
    });
    onChangeSucursal(ClinicID, ClinicName, ClinicGeoLocation);

    if (showMartin) {
      this.setState({ showMartin: false });
    }
  };
  regresarATipoConsulta = () => {
    const { history, state } = this.props;
    const { location } = history;
    const { search } = location;
    let newHistory = {
      ...state,
      historialFlujo: {
        ...state.historialFlujo,
        flujo1: [],
        flujoStep: 0,
      },
    };
    if (search !== "") {
      history.push("/");
    } else {
      history.push("/cita/tipoConsulta", newHistory);
    }
  };
  irAsiguienteVista = () => {
    const { manejarClickContinuar } = this.props;
    manejarClickContinuar();
  };

  render() {
    const {
      dropdownOpen,
      clinicaSeleccionadaNombre,
      clinicaSeleccionadaID,
      clinicaSeleccionadaCoord,
      showMartin,
      urlParams,
      diagnostico,
    } = this.state;
    const { conocemosUbicacion, regresion, clinicas, history } = this.props;
    let {clinicsKeys} = this.state;

    
    // Se comenta el filtro para mostrar todas las clinicas
    
    // if (urlParams.medio === "Azucar" && diagnostico.descripcion === "Azucar Consulta Adulto") {
    //   if (clinicsKeys.length > 0) {
    //     console.log("Si incluye los valores específicos: ", diagnostico);
    //     // Lista de valores específicos que quieres verificar
    //     let specificValues = ['8'];

    //     // Filtrar clinicsKeys para que solo incluya los valores específicos si están presentes
    //     clinicsKeys = clinicsKeys.filter(key => specificValues.includes(key));

    //     console.log("clinics keys sucursales cercanas azucar: ", clinicsKeys);

    //   } else {
    //     console.log("No se encontraron los valores específicos en clinicsKeys.");
    //   }
    // }

    if (process.env.NODE_ENV !== "production") {
      console.log({
        name: "[SucursalesCercanas] Clínica",
        value: {
          clinica: clinicaSeleccionadaNombre,
          conocemosUbicacion,
          clinicsKeys,
          clinicas,
        },
        preview: `${clinicaSeleccionadaNombre}`,
      });
    }
    console.log("clinics keys: ", clinicsKeys);
    console.log('history.location.search:', history.location.search);

    return (
      <Container fluid className={`${styles.containerSucursales} `}>
        <Row className={`${styles.rowContainerTitleButtonDropdown}`}>
          <Col
            xs={12}
            className={`d-flex justify-content-center align-items-center`}
          >
            <p className={`${styles.colContainerTitle__title}`}>
              Selecciona la clínica que más te conviene:
            </p>
          </Col>
          <Col
            xs={12}
            className={`d-flex justify-content-center align-items-center`}
          >
            <ButtonDropdown
              isOpen={dropdownOpen}
              toggle={this.toggleDropdownClinicas}
              className={styles.DropdownSucursales}
            >
              <DropdownToggle
                caret
                color="white"
                className={styles.DropdownClinicas}
              >
                {clinicaSeleccionadaNombre}
              </DropdownToggle>
              <DropdownMenu>
                {clinicsKeys.map((clinicKey) => (
                  <DropdownItem
                    key={clinicKey}
                    onClick={() => this.cambiaClinica(clinicKey)}
                  >
                    {`${clinicas[clinicKey].ClinicName}`}
                    {conocemosUbicacion !== false && regresion !== true
                      ? ` - ${clinicas[clinicKey].distancia} km`
                      : null}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Row>
        <Row className={`${styles.rowContainerImgMapInfo}`}>
          {clinicaSeleccionadaID === null ? (
            <>
              <Col
                xs={12}
                className={`${styles.colContainerTitleBeforeMap} d-flex justify-content-center align-items-center`}
              >
                <p className={`${styles.colContainerTitleBeforeMap__title}`}>
                  En sala
                  <span>uno</span> contamos con más de 20 clínicas alrededor de
                  la Ciudad de México.
                </p>
              </Col>
              <Col
                xs={12}
                className={`${styles.colContainerImgBeforeMap} d-flex justify-content-center align-items-center`}
              >
                <img
                  src={imgBeforeMap}
                  className={`${styles.colContainerImgBeforeMap__img}`}
                  alt="img_default"
                />
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} md={6} className={`${styles.colContainerComoLlego}`}>
                <ComoLlego
                  clinicKey={clinicaSeleccionadaID}
                  nombreClinica={clinicaSeleccionadaNombre}
                />
              </Col>
              <Col xs={12} className={`${styles.colContainerComoLlegoTitle}`}>
                <p className={`${styles.colContainerComoLlegoTitle__title}`}>
                  {clinicaSeleccionadaNombre}
                </p>
                <div
                  className={`${styles.colContainerComoLlegoTitle__borde}`}
                />
              </Col>
              <Col xs={12} md={6} className={`${styles.colContainerMapInfo}`}>
                <Row className={`${styles.rowMapInfo}`}>
                  <Col xs={12} className={`${styles.colContainerMap}`}>
                    <MapaSucursales
                      coords={clinicaSeleccionadaCoord}
                      showMartin={showMartin}
                    />
                  </Col>
                  <Col xs={12} className={`${styles.colContainerInfoClinic}`}>
                    <Row>
                      <Col xs={6}>
                        <p></p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <p
                          className={`${styles.colContainerTitleClinica__title}`}
                        >
                          Información de la Clínica
                        </p>
                      </Col>
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={12} className={`d-flex`}>
                            <img src={infoMap} alt="icon_map" />
                            <p
                              className={`${styles.colContainerTitleDireccion__title}`}
                            >
                              Dirección
                            </p>
                          </Col>
                          <Col
                            xs={12}
                            className={`${styles.colContainerDireccion}`}
                          >
                            <p className={`${styles.colContainerDireccion__p}`}>
                              {clinicaSeleccionadaID !== null &&
                                clinicas[clinicaSeleccionadaID].ClinicAddress}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={12} className={`d-flex`}>
                            <img src={infoCalendar} alt="icon_Calendar" />
                            <p
                              className={`${styles.colContainerTitleHorarios}`}
                            >
                              Horarios
                            </p>
                          </Col>
                          <Col xs={12}>
                            <Row
                              className={`${styles.rowContainerHorariosClinicas}`}
                            >
                              <Col
                                xs={12}
                                className={`${styles.colContainerHorariosLunesAViernes}`}
                              >
                                {
                                (urlParams.medio === "Azucar" || (ConfigAliados[urlParams.medio] && ConfigAliados[urlParams.medio].nombreAgenda) === urlParams.medio)  &&
                                diagnostico.descripcion === "Problemas de retina" ? (
                                  <p
                                    className={`${styles.colContainerHorariosLunesAViernes__p}`}
                                  >{`${clinicas[clinicaSeleccionadaID].horarioRetina}`}</p>
                                ) : (
                                  <p
                                    className={`${styles.colContainerHorariosLunesAViernes__p}`}
                                  >{`Lunes a viernes de ${clinicas[clinicaSeleccionadaID].horarioLunesAViernes}`}</p>
                                )}
                              </Col>
                              <Col
                                xs={12}
                                className={`${styles.colContainerHorariosSabados}`}
                              >
                                <p
                                  className={`${styles.colContainerHorariosSabados__p}`}
                                >{`${clinicas[clinicaSeleccionadaID].horarioSabados}`}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className={`${styles.colContainerComoLlegoPantallasPequeñas}`}
                      >
                        <ComoLlego
                          clinicKey={clinicaSeleccionadaID}
                          nombreClinica={clinicaSeleccionadaNombre}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
        <Row className={`${styles.rowContainerButton}`}>
          <Col
            xs={12}
            className={`d-flex justify-content-center align-items-center`}
          >
            <Row
              className={`${styles.rowContainerButtonsRegresarContinuar} ${
                history.location.state.urlParams.medio !== "" &&
                styles.rowContainerButtonsRegresarContinuar__sinBoton
              }`}
            >
              {history.location.state.urlParams.medio === "" && (
                <Col
                  xs={12}
                  md={6}
                  className={`${styles.colContainerRegresar}`}
                >
                  <button
                    className={`${styles.colContainerButtons__regresar}`}
                    onClick={this.regresarATipoConsulta}
                  >
                    REGRESAR A OPCIONES DE CONSULTA
                  </button>
                </Col>
              )}
              <Col
                xs={12}
                md={6}
                className={`${styles.colContainerContinuar} ${
                  history.location.state.urlParams.medio !== "" &&
                  styles.colContainerContinuar__centrado
                }`}
              >
                <button
                  className={`${styles.colContainerButtons__continuar}`}
                  disabled={clinicaSeleccionadaID === null ? true : false}
                  onClick={this.irAsiguienteVista}
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        CONTINUAR
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span className={`${styles.colContainerButtons__span}`}>
                          <svg className={`${styles.colContainerButtons__svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

SucursalesCercanas.propTypes = {
  conocemosUbicacion: PropTypes.bool,
  clinicKey: PropTypes.any,
  clinicsKeys: PropTypes.arrayOf(number),
  onChangeSucursal: PropTypes.func,
  regresion: PropTypes.any,
  showMartin: PropTypes.bool,
  clinicas: PropTypes.object,
};

SucursalesCercanas.defaultProps = {
  conocemosUbicacion: false,
  clinicKey: null,
  clinicsKeys: [],
  onChangeSucursal: () => {},
  regresion: null,
  showMartin: true,
  clinicas: {},
};
