import React from "react";
import { Col, Row, Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import { PulseLoader } from "react-spinners";

import TarjetasDia from "./TarjetasDia";

import imgNoDisponiblePromo from "images/svg/NoDisponiblePromo.svg";
import imgNoDisponible from "images/svg/NoDisponible.svg";
import styles from "css/Fecha.module.css";
import stylesLentes from "css/SinLentes.module.css";
import stylesConfirmacion from "css/ConfirmacionStyles.module.css";
import stylesPulseLoader from "./HorariosPorServicioStyles.module.css";
import DropdownHorarios from "./DropdownHorarios";
import DropdownHoras from "./DropdownHoras";
import sprite from "images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";
import Admiracion from "images/svg/confirmacion__admiracion.svg";

const TXT_HEADER_FECHA = "Elijan la fecha para acudir a nuestra clínica:";

const MODAL_TEXTO_OCUPADA = (
  <div>
    <p>
      Lo sentimos, se ha ocupado el horario de la cita, por favor da click en
      aceptar y elige nuevos horarios.
    </p>
  </div>
);

const HorarioPorServicio = ({
  state,
  loadHours,
  setHours,
  regresar,
  continuar,
  selectHorario,
  handleModal,
  modalState,
  modalForm,
  handleModalForm,
  actualizando,
}) => {
  const { notAvailable, promocion, fechasValidas, loadingDay, horarioActivo } =
    state;
  const hasSlick = true;
  const renderNotAvailable = (promocion) => {
    if (promocion) {
      return (
        <>
          <Row className={styles.rowNotAvailable}>
            <img
              src={imgNoDisponiblePromo}
              alt=""
              className={styles.notAvailable}
            />
          </Row>
        </>
      );
    }
    return (
      <Row className={styles.rowNotAvailable}>
        <img src={imgNoDisponible} alt="" className={styles.notAvailable} />
      </Row>
    );
  };

  const renderHoras = (horarios, loadingHours) => {
    let bodyHoras = null;

    const horasData = {
      select: selectHorario,
      options: horarios,
    };

    if (loadingHours) {
      bodyHoras = (
        <div className={styles.sweetLoading}>
          <PulseLoader sizeUnit="px" size={15} margin="4px" color="#00748c" />
        </div>
      );
    } else {
      bodyHoras = <DropdownHoras data={horasData} />;
    }

    return (
      <Col
        lg={6}
        className={`py-lg-5 ${styles.fechaHeaderLeft} ${styles.alinearIzquierda}`}
      >
        {bodyHoras}
      </Col>
    );
  };

  const renderHorario = (horarioActivo, loadingDay) => {
    let bodyHorario = null;

    const horariosData = {
      select: setHours,
      options: horarioActivo,
    };

    if (loadingDay) {
      bodyHorario = (
        <div className={styles.sweetLoading}>
          <PulseLoader sizeUnit="px" size={15} margin="4px" color="#00748c" />
        </div>
      );
    } else {
      bodyHorario = <DropdownHorarios data={horariosData} />;
    }

    return (
      <Col
        lg={6}
        className={`py-lg-5 ${styles.fechaHeaderLeft} ${styles.alinearDerecha}`}
      >
        {bodyHorario}
      </Col>
    );
  };

  const renderFecha = () => {
    let bodyFecha = null;
    if (loadingDay || fechasValidas === "") {
      bodyFecha = (
        <div className={styles.sweetLoading}>
          <PulseLoader sizeUnit="px" size={15} margin="4px" color="#00748c" />
        </div>
      );
    } else {
      bodyFecha = (
        <TarjetasDia
          dias={fechasValidas}
          loadHours={loadHours}
          slick={hasSlick}
          state={state}
        />
      );
    }
    return (
      <Row className={`${styles.row_fecha}`}>
        <Col
          className={`${styles.fechaArea} ${styles.fechaElement}`}
          xl={{ size: 12 }}
        >
          <p className={`${styles.fechaArea__p}`}>{TXT_HEADER_FECHA}</p>
          {bodyFecha}
        </Col>
      </Row>
    );
  };
  const { horariosPM, horariosAM } = state;
  const horarios = horarioActivo === "PM" ? horariosPM : horariosAM;
  if (actualizando) {
    return (
      <Container fluid className={stylesPulseLoader.containerPulseLoader}>
        <Container className={stylesPulseLoader.pulseLoaderContainer}>
          <Row className={stylesPulseLoader.rowContainerPulseLoader}>
            <Col xs={12} className={stylesPulseLoader.colContainerPulseLoader}>
              <PulseLoader
                sizeUnit="px"
                size={15}
                margin="4px"
                color="#00748c"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className={stylesPulseLoader.coment}>
                Estamos actualizando su registro, espere un momento porfavor
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  } else {
    return (
      <Container fluid className={`${styles.containerFechaCitaFluid}`}>
        <Container className={`${styles.containerFechaCita}`}>
          <>
            {notAvailable ? renderNotAvailable(promocion) : null}
            {!notAvailable
              ? renderFecha(fechasValidas, loadingDay, hasSlick)
              : null}
            <Row className={`px-3 py-3 ${styles.rowContainer}`}>
              <Col
                lg={12}
                className={`${styles.colContainerTitleHorarios} d-flex justify-content-center`}
              >
                <p className={`${styles.colContainerTitleHorarios__title}`}>
                  Eligan el horario que mejor les conviene:
                </p>
              </Col>
              {!notAvailable ? renderHorario(horarioActivo, loadingDay) : null}
              {!notAvailable ? renderHoras(horarios, state.loadingHours) : null}
            </Row>
          </>
        </Container>
        <Row className={`${styles.rowContainerButtons}`}>
          <Col
            xs={12}
            className={`${styles.colContainerButtons} d-flex justify-content-center align-items-center`}
          >
            <Row className={`${styles.rowButtons}`}>
              <Col
                xs={12}
                md={6}
                className={`${styles.colContainerRegresar} d-flex justify-content-center`}
              >
                <button
                  className={`${styles.colContainerButtons__regresar}`}
                  onClick={regresar}
                  type="button"
                >
                  REGRESAR
                </button>
              </Col>

              <Col
                xs={12}
                md={6}
                className={`${styles.colContainerContinuar} d-flex justify-content-center`}
              >
                <button
                  className={`${styles.colContainerButtons__continuar}`}
                  disabled={state.selectHorario !== "" ? false : true}
                  onClick={continuar}
                  type="button"
                >
                  <Container fluid>
                    <Row>
                      <Col
                        xs={8}
                        className={`${styles.colContainerNombreBoton}`}
                      >
                        CONTINUAR
                      </Col>
                      <Col xs={4} className={`${styles.colContainerSpanBoton}`}>
                        <span className={`${styles.colContainerButtons__span}`}>
                          <svg className={`${styles.colContainerButtons__svg}`}>
                            <use xlinkHref={`${sprite}#icon-arrow-right`} />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={stylesLentes.rowNoLead}>
          <Col>
            <Modal
              isOpen={modalState}
              toggle={() => handleModal()}
              className={stylesConfirmacion.modalContainer}
              id="confirmaDatos"
              backdropClassName={`${stylesConfirmacion.backdropModal}`}
              modalClassName={`${stylesConfirmacion.modal}`}
              contentClassName={`${stylesConfirmacion.modalContanet}`}
            >
              <ModalHeader
                toggle={() => handleModal()}
                className={`${stylesConfirmacion.modalHeader}`}
                buttonClassClosed={`${stylesConfirmacion.modalButtonClosed}`}
              />
              <ModalBody className={`${stylesConfirmacion.modalBody}`}>
                <Container>
                  <Row>
                    <Col
                      xs={12}
                      className={`d-flex justify-content-center align-items-center`}
                    >
                      <img src={Admiracion} alt="modal_icon" />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      className={`${stylesConfirmacion.colContainerTextModalCancelar} `}
                    >
                      <Row>
                        <Col xs={12}>
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            Lo sentimos, este horario ya fue ocupado , de click
                            en aceptar y busque un horario mas
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className={`${stylesConfirmacion.col_cancelarSi} d-flex justify-content-center`}
                      xs={12}
                    >
                      <button
                        type="button"
                        onClick={() => handleModal()}
                        className={`${stylesConfirmacion.col_cancelarSiButton}`}
                      >
                        ACEPTAR
                      </button>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
        <Row className={stylesLentes.rowNoLead}>
          <Col>
            <Modal
              isOpen={modalForm}
              toggle={() => handleModalForm()}
              className={stylesConfirmacion.modalContainer}
              id="confirmaDatos"
              backdropClassName={`${stylesConfirmacion.backdropModal}`}
              modalClassName={`${stylesConfirmacion.modal}`}
              contentClassName={`${stylesConfirmacion.modalContanet}`}
            >
              <ModalHeader
                toggle={() => handleModalForm()}
                className={`${stylesConfirmacion.modalHeader}`}
                buttonClassClosed={`${stylesConfirmacion.modalButtonClosed}`}
              />
              <ModalBody className={`${stylesConfirmacion.modalBody}`}>
                <Container>
                  <Row>
                    <Col
                      xs={12}
                      className={`d-flex justify-content-center align-items-center`}
                    >
                      <img src={Admiracion} alt="modal_icon" />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      className={`${stylesConfirmacion.colContainerTextModalCancelar} `}
                    >
                      <Row>
                        <Col xs={12}>
                          <p
                            className={stylesConfirmacion.textModalNoDisponible}
                          >
                            Los datos del formulario no se registraron
                            correctamente, comuniquese con el administrador de
                            la agenda para corroborar su información
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className={`${stylesConfirmacion.col_cancelarSi} d-flex justify-content-center`}
                      xs={12}
                    >
                      <button
                        type="button"
                        onClick={() => handleModalForm()}
                        className={`${stylesConfirmacion.col_cancelarSiButton}`}
                      >
                        ACEPTAR
                      </button>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default HorarioPorServicio;
