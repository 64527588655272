/* eslint-disable no-restricted-globals */
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { Container } from "reactstrap";

import Cuestionario from "views/omo/Cuestionario";
import ElegirSucursal from "views/ElegirSucursal";
import ElegirFecha from "views/Fecha";
import DatosPaciente from "views/DatosPaciente";
import Confirmacion from "views/Confirmacion";
import ConfirmacionOMO from "views/omo/Confirmacion";
import ServicioNoDisponible from "views/ServicioNoDisponible";
import Diagnosticos from "views/Diagnosticos";

import Cancelada from "views/Cancelada";
import Promo2X1Container from "views/Promo2X1/Promo2X1Container";
import PagoDeCitaContainer from "views/PagoDeCita/PagoDeCitaContainer";
import LlamadaVideoLlamadaContainer from "components/LlamadaVideoLlamada/LlamadaVideoLlamadaContainer";
import TipoConsultaPresencialOmoContainer from "components/TipoConsultaPresencialOmo/TipoConsultaPresencialOmoContainer";
import HeaderContainer from "components/HeaderContainer/HeaderContainer";
import FooterContainer from "components/FooterContainer/FooterContainer";
import Trabajando from "views/Trabajando/Trabajando";
import Promociones from "views/Promociones";
import BienvenidoContainer from "views/Bienvenido/BienvenidoContainer";
import BienvenidoCIContainer from "views/AgendaCI/Bienvenido/BienvenidoCIContainer";
import CategoriasCIContainer from "views/AgendaCI/CategoriasCI/CategoriasCIContainer";
import FormularioCIContainer from "views/AgendaCI/FormularioCI/FormularioCIContainer";
import HorarioPorServicioContainer from "views/AgendaCI/HorarioPorServicio/HorarioPorServicioContainer";
import CreaLeadContainer from "views/CreaLead/CreaLeadContainer";

import CreaTamizajeContainer from "views/CreaPacienteTamizado/CreaPacienteTamizadoContainer";
import CreaTamizajeGralContainer from "views/CreaPacienteTamizado/TamizarPacienteAgendaGralContainer";

import ConfirmacionContainer from "views/AgendaCI/Confirmacion/ConfirmacionContainer";
import { getParamsString } from "functions/GetUrlParams";

import styles from "css/RoutesStyles.module.css";
import LoginContainer from "views/AgendaMetro/Login/LoginContainer";
import SingUpContainer from "views/AgendaMetro/SingUp/SingUpContainer";
import SignosVitalesContainer from "views/ExpedienteElectronico/SignosVitales/SignosVitalesContainer";
import { PDFRecuperacionContainer } from "views/ExpedienteElectronico/PDFRecuperacionContainer";
import MonitorContainer from "views/Monitor/MonitorContainer";
import BienvenidoAliadosContainer from "views/BienvenidoAliados/BienvenidoAliadosContainer";

// Parmámetros de la ruta
let redirectPath = "/cita/bienvenida";
redirectPath += getParamsString();

const Index = () => <Redirect to={redirectPath} />;

function Cita({ routes }) {
  return (
    <Route
      render={(props) => (
        <Container fluid className={`${styles.containerSalauno}`}>
          <HeaderContainer />
          <Switch location={props.location}>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
          <FooterContainer />
        </Container>
      )}
    />
  );
}

const routes = [
  {
    path: "/cita",
    component: Cita,
    routes: [
      {
        path: "/cita/bienvenida",
        component: BienvenidoContainer,
      },
      {
        path: "/cita/diagnosticos",
        component: Diagnosticos,
      },
      {
        path: "/cita/orientacion",
        component: Cuestionario,
      },
      {
        path: "/cita/clinica",
        component: ElegirSucursal,
      },
      {
        path: "/cita/horario",
        component: ElegirFecha,
      },
      {
        path: "/cita/datosPaciente",
        component: DatosPaciente,
      },
      {
        path: "/cita/PagoDeCita",
        component: PagoDeCitaContainer,
      },
      {
        path: "/cita/confirmacion",
        component: Confirmacion,
      },
      {
        path: "/cita/confirmacionOMO",
        component: ConfirmacionOMO,
      },
      {
        path: "/cita/tipoLlamada",
        component: LlamadaVideoLlamadaContainer,
      },
      {
        path: "/cita/tipoConsulta",
        component: TipoConsultaPresencialOmoContainer,
      },
      {
        path: "/cita/cancelada",
        component: Cancelada,
      },
      {
        path: "/cita/servicioNoDisponible",
        component: ServicioNoDisponible,
      },
      {
        path: "/cita/promocion",
        component: Promociones,
      },
      {
        path: "/cita/candidato",
        component: CreaLeadContainer,
      },
      {
        path: "/cita/tamizaje",
        component: CreaTamizajeContainer,
      },
      {
        path: "/cita/tamizajeGeneral",
        component: CreaTamizajeGralContainer,
      },
      {
        path: "/cita/bienvenidaCI",
        component: BienvenidoCIContainer,
      },
      {
        path: "/cita/categoriasCI",
        component: CategoriasCIContainer,
      },
      {
        path: "/cita/datosCI",
        component: FormularioCIContainer,
      },
      {
        path: "/cita/horarioCI",
        component: HorarioPorServicioContainer,
      },
      {
        path: "/cita/confirmacionCI",
        component: ConfirmacionContainer,
      },
      {
        path: "/cita/LoginMetro",
        component: LoginContainer,
      },
      {
        path: "/cita/SingUpMetro",
        component: SingUpContainer,
      },
      {
        component: Index,
      },
    ],
  },
  {
    path: "/reagendar",
    component: ElegirFecha,
  },
  {
    path: "/cita2X1",
    component: Promo2X1Container,
  },
  {
    path: "/trabajando",
    component: Trabajando,
  },
  {
    path: "/graph",
    component: SignosVitalesContainer,
  },
  {
    path: "/PDFRecuperacion",
    component: PDFRecuperacionContainer,
  },
  {
    path: "/monitorDeTurnos",
    component: MonitorContainer,
  },
  {
    path: "/bienvenidaAliados",
    component: BienvenidoAliadosContainer,
  },
];

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Index} />
      {routes.map((route, index) => (
        <RouteWithSubRoutes key={`subroute${index}`} {...route} />
      ))}
      <Route component={Index} />
    </Switch>
  </Router>
);

export default Routes;
