/* eslint-disable import/no-duplicates */
/* eslint-disable operator-linebreak */
/* eslint-disable no-lonely-if */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React from "react";
import { useState } from "react";
import FormularioCI from "./FormularioCI";

const FormularioCIContainer = ({ history: { location }, history }) => {
  const { state, search } = location;
  const newHistory = {
    ...state,
  };

  console.log("state:", state);

  const initialState = {
    ojoOperar: "OI",
    tipoAnestecia: "Tópica",
    tipoTecnica:
      state.ServicioCI.nombreDisplay === "CROSSLINKING" ? "EPI ON" : "LASIK",
    equipo: "Centurion Silver",
    insumos: "",
    lugarProcedimiento: "Quirofano",
    nombrePaciente: "",
    requiereCalLio: "SI",
    modeloLio: "AT LISA tri 839MP",
    poderLente: 0,
    comentarios: "",
  };

  const [formState, setFormState] = useState(initialState);
  const [modalState, setModalState] = useState(false);

  const handleChange = (e) => {
    if (e.target.name !== "requiereCalLio") {
      if (formState.requiereCalLio === "NO") {
        setFormState({
          ...formState,
          [e.target.name]: e.target.value,
          modeloLio: "",
        });
        // setFormState({ ...formState, modeloLio: "" });
      } else {
        if (e.target.name == "poderLente") {
          setFormState({
            ...formState,
            // requiereCalLio: "SI",
            [e.target.name]: parseFloat(e.target.value),
          });
        } else {
          setFormState({
            ...formState,
            // requiereCalLio: "SI",
            [e.target.name]: e.target.value,
          });
        }
        // setFormState({ ...formState, [e.target.name]: e.target.value });
      }
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }
    console.log("formState:", formState);
  };

  const comprobarDatos = () => {
    if (state.CategoriaCI === "Cirugía") {
      if (state.ServicioCI.nombreServicio === "CI - CROSSLINKING") {
        if (
          formState.ojoOperar === "" ||
          formState.tipoTecnica === "" ||
          formState.insumos === "" ||
          formState.nombrePaciente === "" ||
          formState.comentarios === ""
        ) {
          return false;
        }
      } else if (state.ServicioCI.nombreServicio === "CI - LASIK") {
        if (
          formState.ojoOperar === "" ||
          formState.tipoTecnica === "" ||
          formState.insumos === "" ||
          formState.nombrePaciente === "" ||
          formState.comentarios === ""
        ) {
          return false;
        }
      } else if (state.ServicioCI.nombreServicio === "CI - CHALAZION") {
        if (
          formState.ojoOperar === "" ||
          formState.lugarProcedimiento === "" ||
          formState.insumos === "" ||
          formState.nombrePaciente === "" ||
          formState.comentarios === ""
        ) {
          return false;
        }
      } else {
        if (formState.requiereCalLio === "SI") {
          if (
            formState.ojoOperar === "" ||
            formState.tipoAnestecia === "" ||
            formState.equipo === "" ||
            formState.insumos === "" ||
            formState.nombrePaciente === "" ||
            formState.requiereCalLio === "" ||
            formState.modeloLio === "" ||
            formState.poderLente === 0 ||
            formState.comentarios === ""
          ) {
            return false;
          }
        } else {
          if (
            formState.ojoOperar === "" ||
            formState.tipoAnestecia === "" ||
            formState.equipo === "" ||
            formState.insumos === "" ||
            formState.nombrePaciente === "" ||
            formState.poderLente === 0 ||
            formState.comentarios === ""
          ) {
            return false;
          }
        }
      }
    } else if (state.CategoriaCI === "Inyecciones") {
      if (
        formState.ojoOperar === "" ||
        formState.insumos === "" ||
        formState.comentarios === "" ||
        formState.nombrePaciente === ""
      ) {
        return false;
      }
    } else {
      if (formState.comentarios === "" || formState.nombrePaciente === "") {
        return false;
      }
    }
    return true;
  };

  const confirmarDatos = () => {
    if (state.CategoriaCI === "Cirugía") {
      if (state.ServicioCI.nombreServicio === "CI - CROSSLINKING") {
        setFormState({
          ...formState,
          tipoAnestecia: "",
          equipo: "",
          lugarProcedimiento: "",
          requiereCalLio: "",
          modeloLio: "",
          poderLente: 0,
        });
      } else if (state.ServicioCI.nombreServicio === "CI - LASIK") {
        setFormState({
          ...formState,
          tipoAnestecia: "",
          equipo: "",
          lugarProcedimiento: "",
          requiereCalLio: "",
          modeloLio: "",
          poderLente: 0,
        });
      } else if (state.ServicioCI.nombreServicio === "CI - CHALAZION") {
        setFormState({
          ...formState,
          tipoAnestecia: "",
          equipo: "",
          tipoTecnica: "",
          requiereCalLio: "",
          modeloLio: "",
          poderLente: 0,
        });
      } else {
        if (formState.requiereCalLio !== "No") {
          setFormState({
            ...formState,
            tipoTecnica: "",
            lugarProcedimiento: "",
          });
        } else {
          setFormState({
            ...formState,
            tipoTecnica: "",
            lugarProcedimiento: "",
            modeloLio: "",
          });
        }
      }
    } else if (state.CategoriaCI === "Inyecciones") {
      setFormState({
        ...formState,
        tipoTecnica: "",
        tipoAnestecia: "",
        equipo: "",
        requiereCalLio: "",
        modeloLio: "",
        poderLente: 0,
      });
    } else {
      setFormState({
        ...formState,
        equipo: "",
        insumos: "",
        lugarProcedimiento: "",
        modeloLio: "",
        ojoOperar: "",
        poderLente: 0,
        requiereCalLio: "",
        tipoAnestecia: "",
        tipoTecnica: "",
      });
    }
    setModalState(true);
  };

  const handleConfirmar = () => {
    setModalState(!modalState);
  };

  const continuar = () => {
    newHistory.formState = { ...formState };
    history.push(`/cita/horarioCI${search}`, newHistory);
  };

  return (
    <FormularioCI
      state={state}
      formState={formState}
      handleChange={handleChange}
      comprobarDatos={comprobarDatos}
      confirmarDatos={confirmarDatos}
      modalState={modalState}
      handleConfirmar={handleConfirmar}
      continuar={continuar}
    />
  );
};

export default FormularioCIContainer;
