import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import styles from "../css/ServicioNoDisponible.module.css";

import imagen_loSiento from "../images/svg/MartinTriste.svg";

const texto_encabezado = "¡Lo siento!";
const texto_mensajeDefault =
  "Por el momento no contamos con este servicio, seguimos trabajando para darte la mejor experiencia.";
const texto_mensajeOftalmopediatria =
  "¡Lo sentimos! Por el momento no contamos con el servicio de oftalmopediatría, seguimos trabajando para darte la mejor experiencia.";
const texto_mensajeEstrabismo =
  "¡Lo sentimos! Por el momento no podemos atender la patología de estrabismo, seguimos trabajando para darte la mejor experiencia.";
const texto_mensajeOculoplastia =
  "¡Lo sentimos! Por el momento no contamos con el servicio de oftlamooculoplastía, seguimos trabajando para darte la mejor experiencia.";
export default class ServicioNoDisponible extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diagnostico:
        props.location.state !== undefined
          ? props.location.state.diagnostico
          : { id: "" },
    };
    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[ServicioNoDisponible] History`,
        value: props.location.state,
        important: true,
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  manejarClick = () => {};

  render() {
    return (
      <Container fluid className={`${styles.containerNoDisponible}`}>
        <Container>
          <Row >
            <Col md={12}>
              <Row>
                <Col>
                  <h2 className={styles.titulo}>{texto_encabezado}</h2>
                  <h3 className={styles.subtitulo}>
                    {this.state.diagnostico.id === "" && texto_mensajeDefault}
                    {this.state.diagnostico.id === 4 && texto_mensajeEstrabismo}
                    {this.state.diagnostico.id === 7 &&
                      texto_mensajeOculoplastia}
                    {this.state.diagnostico.id === 13 &&
                      texto_mensajeOftalmopediatria}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  className={`d-flex justify-content-center`}
                >
                  <img
                    src={imagen_loSiento}
                    className={styles.infoImagen}
                    alt=""
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
