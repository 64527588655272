/* eslint-disable quotes */
import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import styles from "./SingUpStyles.module.css";

const SingUp = ({ formValues, handleInputChange, handleSingUp }) => {
  const { nombre, email, password, password2 } = formValues;
  return (
    <Container fluid className={styles.singUpContainer}>
      <Row className={styles.rowBodyContainer}>
        <Col xs={12} className={styles.colBodyContainer_form}>
          <Row className={styles.rowFormSingUp}>
            <form className={styles.form} onSubmit={handleSingUp}>
              <div className={styles.error}>Hola Mundo</div>
              <Container className={styles.formBody}>
                <Row className={styles.formRowItem}>
                  <Col className={styles.formColItem} xs={12}>
                    <label htmlFor="nombre">Nombre</label>
                  </Col>
                  <Col className={styles.formColItemInput} xs={12}>
                    <input
                      type="text"
                      id="nombre"
                      name="nombre"
                      requeried
                      value={nombre}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row className={styles.formRowItem}>
                  <Col className={styles.formColItem} xs={12}>
                    <label htmlFor="correo">Correo electronico</label>
                  </Col>
                  <Col className={styles.formColItemInput} xs={12}>
                    <input
                      type="email"
                      id="correo"
                      name="email"
                      requeried
                      placeholder="usuario@dominio.com"
                      value={email}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row className={styles.formRowItem}>
                  <Col className={styles.formColItem} xs={12}>
                    <label htmlFor="contraseña">Contraseña </label>
                  </Col>
                  <Col className={styles.formColItemInput} xs={12}>
                    <input
                      type="password"
                      id="contraseña"
                      name="password"
                      value={password}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row className={styles.formRowItem}>
                  <Col className={styles.formColItem} xs={12}>
                    <label htmlFor="contraseña2">Confirma tu contraseña </label>
                  </Col>
                  <Col className={styles.formColItemInput} xs={12}>
                    <input
                      type="password"
                      id="contraseña2"
                      name="password2"
                      value={password2}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row className={styles.formRowItem}>
                  <Col className={styles.formColItemButton} xs={12}>
                    <button type="submit">Crear cuenta</button>
                  </Col>
                </Row>
              </Container>
            </form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SingUp;
