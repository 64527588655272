import React, { useState } from "react";
import GeneralToast from "./GeneralToast";

export default ({ mensajeError, backgroundHeader, typeStyles }) => {
  const [show, setShow] = useState(true);
  const toggle = () => setShow(!show);
  return (
    <GeneralToast
      toggle={toggle}
      show={show}
      mensajeError={mensajeError}
      backgroundHeader={backgroundHeader}
      typeStyles={typeStyles}
    />
  );
};
