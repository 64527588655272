import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Botones.module.css";

export default class BotonHorario extends Component {
  constructor(props) {
    super(props);

    let width = 117.5;
    let height = 110;

    switch (props.type) {
      case "Contacto":
        width = "90%";
        height = 80;
        break;
      default:
        width = 117.5;
        height = 110;
    }

    this.state = {
      respuesta: "",
      prevPropsSeleccionada: props.seleccionada,
      width: width,
      height: height,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.seleccionada !== state.prevPropsSeleccionada) {
      return {
        prevPropsSeleccionada: props.seleccionada,
      };
    }
    return null;
  }

  getClassNames1 = () => {
    const { seleccionada } = this.props;

    return classNames({
      [styles.horarioBtnElementActivo]: seleccionada === true,
      [styles.horarioBtnElementInactivo]: seleccionada === false,
    });
  };

  render() {
    const { imagen, manejarClick, izq, der } = this.props;
    const { respuesta, width, height } = this.state;

    return (
      <div
        className={
          izq === true
            ? styles.horarioBtnIzq
            : der === true
            ? styles.horarioBtnDer
            : styles.horarioBtn
        }
      >
        <button
          className={this.getClassNames1()}
          onClick={manejarClick}
          type="button"
        >
          <img
            src={imagen}
            alt=""
            width={width}
            height={height}
            className={`${styles.img_boton_horario}`}
          />
        </button>
        <p>{respuesta}</p>
      </div>
    );
  }
}

BotonHorario.propTypes = {
  seleccionada: PropTypes.bool,
  imagen: PropTypes.any,
  manejarClick: PropTypes.func,
  type: PropTypes.string,
};

BotonHorario.defaultProps = {
  seleccionada: false,
  imagen: null,
  manejarClick: () => {},
  type: "horario",
};
