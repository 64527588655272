/* eslint-disable quotes */
/* eslint-disable no-else-return */
import React from "react";
import validator from "validator";
import { useForm } from "Hooks/useForm";
import SingUp from "./SingUp";
import { useDispatch } from "react-redux";
import { removeError, setError } from "Actions/Ui";

const SingUpContainer = () => {
  const dispatch = useDispatch();
  const [formValues, handleInputChange] = useForm({
    nombre: "José Eduardo Malfavón Talavera",
    email: "malfavontalaveraj@gmail.com",
    password: "prueba",
    password2: "prueba",
  });
  const { nombre, email, password, password2 } = formValues;

  const handleSingUp = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      console.log("Formulario correcto");
    }
  };

  const isFormValid = () => {
    if (nombre.trim().length === 0) {
      dispatch(setError("El nombre es requerido"));
      return false;
    } else if (!validator.isEmail(email)) {
      dispatch(setError("El correo no es valido"));
      return false;
    } else if (
      password !== password2 ||
      password.length < 5 ||
      password2.length < 5
    ) {
      dispatch(
        setError("La contraseña debe de tener por lo menos 6 caracteres")
      );
    }
    dispatch(removeError());
    return true;
  };

  return (
    <SingUp
      formValues={formValues}
      handleInputChange={handleInputChange}
      handleSingUp={handleSingUp}
      isFormValid={isFormValid}
    />
  );
};
export default SingUpContainer;
