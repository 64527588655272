/* eslint-disable no-restricted-globals */
import React from "react";
import { Row, Col } from "reactstrap";
// import styles from "../../css/ConfirmacionStyles.module.css";
import styles from "./AvisoPago.module.css";

export default (props) => {
  const { img, children } = props;
  return (
    <Row className={styles.avisoContainer}>
      {/* //TODO aqui estuvo la primera imagen */}
      <Col xs="12" lg="3" className={styles.avisoContainer__mascot}>
        <img
          className={styles.avisoContainer__svg}
          src={img}
          alt="imagen_mascota"
        />
      </Col>
      <Col xs="12" lg="9" className={styles.avisoContainer__aviso}>
        <p className={styles.avisoContainer__paragraph}>{children}</p>
      </Col>
    </Row>
  );
};
