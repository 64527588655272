import React from "react";
import { Container, Row, Col } from "reactstrap";
import { getParams } from "functions/GetUrlParams";
import promociones from "data/promociones.json";
import styles from "./GeneralHeaderStyles.module.css";

import HeaderLogo from "../../../images/svg/header__Logo.svg";
import logiCI from "../../../images/png/LogoCI.png";

import ConfigAliados from "data/ConfigAliados.json";

const urlParams = getParams();

export default ({ location, regresarAlInicio }) => {
  const { pathname } = location;
  
  const renderTelefonoCallCenter = () => {
    //obtiene el medio de la url
  let parametros = new URLSearchParams(window.location.search);
  let medioparams = parametros.get('medio');
  console.log("Medioparams",medioparams);
    //validar que ConfigAliados[urlParams.medio] existe
    let ConfigAliadosParams = ConfigAliados[medioparams];
    console.log("ConfigAliadosParams:", ConfigAliadosParams);
    if (ConfigAliadosParams !== undefined) {
      if (urlParams.medio === ConfigAliados[medioparams].nombreAgenda) {
        return "5544459711";
      }
     console.log("entra a aqui")
    }
    else{
      if (urlParams.medio === "GDLV" || urlParams.medio === "GDLV-P") {
        return "5547420260";
      }
      if (urlParams.medio === "GDLV-Optometria") {
        return "5547420260";
      }
      if (urlParams.medio === "GeneralCampanas") {
        return "5547420260";
      }
      if (urlParams.medio === "SHCP") {
        return "55 8880 4021";
      }
      if (urlParams.medio === "Azucar") {
        return "5544459711";
      }
      if (urlParams.promo !== null) {
        if (promociones[urlParams.promo].promoPara === "Marketing") {
          return "55 8880 4021";
        } else {
          return "55 8930 7758";
        }
      } else {
        return "55 8880 4021";
      }
    }
    
  };
  const despliegaConBotonDeInicio = () => {
    return (
      <Col className={`${styles.colContainerButtons}`}>
        <Row className={`${styles.rowContainerButtons}`}>
          <Col
            className={`${styles.colContainerButtonInicio} d-flex align-items-center`}
          >
            <button
              type="button"
              onClick={() => regresarAlInicio()}
              className={`${styles.colContainerButtonInicio__button}`}
            >
              Inicio
            </button>
          </Col>
          <Col
            className={`${styles.colContainerButtonCC} d-flex align-items-center`}
          >
            <a
              className={`${styles.colContainerButtonCC__button}`}
              href={"tel:+" + renderTelefonoCallCenter()}
            >
              Call Center {renderTelefonoCallCenter()}
            </a>
          </Col>
        </Row>
      </Col>
    );
  };

  const despliegaSinBotonDeInicio = (urlParams) => {
    return (
      <Col className={`${styles.colContainerButton} d-flex align-items-center`}>
        <a
          className={`${styles.colContainerButton__button}`}
          href={"tel:+" + renderTelefonoCallCenter()}
        >
          Call Center {renderTelefonoCallCenter()}
        </a>
      </Col>
    );
  };
  return (
    <Container
      fluid
      className={`
          ${styles.GeneralHeaderContainer} 
            d-flex align-items-center
          ${pathname === "/cita/bienvenida" && styles.shadow}
          ${urlParams.medio === "CI" && styles.backgroundCI}
        `}
    >
      <Row className={`${styles.rowGeneralHeader}`}>
        <Col
          className={`${styles.colGeneralHeaderLogo}`}
          xs={{ size: 4 }}
          sm={{ size: 3 }}
          md={{ size: 3 }}
        >
          <a href="https://www.salauno.com.mx/">
            {urlParams.medio !== "CI" ? (
              <img
                className={`${styles.colGeneralHeaderLogo__img}`}
                src={HeaderLogo}
                alt=""
              />
            ) : (
              <img
                className={`${styles.colGeneralHeaderLogo__img} ${styles.colGeneralHeaderLogo__imgCI}`}
                src={logiCI}
                alt=""
              />
            )}
          </a>
        </Col>
        {urlParams.medio !== "GDLV-P" ||
          urlParams.medio !== "SHCP" ||
          (urlParams.medio !== "GDLV-Optometria") !== "GeneralCampanas"}
        {pathname === "/cita/bienvenida" &&
          urlParams.medio === "GDLV-P" &&
          despliegaSinBotonDeInicio()}
        {pathname !== "/cita/bienvenida" &&
          urlParams.medio === "GDLV-P" &&
          despliegaConBotonDeInicio()}
        {pathname === "/cita/bienvenida" &&
          urlParams.medio === "SHCP" &&
          despliegaSinBotonDeInicio()}
        {pathname !== "/cita/bienvenida" &&
          urlParams.medio === "SHCP" &&
          despliegaConBotonDeInicio()}
        {pathname === "/cita/bienvenida" &&
          urlParams.medio === "GDLV-Optometria" &&
          despliegaSinBotonDeInicio()}
        {pathname === "/cita/bienvenida" &&
          urlParams.medio === "GeneralCampanas" &&
          despliegaSinBotonDeInicio()}
        {pathname !== "/cita/bienvenida" &&
          urlParams.medio === "GDLV-Optometria" &&
          despliegaConBotonDeInicio()}
        {pathname !== "/cita/bienvenida" &&
          urlParams.medio === "GeneralCampanas" &&
          despliegaConBotonDeInicio()}
      </Row>
    </Container>
  );
};
