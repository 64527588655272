import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './Botones.module.css'

export default class BotonHoraPromo extends Component {
  handleClick = () => {
    const {
      promoSelected,
      promoDateTime1,
      promoDateTime2,
      promoKey,
    } = this.props
    promoSelected(promoDateTime1, promoDateTime2, promoKey)
  }

  render() {
    const {
      promoKey,
      estiloClassname,
      promo1,
      promo2,
    } = this.props
    return (
      <div className={styles.promoButtons} key={promoKey}>
        <div
          className={styles.horaBtn}
          onClick={this.handleClick}
        >
          <button
            className={(estiloClassname === 'estiloHoraBtnElement') ? styles.horaBtnElement : styles.horaBtnElementActivo}
            type="button"
          >
            <p>
              {promo1}
            </p>
          </button>
        </div>
        <div
          className={styles.horaBtn}
          onClick={this.handleClick}
        >
          <button
            className={(estiloClassname === 'estiloHoraBtnElement') ? styles.horaBtnElement : styles.horaBtnElementActivo}
            type="button"
          >
            <p>
              {promo2}
            </p>
          </button>
        </div>
      </div>
    )
  }
}

BotonHoraPromo.propTypes = {
  promoSelected: PropTypes.any,
  promoDateTime1: PropTypes.any,
  promoDateTime2: PropTypes.any,
  promoKey: PropTypes.any,
  estiloClassname: PropTypes.string,
  promo1: PropTypes.string,
  promo2: PropTypes.string,
}

BotonHoraPromo.defaultProps = {
  promoSelected: null,
  promoDateTime1: null,
  promoDateTime2: null,
  promoKey: null,
  estiloClassname: '',
  promo1: '',
  promo2: '',
}
