import React, { Component } from "react";
import PropTypes from "prop-types";

import { BotonHorario } from "../Botones";

import styles from "./Tarjetas.module.css";

import Llamada from "../../images/svg/llamadaVideoLlamada__Llamada.svg";
import VideoLlamada from "../../images/svg/llamadaVideoLlamada_VideoLlamada.svg";

import vectorAmActivo from "../../images/svg/AMActivo.svg";
import vectorAmInactivo from "../../images/svg/AMInactivo.svg";
import vectorPmActivo from "../../images/svg/PMActivo.svg";
import vectorPmInactivo from "../../images/svg/PMInactivo.svg";

import vectorllamadaActivo from "../../images/svg/llamadaActivo.svg";
import vectorllamadaInactivo from "../../images/svg/llamadaInactivo.svg";
import vectorVideollamadaActivo from "../../images/svg/videollamadaActivo.svg";
import vectorVideollamadaInactivo from "../../images/svg/videollamadaInactivo.svg";

export default class TarjetasHorario extends Component {
  constructor(props) {
    super(props);

    let opcUnoActiva = vectorAmActivo;
    let opcDosActiva = vectorPmActivo;
    let opcUnoInactiva = vectorAmInactivo;
    let opcDosInactiva = vectorPmInactivo;

    switch (props.type) {
      case "Contacto":
        opcUnoActiva = vectorllamadaActivo;
        opcDosActiva = vectorVideollamadaActivo;
        opcUnoInactiva = vectorllamadaInactivo;
        opcDosInactiva = vectorVideollamadaInactivo;
        break;
      default:
        opcUnoActiva = vectorAmActivo;
        opcDosActiva = vectorPmActivo;
        opcUnoInactiva = vectorAmInactivo;
        opcDosInactiva = vectorPmInactivo;
    }

    this.state = {
      selectedOpcUno: true,
      type: props.type,
      imgOpcUno: opcUnoActiva,
      imgOpcDos: opcDosInactiva,
      opcUnoActiva,
      opcDosActiva,
      opcUnoInactiva,
      opcDosInactiva,
    };
  }

  componentDidUpdate() {
    const { horario, opcUno } = this.props;
    const { selectedOpcUno } = this.state;
    if (process.env.NODE_ENV !== "production") {
      console.tron.log(`[TarjetasHorario] ${horario} && ${selectedOpcUno}`);
    }

    if (horario && (horario === opcUno) !== selectedOpcUno) {
      this.switchHorarios(horario);
    }
  }

  switchHorarios = (opcionSeleccionada) => {
    const { cargar, opcUno } = this.props;
    const {
      opcUnoActiva,
      opcUnoInactiva,
      opcDosInactiva,
      opcDosActiva,
    } = this.state;

    this.setState({
      selectedOpcUno: opcionSeleccionada === opcUno,
      imgOpcUno: opcionSeleccionada === opcUno ? opcUnoActiva : opcUnoInactiva,
      imgOpcDos: opcionSeleccionada === opcUno ? opcDosInactiva : opcDosActiva,
    });
    cargar(opcionSeleccionada);
  };

  render() {
    const { selectedOpcUno } = this.state;

    const { opcUno, opcDos, type } = this.props;

    return (
      <div className={styles.horarioContent}>
        <BotonHorario
          manejarClick={() => this.switchHorarios(opcUno)}
          seleccionada={selectedOpcUno}
          imagen={Llamada}
          type={type}
          izq={true}
        />
        <BotonHorario
          manejarClick={() => this.switchHorarios(opcDos)}
          seleccionada={!selectedOpcUno}
          imagen={VideoLlamada}
          type={type}
          der={true}
        />
      </div>
    );
  }
}

TarjetasHorario.propTypes = {
  horario: PropTypes.string,
  cargar: PropTypes.any,
  type: PropTypes.string,
  opcUno: PropTypes.string,
  opcDos: PropTypes.string,
};

TarjetasHorario.defaultProps = {
  horario: "AM",
  cargar: null,
  type: "horario",
  opcUno: "AM",
  opcDos: "PM",
};
