import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from '../../components/BotonGeneral/BotonGeneral.module.css'
import '../../css/Fonts.css'


export default class BotonCuestionario extends Component {
    constructor(props) {
        super(props)
        this.state = { prevPropsActivo: props.activo }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.activo !== state.prevPropsActivo) {
            return {
                prevPropsActivo: props.activo,
            }
        }
        return null
    }

    getClassNames = () => {
        const {
            activo
        } = this.props

        return classNames({
            [styles.BotonGeneralActivo]: activo === true,
            [styles.BotonGeneralInactivo]: activo === false,
        })
    }

    render() {
        const {
            manejarClick,
            nombreBoton,
            id,
        } = this.props
        // const { textoBotones } = this.props
        return (
    
                <button 
                    onClick={manejarClick}
                    className={this.getClassNames() + ' btn-block'}
                    type="button"
                    id={id}>
                    {nombreBoton}
                </button>
            
        );
    }

}
    BotonCuestionario.propTypes = {
        // color: PropTypes.string, // default: 'secondary'
        idSintoma: PropTypes.string,
        existe: PropTypes.bool,
        manejarClick: PropTypes.func,
        nombreBoton: PropTypes.string,
        activo: PropTypes.bool,
        id: PropTypes.string,
    }

    BotonCuestionario.defaultProps = {
        existe: false,
        manejarClick: () => {},
        nombreBoton: '',
        activo: false,
        id: '',
        idSintoma: null,
    }

