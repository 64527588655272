/* eslint-disable radix */
/* eslint-disable comma-dangle */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import axios from "axios";
import HorarioPorServicio from "./HorarioPorServicio";

const baseUrl =
  window.location.hostname === "citas.salauno.com.mx"
    ? "https://salauno-nodeserver.azurewebsites.net"
    : "https://salauno-nodeserver-testing.azurewebsites.net";

// const baseUrlSalesforce = "https://salauno.secure.force.com/services/apexrest";
const url = "https://salauno.secure.force.com/services/apexrest/EndpointsCI";

const HorarioPorServicioContainer = ({ history: { location }, history }) => {
  const { state, search } = location;
  console.log("state:", state);
  const [fechaActiva, setFechaActiva] = useState("");

  let initialState = {
    activo: false,
    duration: null,
    existeBtn: true,
    fechasValidas: [],
    horarioActivo: "AM",
    horariosAM: [],
    horariosPM: [],
    loadingContinuar: false,
    loadingDay: true,
    loadingHours: true,
    modalOcupada: false,
    notAvailable: false,
    promocion: false,
    width: window.innerWidth,
    baseUrl,
    selectHorario: "",
  };

  const [stateHorarioPorServicio, setStateHorarioPorServicio] =
    useState(initialState);

  const [modalState, setModalState] = useState(false);
  const [modalForm, setModalForm] = useState(false);
  const [actualizando, setActualizando] = useState(false);

  const setHours = (horarioActivo) => {
    setStateHorarioPorServicio({ ...stateHorarioPorServicio, horarioActivo });
  };

  const getTimes = (date) => {
    console.log("getTimes date:", date);
    setFechaActiva(date);
    const { horarios, loadingHours } = stateHorarioPorServicio;
    console.log("getTimes fechaActiva:", fechaActiva);
    let horariosFiltrados = [];
    if (loadingHours === false) {
      horarios.fechasValidas.map((horario) => {
        if (horario.dia.startsWith(date)) {
          horariosFiltrados.push(horario);
        }
      });
    }

    let { horariosAM, horariosPM, horarioActivo } = stateHorarioPorServicio;

    horariosAM = [];
    horariosPM = [];
    horariosFiltrados.forEach((hora) => {
      //   console.log("hora:", parseInt(hora.dia.substring(11, 13)));
      if (parseInt(hora.dia.substring(11, 13)) < 12) {
        horariosAM.push(hora);
      } else {
        horariosPM.push(hora);
      }
    });

    console.log("horariosAM:", horariosAM);
    console.log("horariosPM:", horariosPM);

    if (horariosAM.length > 0) {
      if (horarioActivo !== "AM") {
        horarioActivo = "AM";
      }
    } else {
      horarioActivo = "PM";
    }

    setStateHorarioPorServicio({
      ...stateHorarioPorServicio,
      horariosAM,
      horariosPM,
      horarioActivo,
      loadingHours: false,
    });
  };

  const loadHours = (date) => {
    getTimes(date);
  };

  const getResponseFecha = (response) => {
    const fechasValidas = response.data;
    return {
      loadingDay: false,
      fechasValidas,
    };
  };

  const getDates = async () => {
    const f = new Date();
    let year = f.getFullYear();
    const month = `${year}-${f.getMonth() + 1}`;
    let nextMonth = f.getMonth() + 2;

    if (nextMonth > 12) {
      nextMonth = 1;
      year += 1;
    }

    const nextMonthYear = `${year}-${nextMonth}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    const monthPost = {
      departamento: "CI",
      servicio: state.ServicioCI.nombreServicio,
      mes: month,
      mesSiguiente: nextMonthYear,
    };

    const response = await axios.post(
      baseUrl + "/acuity/horariosPorServicioPrivado",
      monthPost,
      config
    );

    const fechas = getResponseFecha(response);
    let fechaRecotada = new Set([]);
    fechas.fechasValidas.map((fecha) => {
      fechaRecotada.add(fecha.dia.substring(0, 10));
    });
    fechaRecotada = [...fechaRecotada];
    return {
      loadingDay: fechas.loadingDay,
      fechasValidas: fechaRecotada.sort(),
      horarios: fechas,
    };
  };

  useEffect(() => {
    getDates().then((datesCalendars) => {
      console.log("datesCalendars:", datesCalendars);
      setStateHorarioPorServicio({
        ...stateHorarioPorServicio,
        ...datesCalendars,
        loadingHours: false,
      });
    });
  }, []);

  const regresar = () => {
    let newHistory = {
      ...state,
    };
    history.push("/cita/datosCI", newHistory);
  };

  const selectHorario = (horario) => {
    console.log("horario:", horario);
    setStateHorarioPorServicio({
      ...stateHorarioPorServicio,
      selectHorario: horario,
    });
    // console.log(
    //   "selectHorario stateHorarioPorServicio:",
    //   stateHorarioPorServicio
    // );
  };

  const continuar = async () => {
    if (state.cita.id === undefined) {
      let newHistory = {
        ...state,
        cita: {
          horario: stateHorarioPorServicio.selectHorario,
        },
      };
      const body = {
        datetime: stateHorarioPorServicio.selectHorario.dia,
        // datetime: "2022-01-18T10:00:00-0600",
        appointmentTypeID:
          stateHorarioPorServicio.selectHorario.clinicas[0].serviceId,
        calendarID:
          stateHorarioPorServicio.selectHorario.clinicas[0].calendarID,
        firstName: state.doctor.firstName,
        lastName: state.doctor.lastName,
        email: state.doctor.email,
        phone: state.doctor.phone,
        datosAdicionales: [
          {
            id: 1985156,
            nombreCampo:
              "Si ya eres paciente de salauno favor de ingresar tu numero de expediente",
            value: state.doctor.AccountNumber,
          },
          {
            id: 8410243,
            nombreCampo: "utm_source",
            value: "Cirugia Inteligente",
          },
          {
            id: 8410244,
            nombreCampo: "utm_medium",
            value: "Agenda2.0",
          },
          {
            id: 8410245,
            nombreCampo: "utm_campaign",
            value: "C-SU-CRHENALS-001",
          },
          {
            id: 8410246,
            nombreCampo: "utm_content",
            value: state.CategoriaCI,
          },
          {
            id: 8410248,
            nombreCampo: "utm_term",
            value: state.ServicioCI.nombreDisplay,
          },
          {
            id: 2033264,
            nombreCampo: "Medio",
            value: "Agenda2.0",
          },
        ],
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      const response = await axios.post(
        baseUrl + "/acuity/creaCita",
        body,
        config
      );
      console.log("continuar response:", response);
      if (response.data.error === "not_available") {
        setModalState(true);
      } else {
        const data = {
          action: "updateCx",
          ojoOperar: state.formState.ojoOperar,
          tipoAnestecia: state.formState.tipoAnestecia,
          equipo: state.formState.equipo,
          insumos: state.formState.insumos,
          nombrePaciente: state.formState.nombrePaciente,
          requiereCalLio: state.formState.requiereCalLio,
          modeloLio: state.formState.modeloLio,
          poderLente: state.formState.poderLente,
          comentarios: state.formState.comentarios,
          idCita: response.data.id,
          // idCita: 552738499,
          lugarProcedimiento: state.formState.lugarProcedimiento,
          tipoTecnica: state.formState.tipoTecnica,
        };
        const params = {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        };
        setActualizando(true);
        setTimeout(async () => {
          await fetch(url, params)
            .then((response) => response.json())
            .then((responseJson) => {
              console.log("responseJson:", responseJson);
              setActualizando(false);
              newHistory.cita = response.data;
              newHistory.cirugia = responseJson;
              history.push("/cita/confirmacionCI", newHistory);
            })
            .catch((error) => console.log("error:", error));
        }, 15000);
      }
    } else {
      const body = {
        idCita: state.cita.id,
        time: stateHorarioPorServicio.selectHorario.dia,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      const response = await axios.put(
        baseUrl + "/acuity/reagendar",
        body,
        config
      );
      console.log("continuar response:", response);
      const newHistory = {
        ...state,
      };

      const min =
        stateHorarioPorServicio.selectHorario.dia.substring(14, 16) === "00"
          ? 0
          : stateHorarioPorServicio.selectHorario.dia.substring(14, 16);

      const data = {
        action: "updateCXDateAndTime",
        idCita: state.cita.id,
        anio: parseInt(
          stateHorarioPorServicio.selectHorario.dia.substring(0, 4)
        ),
        mes: parseInt(
          stateHorarioPorServicio.selectHorario.dia.substring(5, 7)
        ),
        dia: parseInt(
          stateHorarioPorServicio.selectHorario.dia.substring(8, 10)
        ),
        hour: parseInt(
          stateHorarioPorServicio.selectHorario.dia.substring(11, 13)
        ),
        min: parseInt(min),
      };
      console.log("data:", data);

      const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      };

      await fetch(url, params)
        .then((responseSalesforce) => responseSalesforce.json())
        .then((responseJson) => {
          console.log("responseJson:", responseJson);
          setActualizando(false);
          newHistory.cita = response.data;
          newHistory.cirugia = responseJson;
          history.push("/cita/confirmacionCI", newHistory);
        })
        .catch((error) => console.log("error:", error));
      history.push("/cita/confirmacionCI", newHistory);
    }
  };

  const handleModal = () => {
    setModalState(!modalState);
  };

  const handleModalForm = () => {
    setModalForm(!modalForm);
  };

  return (
    <HorarioPorServicio
      state={stateHorarioPorServicio}
      loadHours={loadHours}
      setHours={setHours}
      regresar={regresar}
      continuar={continuar}
      selectHorario={selectHorario}
      handleModal={handleModal}
      modalState={modalState}
      modalForm={modalForm}
      handleModalForm={handleModalForm}
      actualizando={actualizando}
    />
  );
};

export default HorarioPorServicioContainer;
