import is from "is_js";

export function getUrlParameter(name) {
  const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

export function getParams() {
  const mobileDevice = is.mobile() || is.tablet();
  const mobileBrowser =
    is.ie() ||
    is.edge("<17") ||
    is.chrome("<70") ||
    is.firefox("<63") ||
    is.opera("<46") ||
    is.safari("<10.3") ||
    is.blackberry();
  const browser =
    is.ie() ||
    is.chrome("<49") ||
    is.firefox("<44") ||
    is.edge("<17") ||
    is.opera("<36") ||
    is.safari("<10.1");

  const params = new URLSearchParams(window.location.search);

  let expectedParams = [
    //Inicios definidos independientes de otros parametros
    "ruta",
    "servicio",
    "diagnostico",
    "sintomatologia",
    "clinicCode",
    "ageUser", //La edad tomada de la fecha de nacimiento o mediante este parametro
    //Promociones o integraciones
    "cupon",
    "promo",
    "cmp",
    //Formas de pago
    "amount",
    "reference",
    "clabe",
    "tipo",
    "eventoId",
    //Prellenado de campos
    "firstName",
    "lastNamePat",
    "lastNameMat",
    "email",
    "fechaNacim",
    "genero",
    "cp",
    "tel",
    "cel",
    "exp",
    "idPersonal",
    //Etiquetas de marketing
    "medio",
    "fuente",
    "gclid",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
    "isContactCenter",
    "sucursal",
    "cirugia",
    "signoVital",
    "recuperacion",
  ];

  let valueParams = {};

  expectedParams.forEach((parameter) => {
    Object.defineProperty(valueParams, parameter, {
      value: mobileDevice
        ? mobileBrowser
          ? getUrlParameter(parameter)
          : params.get(parameter)
        : browser
        ? getUrlParameter(parameter)
        : params.get(parameter),
      writable: true,
      enumerable: true,
      configurable: true,
    });
  });

  return valueParams;
}

export function getParamsString() {
  let paramsString = "";
  let paramsExist = false;
  const urlParams = getParams();

  Object.entries(urlParams).forEach((parametro) => {
    if (parametro[1] !== null) {
      if (!paramsExist) {
        paramsExist = true;
        paramsString += "?";
      } else {
        paramsString += "&";
      }
      paramsString += `${parametro[0]}=${parametro[1]}`;
    }
  });

  return paramsString;
}
