import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { BotonHoraPromo } from "../Botones";

import styles from "./Tarjetas.module.css";

const martinSinHorario = require("../../images/svg/NoPromo.svg");

export default class TarjetasHoraPromo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botonSeleccionado: null,
    };
  }

  promoSelected = (promo1, promo2, idBoton) => {
    const { promoSelectedFecha } = this.props;
    promoSelectedFecha(promo1, promo2);
    if (process.env.NODE_ENV !== "production") {
      console.tron.log(
        `[TarjetasHoraPromo] Promo1: ${promo1} Promo2: ${promo2}`
      );
    }
    this.setState({
      botonSeleccionado: idBoton,
    });
  };

  render() {
    const { horas, seleccionoHora, duration } = this.props;
    const { botonSeleccionado } = this.state;
    const arrHoras = [];
    const arrPromoHoras = [];
    const promoDateTime = [];
    const tmpNextMin1 = duration;
    const tmpNextMax1 = 40;
    const tmpNextMin2 = 60 - duration;
    const tmpNextMax2 = 20;

    let tmpHr = "";
    let tmpMin = "";
    let tmpHr2 = "";
    let tmpMin2 = "";
    let botonHoraPromo = "";
    let indexPromo = 0;
    horas.map((itemHoras) =>
      arrHoras.push(
        `${moment(itemHoras.time, "YYYY-MM-DDTHH:mm:ssZ")
          .toDate()
          .getHours()}:${
          moment(itemHoras.time, "YYYY-MM-DDTHH:mm:ssZ").toDate().getMinutes() <
          10
            ? "0"
            : ""
        }${moment(itemHoras.time, "YYYY-MM-DDTHH:mm:ssZ")
          .toDate()
          .getMinutes()}`
      )
    );
    //!Hasta aqui funciona bien
    // if (process.env.NODE_ENV !== 'production') { console.tron.log(`[TarjetasHoraPromo] arrHoras 2x1: ${arrHoras}`) }
    for (let i = 0; i < arrHoras.length - 1; i += 1) {
      tmpHr = arrHoras[i].split(":")[0];
      tmpMin = arrHoras[i].split(":")[1];
      if (arrHoras[i + 1]) {
        tmpHr2 = arrHoras[i + 1].split(":")[0];
        tmpMin2 = arrHoras[i + 1].split(":")[1];
      } else {
        tmpHr2 = "";
        tmpMin2 = "";
      }
      if (tmpHr2 - tmpHr <= 1) {
        if (tmpHr2 - tmpHr === 0) {
          if (
            tmpMin2 - tmpMin <= tmpNextMax1 &&
            tmpMin2 - tmpMin >= tmpNextMin1
          ) {
            arrPromoHoras.push(arrHoras[i]);
            arrPromoHoras.push(arrHoras[i + 1]);
            promoDateTime.push(horas[i].time);
            promoDateTime.push(horas[i + 1].time);
          }
        } else if (
          tmpMin - tmpMin2 >= tmpNextMax2 &&
          tmpMin - tmpMin2 <= tmpNextMin2
        ) {
          arrPromoHoras.push(arrHoras[i]);
          arrPromoHoras.push(arrHoras[i + 1]);
          promoDateTime.push(horas[i].time);
          promoDateTime.push(horas[i + 1].time);
        }
      }
    }
    // if (process.env.NODE_ENV !== 'production') { console.tron.log(`[TarjetasHoraPromo] Horas c/ Promo ${arrPromoHoras.length}`) }
    // if (process.env.NODE_ENV !== 'production') { console.tron.log(`[TarjetasHoraPromo] ${promoDateTime.length} promoDateTime: ${promoDateTime}`) }

    if (arrPromoHoras.length === 0 && seleccionoHora) {
      botonHoraPromo = (
        <div className={styles.divMartin}>
          <img
            src={martinSinHorario}
            alt=""
            className={styles.martinSinHorario}
          />
        </div>
      );
    } else {
      botonHoraPromo = arrPromoHoras.map((itemHoras, index) => {
        if (index === 0 || index % 2 === 0) {
          indexPromo += 1;
          // if (process.env.NODE_ENV !== 'production') { console.tron.log(`[TarjetasHoraPromo] Opciones: ${arrPromoHoras[index]} ${arrPromoHoras[index + 1]} ${promoDateTime[index]} ${promoDateTime[index + 1]}`) }
          return (
            <BotonHoraPromo
              promo1={arrPromoHoras[index]}
              promo2={arrPromoHoras[index + 1]}
              promoDateTime1={promoDateTime[index]}
              promoDateTime2={promoDateTime[index + 1]}
              promoKey={indexPromo}
              key={indexPromo}
              promoSelected={this.promoSelected}
              estiloClassname={
                indexPromo !== botonSeleccionado
                  ? "estiloHoraBtnElement"
                  : "estiloHoraBtnElementActivo"
              }
            />
          );
        }
        return null;
      });
    }
    return <div className={styles.horaContentPromo}>{botonHoraPromo}</div>;
  }
}

TarjetasHoraPromo.propTypes = {
  promoSelectedFecha: PropTypes.any,
  horas: PropTypes.any,
  seleccionoHora: PropTypes.any,
  servicio: PropTypes.any,
  duration: PropTypes.any,
};

TarjetasHoraPromo.defaultProps = {
  promoSelectedFecha: null,
  horas: null,
  seleccionoHora: null,
  servicio: null,
  duration: null,
};
