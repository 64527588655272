import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

import styles from './TipoConsultaPresencialOmoStyles.module.css';

import sprite from 'images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg';
import EstrellaRecomendada from 'images/svg/tipoConsulta__EstrellaRecomendada.svg';
import presencial from 'images/png/TipoConsultaPresencialOmoPNG/Tipo_presencial.png';
import omo from 'images/png/TipoConsultaPresencialOmoPNG/Tipo_virtual.png';
import Admiracion from 'images/svg/confirmacion__admiracion.svg';

export default ({
	switchPrecencialOmo,
	tipoConsultaState,
	type,
	prioridad,
	prioridadAlternativa,
	state,
	EdadPaciente,
	muestraModal,
	modalConsultaAEspecialista,
}) => {
	const { nombreServicio } = state.diagnostico;
	const { urlParams } = state;
	console.log('urlParams', urlParams);
	const isPrecioUrlCampaña = urlParams.medio === 'Campanas' ? 150 : 350;
	let precioDefaultPresencial =
		prioridadAlternativa === 1 && type === 'presencial' ? 590 : isPrecioUrlCampaña;
	if (nombreServicio === 'Vision Integral') {
		precioDefaultPresencial = 150;
	}
	let precioDefaultOmo = 100;
	const [modal, setModal] = useState(true);
	const toggle = () => setModal(!modal);

	const displayFirstPresencial = () => {
		return (
			<>
				<Col
					xs={12}
					lg={nombreServicio === 'Vision Integral' ? 12 : 5}
					className={`${styles.colPresencialContainer} d-flex justify-content-center`}>
					<Container
						fluid
						className={`${
							nombreServicio === 'Vision Integral' &&
							styles.displayAlingnCenterProvicional
						} ${styles.containerPresencial} d-flex `}>
						<Row
							className={`${styles.rowPresencialContainer} ${styles.mBorderColorActive}`}>
							<Col
								xs={12}
								className={`${styles.colContainerRecomendada} d-flex justify-content-center align-items-center`}>
								<div className={`${styles.colContainerRecomendada__div}`}>
									<img
										src={EstrellaRecomendada}
										className={`${styles.colContainerRecomendada__img}`}
										alt='icon_recomendada'
									/>
									<p className={`${styles.colContainerRecomendada__p}`}>
										Recomendada
									</p>
								</div>
							</Col>
							<Col lg={12} className={`${styles.colIconPresencial}`}>
								{/* // !Aqui va el icono de presencial */}
								<img
									src={presencial}
									alt='icono cita presencial'
									className={`${styles.colIconPresencial__img}`}
								/>
							</Col>
							<Col lg={12} className={`${styles.colInfoPresencial}`}>
								<Row className={`${styles.rowInfoPresencial}`}>
									<Col lg={12} className={`${styles.colInfoTitle}`}>
										<p className={`${styles.colInfoTitle__title}`}>
											Consulta en tu Clínica Segura
										</p>
									</Col>
									<Col lg={12} className={`${styles.colInfoPrice}`}>
										<p className={`${styles.colInfoPrice__p}`}>
											{`Desde $${precioDefaultPresencial} pesos`}
										</p>
									</Col>
									<Col lg={12} className={`${styles.colInfo}`}>
										<p className={`${styles.colInfo__p}`}>
										Contamos con un estricto protocolo para la prevención de COVID 19.
										</p>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className={`${styles.rowPresencialContainerButton}`}>
							<Col
								lg={12}
								className={`${
									nombreServicio === 'Vision Integral' &&
									styles.displatJustifyCenterProvicional
								} ${styles.colPresencialContainerButton}`}>
								<button
									onClick={() => switchPrecencialOmo('opcionPresencial')}
									className={`${styles.colPresencialContainerButton__button}`}>
									<Container
										fluid
										className={`${styles.buttonContainerParams}`}>
										<Row className={`${styles.rowContainerParams}`}>
											<Col
												xs={8}
												className={`${styles.colContainerNombreBoton}`}>
												AGENDAR CITA
											</Col>
											<Col
												xs={2}
												className={`${styles.colContainerSpanBotonOMO}`}>
												<span
													className={`${styles.colPresencialContainer__span}`}>
													<svg
														className={`${styles.colPresencialContainerButton__svg}`}>
														<use xlinkHref={`${sprite}#icon-arrow-right`} />
													</svg>
												</span>
											</Col>
										</Row>
									</Container>
								</button>
							</Col>
						</Row>
					</Container>
				</Col>
				{nombreServicio !== 'Vision Integral' && (
					<>
						<Col
							lg={2}
							className={`${styles.colContainerSeparador} d-flex justify-content-center align-items-center`}>
							<div className={`${styles.colContainerSeparador__separador}`} />
						</Col>
						{/* //! Opcion Omo */}
						<Col
							xs={12}
							lg={5}
							className={`${styles.colOmoContainer} d-flex justify-content-center`}>
							<Container fluid className={`${styles.containerOmo} `}>
								<Row className={`${styles.rowOmoContainer}`}>
									<Col lg={12} className={`${styles.colIconOmo}`}>
										<img
											src={omo}
											alt='icono cita presencial'
											className={`${styles.colIconOmo__img}`}
										/>
									</Col>
									<Col lg={12} className={`${styles.colInfoOmo}`}>
										<Row className={`${styles.rowInfoOmo}`}>
											<Col lg={12} className={`${styles.colInfoTitle}`}>
												<p className={`${styles.colInfoTitle__title}`}>
													Oftalmólogo en tu casa
												</p>
											</Col>
											<Col lg={12} className={`${styles.colInfoPrice}`}>
												<p
													className={`${styles.colInfoPrice__p}`}>{`$${precioDefaultOmo} pesos`}</p>
											</Col>
											<Col lg={12} className={`${styles.colInfo}`}>
												<p className={`${styles.colInfo__p}`}>
												Agenda una orientación médica oftalmológica para hablar con nuestro especialista sin salir de casa.{' '}
													<a
														className={`${styles.colInfo__a}`}
														href='https://www.salauno.com.mx/servicios/tu-doctor-en-linea'>
														Más Info
													</a>
												</p>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className={`${styles.rowOmoContainerButton}`}>
									<Col lg={12} className={`${styles.colOmoContainerButton}`}>
										<button
											onClick={() => switchPrecencialOmo('opcionOMO')}
											className={`${styles.colOmoContainerButton__button}`}>
											<Container
												fluid
												className={`${styles.buttonContainerParams}`}>
												<Row>
													<Col
														xs={10}
														className={`${styles.colContainerNombreBoton}`}>
														AGENDAR ORIENTACIÓN
													</Col>
													<Col
														xs={2}
														className={`${styles.colContainerSpanBotonOMO}`}>
														<span className={`${styles.colOmoContainer__span}`}>
															<svg
																className={`${styles.colOmoContainerButton__svg}`}>
																<use xlinkHref={`${sprite}#icon-arrow-right`} />
															</svg>
														</span>
													</Col>
												</Row>
											</Container>
										</button>
									</Col>
								</Row>
							</Container>
						</Col>
					</>
				)}
			</>
		);
	};

	const displayFirstOmo = () => {
		return (
			<>
				<Col
					xs={12}
					lg={EdadPaciente < 40 ? 12 : 5}
					className={`${styles.colPresencialContainer} d-flex justify-content-center`}>
					<Container
						fluid
						className={`${
							EdadPaciente < 40 && styles.displayAlingnCenterProvicional
						} ${styles.containerPresencial} d-flex`}>
						<Row
							className={`${styles.rowPresencialContainer} ${styles.mBorderColorActive} ${styles.rowReverso}`}>
							<Col
								xs={12}
								className={`${styles.colContainerRecomendada} d-flex justify-content-center align-items-center`}>
								<div className={`${styles.colContainerRecomendada__div}`}>
									<img
										src={EstrellaRecomendada}
										className={`${styles.colContainerRecomendada__img}`}
										alt='icon_recomendada'
									/>
									<p className={`${styles.colContainerRecomendada__p}`}>
										Recomendada
									</p>
								</div>
							</Col>
							<Col lg={12} className={`${styles.colIconPresencial}`}>
								{/* // !Aqui va el icono de omo*/}
								<img
									src={omo}
									alt='icono cita presencial'
									className={`${styles.colIconPresencial__img}`}
								/>
							</Col>
							<Col lg={12} className={`${styles.colInfoPresencial}`}>
								<Row className={`${styles.rowInfoPresencial}`}>
									<Col lg={12} className={`${styles.colInfoTitle}`}>
										<p className={`${styles.colInfoTitle__title}`}>
											Oftalmólogo en tu casa
										</p>
									</Col>
									<Col lg={12} className={`${styles.colInfoPrice}`}>
										<p
											className={`${styles.colInfoPrice__p}`}>{`$${precioDefaultOmo} pesos`}</p>
									</Col>
									<Col lg={12} className={`${styles.colInfo}`}>
										<p className={`${styles.colInfo__p}`}>
											Agenda una orientación médica oftalmológica para hablar con nuestro especialista sin salir de casa.{' '}
											<a
												className={`${styles.colInfo__a}`}
												href='https://www.salauno.com.mx/servicios/tu-doctor-en-linea'>
												Más Info
											</a>
										</p>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className={`${styles.rowPresencialContainerButton}`}>
							<Col
								lg={12}
								className={`${
									EdadPaciente < 40 && styles.displatJustifyCenterProvicional
								} ${styles.colPresencialContainerButton}`}>
								<button
									onClick={() => switchPrecencialOmo('opcionOMO')}
									className={`${styles.colOmoContainerButton__button} ${styles.colorAmarillo}`}>
									<Container fluid>
										<Row className={`${styles.rowContainerParams}`}>
											<Col
												xs={10}
												className={`${styles.colContainerNombreBoton}`}>
												AGENDAR ORIENTACIÓN
											</Col>
											<Col
												xs={2}
												className={`${styles.colContainerSpanBotonOMO}`}>
												<span
													className={`${styles.colPresencialContainer__span}`}>
													<svg
														className={`${styles.colPresencialContainerButton__svg}`}>
														<use xlinkHref={`${sprite}#icon-arrow-right`} />
													</svg>
												</span>
											</Col>
										</Row>
									</Container>
								</button>
							</Col>
						</Row>
					</Container>
				</Col>
				{EdadPaciente >= 40 && (
					<>
						<Col
							lg={2}
							className={`${styles.colContainerSeparador} d-flex justify-content-center align-items-center`}>
							<div className={`${styles.colContainerSeparador__separador}`} />
						</Col>
						<Col
							xs={12}
							lg={5}
							className={`${styles.colOmoContainer} d-flex justify-content-center`}>
							<Container fluid className={`${styles.containerOmo}`}>
								<Row className={`${styles.rowOmoContainer}`}>
									<Col lg={12} className={`${styles.colIconOmo}`}>
										{/* // !Aqui va el icono de presencial */}
										<img
											src={presencial}
											alt='icono cita presencial'
											className={`${styles.colIconOmo__img}`}
										/>
									</Col>
									<Col lg={12} className={`${styles.colInfoOmo}`}>
										<Row className={`${styles.rowInfoOmo}`}>
											<Col lg={12} className={`${styles.colInfoTitle}`}>
												<p className={`${styles.colInfoTitle__title}`}>
													Consulta en tu Clínica Segura
												</p>
											</Col>
											<Col lg={12} className={`${styles.colInfoPrice}`}>
												<p className={`${styles.colInfoPrice__p}`}>
													{`Desde $${precioDefaultPresencial} pesos`}
												</p>
											</Col>
											<Col lg={12} className={`${styles.colInfo}`}>
												<p className={`${styles.colInfo__p}`}>
													Contamos con un estricto protocolo para la prevención de COVID 19.
												</p>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className={`${styles.rowOmoContainerButton}`}>
									<Col lg={12} className={`${styles.colOmoContainerButton}`}>
										<button
											onClick={() => switchPrecencialOmo('opcionPresencial')}
											className={`${styles.colPresencialContainerButton__button} ${styles.transparente}`}>
											<Container
												fluid
												className={`${styles.buttonContainerParams}`}>
												<Row className={`${styles.rowContainerParams}`}>
													<Col
														xs={8}
														className={`${styles.colContainerNombreBoton}`}>
														AGENDAR CITA
													</Col>
													<Col
														xs={2}
														className={`${styles.colContainerSpanBotonOMO}`}>
														<span className={`${styles.colOmoContainer__span}`}>
															<svg
																className={`${styles.colOmoContainerButton__svg}`}>
																<use xlinkHref={`${sprite}#icon-arrow-right`} />
															</svg>
														</span>
													</Col>
												</Row>
											</Container>
										</button>
									</Col>
								</Row>
							</Container>
						</Col>
					</>
				)}
			</>
		);
	};

	return (
		<Container fluid className={`${styles.containerTipoConsultaPresencialOmo}`}>
			<Container className={`${styles.container}`}>
				<Row className={`${styles.rowContainerTitles}`}>
					<Col
						xs={12}
						className={`${styles.colTitle1} d-flex justify-content-center align-items-end`}>
						<p className={`${styles.colTitle1__title}`}>
							{EdadPaciente < 40 && type !== 'presencial'
								? '¡Tu que puedes, '
								: 'Elijan su consulta en sala'}
							<span className={`${styles.colTitle1__span}`}>
								{EdadPaciente < 40 && type !== 'presencial'
									? 'quédate en casa!'
									: 'uno'}
							</span>
						</p>
					</Col>
					<Col
						xs={12}
						className={`d-flex justify-content-center align-items-center`}>
						{EdadPaciente < 40 && type !== 'presencial' ? (
							<p className={`${styles.colTitle2__title}`}>
								Para cumplir con las medidas de
								<strong>
									{' '}
									sana distancia y garantizar la salud y seguridad
								</strong>{' '}
								de nuestros pacientes,{' '}
								<strong> les recomendamos agendar </strong>
								la opción de consulta para{' '}
								<strong> Orientación Médica Oftalmológica</strong> y de ser
								necesario el médico oftalmólogo te referirá a cualquiera de
								nuestras clínicas.
							</p>
						) : (
							<p className={`${styles.colTitle2__title}`}>
								De acuerdo a sus respuestas a las preguntas anteriores, les recomendamos agendar una
								<span className={`${styles.colTitle2__span}`}>
									{type === 'presencial'
										? ' consulta presencial.'
										: ' consulta directo en clínica.'}
								</span>
							</p>
						)}
					</Col>
				</Row>
				<Row className={`${styles.rowContainerTipoConsultaPresencialOmo}`}>
					{type === 'presencial' ? displayFirstPresencial() : displayFirstOmo()}
					{muestraModal === true ? (
						<div>
							<Modal
								isOpen={modal}
								toggle={toggle}
								className={styles.modalContainer}
								backdropClassName={`${styles.backdropModal}`}
								contentClassName={`${styles.modalContanet}`}>
								<ModalHeader
									toggle={toggle}
									className={`${styles.modalHeader}`}
									buttonClassClosed={`${styles.modalButtonClosed}`}
								/>
								<ModalBody
									className={styles.modalTipoConsultaPresencialOmo__body}>
									<Container>
										<Row>
											<Col
												xs={12}
												className={`d-flex justify-content-center align-items-center`}>
												<img src={Admiracion} alt='modal_icon' />
											</Col>
											<Col
												xs={12}
												className={`${styles.modalTipoConsultaPresencialOmo}`}>
												<p className={styles.modalTipoConsultaPresencialOmo__p}>
													Por alguno de los síntomas que refieres, te
													recomendamos asistir a cualquiera de nuestras clínicas
													de manera presencial con un oftalmólogo especialista.
													Si deseas continuar con tu orientación médica
													oftalmológica en línea da click enc continuar. Si
													durante esta orientación el oftalmólogo diagnostica la
													necesidad de una consulta presencial de alta
													especialidad, deberás pagar el costo de la misma.
												</p>
											</Col>
											{/* <Col xs={12}>
												<p className={styles.modalTipoConsultaPresencialOmo__p}>
													Si deseas agendar tu orientación oftalmológica en
													línea. En este caso, tiene un precio de $350.00 y de
													ser referido con el especialista de forma presencial,
													deberás pagar tu consulta de alta especialidad.
												</p>
											</Col> */}
											<Col
												xs={12}
												className={`${styles.colModalTipoConsultaPresencialOmoButton} d-flex justify-content-center`}>
												<Button
													className={
														styles.modalTipoConsultaPresencialOmo__buttonEspecialista
													}
													onClick={modalConsultaAEspecialista}>
													AGENDAR CONSULTA CON ESPECIALISTA
												</Button>
											</Col>
											<Col
												xs={12}
												className={`${styles.colModalTipoConsultaPresencialOmoButton} d-flex justify-content-center`}>
												<Button
													className={
														styles.modalTipoConsultaPresencialOmo__button
													}
													onClick={toggle}>
													CONTINUAR AGENDANDO MI ORIENTACIÓN
												</Button>
											</Col>
										</Row>
									</Container>
								</ModalBody>
							</Modal>
						</div>
					) : null}
				</Row>
			</Container>
		</Container>
	);
};
