import React from "react";
import { Container, Row, Col } from "reactstrap";
import styles from "./FormaDePagoTarjetaStyles.module.css";
import GeneralToatsContainer from "../../../components/GeneralToats/GeneralToatsContainer";

export default (props) => {
  const {
    realizarPago,
    error,
    handleChange,
    handleChangeTipoTarjeta,
    datos,
    peticion,
    momentNow,
  } = props;
  let arrayAños=[];
  const añosCard = () => {
    for (let index = 0; index <= 24; index++) {
      arrayAños[index] = parseInt(momentNow) + index;
    }
  };
  añosCard();
  return (
    <Container className={`${styles.container}`}>
      <Row className={`${styles.rowContainer}`}>
        <Col xs={12} className={`${styles.colContainerForm}`}>
          <form
            id="card-form"
            onSubmit={realizarPago}
            className={`${styles.form} `}
          >
            <Container className={`${styles.formContainer}`}>
              {error !== "" && peticion === "Hacer peticion" && (
                <GeneralToatsContainer
                  mensajeError={error}
                  backgroundHeader={"Yellow"}
                  typeStyles={"FormaDePago"}
                />
              )}
              <Row className={`${styles.rowTitle}`}>
                <Col xs={12} className={`${styles.colTitle}`}>
                  <p className={`${styles.colTitle__title}`}>
                    Débito o Crédito
                  </p>
                </Col>
              </Row>
              <Row className={`${styles.rowDataCard1} my-0 py-1`}>
                <Col
                  xs={12}
                  md={6}
                  className={`${styles.colNumeroTarjetaContainer}`}
                >
                  <Row className={`${styles.rowNumeroTarjetaContainer}`}>
                    <Col xs={12}>
                      <Row>
                        <Col
                          xs={12}
                          className={`${styles.colTitleNumeroTarjeta}`}
                        >
                          <label
                            className={`${styles.colTitleNumeroTarjeta__title}`}
                          >
                            Numero de Tarjeta
                          </label>
                        </Col>
                        <Col xs={12} className={`${styles.colNumeroTarjeta}`}>
                          <Row>
                            <Col
                              xs={12}
                              className={styles.colNumeroTarjetaInputContainer}
                            >
                              <input
                                className={`${styles.colNumeroTarjeta__input}`}
                                type="number"
                                size="20"
                                data-conekta="card[number]"
                                id="number"
                                name="number"
                                onChange={handleChangeTipoTarjeta}
                                required
                                placeholder="Numero"
                              />
                            </Col>
                            <Col
                              lxs={12}
                              className={styles.colNumeroTarjetaSvgContainer}
                            >
                              {/* {tipoTarjeta === "visa" && (
                            <svg className={styles.colNumeroTarjeta__svg}>
                              <use xlinkHref={`${sprite}#icon-cc-visa`} />
                            </svg>
                          )}
                          {tipoTarjeta === "mastercard" && (
                            <svg className={styles.colNumeroTarjeta__svg}>
                              <use xlinkHref={`${sprite}#icon-cc-mastercard`} />
                            </svg>
                          )}
                          {tipoTarjeta === "amex" && (
                            <svg className={styles.colNumeroTarjeta__svg}>
                              <use xlinkHref={`${sprite}#icon-cc-amex`} />
                            </svg>
                          )} */}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  className={`${styles.colFechaVencimientoContainer}`}
                >
                  <Row className={`${styles.rowFechaVencimientoContainer}`}>
                    <Col xs={6} className={`${styles.colFechaVencimientoMes}`}>
                      <Row className={`${styles.rowMesAñoContainer}`}>
                        <Col
                          xs={12}
                          className={`${styles.colTitleFechaVencimiento}`}
                        >
                          <label
                            className={`${styles.colTitleFechaVencimiento__title}`}
                          >
                            Fecha de Vencimiento
                          </label>
                        </Col>

                        <Col
                          xs={12}
                          className={`${styles.colFechaVencimientoMes}`}
                        >
                          <select
                            className={`${styles.colFechaVencimientoMes__input}`}
                            // type="number"
                            data-conekta="card[exp_month]"
                            id="exp_month"
                            required
                            placeholder="MM"
                          >
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={6}
                      className={`${styles.colFechaVencimientoAñoContainer}`}
                    >
                      <Row
                        className={`${styles.rowFechaVenicimientoAñoContainer}`}
                      >
                        <Col
                          xs={12}
                          className={`${styles.colTitleFechaVencimientoAño}`}
                        >
                          <label
                            className={`${styles.colTitleFechaVencimientoAño__title}`}
                          ></label>
                        </Col>
                        <Col
                          xs={12}
                          className={`${styles.colFechaVencimientoAño}`}
                        >
                          <select
                            className={`${styles.colFechaVencimientoAño__input}`}
                            // type="number"
                            // min={momentNow}
                            // max={momentDurationCard}
                            // size="4"
                            data-conekta="card[exp_year]"
                            id="exp_year"
                            required
                            placeholder="AAAA"
                          >
                            {arrayAños.map((año, index) => (
                              <option value={año} key={`${index}${año}`}>
                                {año}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={`${styles.rowDataCard2} my-3 py-1`}>
                <Col
                  xs={12}
                  md={6}
                  className={`${styles.colNombreTarjetaContainer}`}
                >
                  <Row className={`${styles.rowNombreTarjetaContainer}`}>
                    <Col xs={12} className={`${styles.colTitleNombreTarjeta}`}>
                      <label
                        className={`${styles.colTitleNombreTarjeta__title}`}
                      >
                        Nombre en la Tarjeta
                      </label>
                    </Col>
                    <Col xs={12} className={`${styles.colNombreTarjeta}`}>
                      <input
                        className={`${styles.colNombreTarjeta__input}`}
                        data-conekta="card[name]"
                        id="name"
                        name="name"
                        onChange={handleChange}
                        required
                        size="20"
                        type="text"
                        placeholder="Nombre del paciente"
                        value={datos.name}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} className={`${styles.colCvvContainer}`}>
                  <Row className={`${styles.rowCvvContainer}`}>
                    <Col xs={12} className={`${styles.colTitleCvv}`}>
                      <label className={`${styles.colTitleCvv_title}`}>
                        CVV
                      </label>
                    </Col>
                    <Col xs={12} className={`${styles.colCvv}`}>
                      <input
                        className={`${styles.colCvv__input}`}
                        type="number"
                        size="4"
                        data-conekta="card[cvc]"
                        id="cvc"
                        required
                        placeholder="cvv"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </form>
        </Col>
      </Row>
    </Container>
  );
};
