import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './Botones.module.css'
import '../../css/Fonts.css'

let componentePrecio = ''

export default class BotonServicios extends Component {
  constructor(props) {
    super(props)
    this.state = {
      respuesta: '',
    }
  }

  handleClick = () => {
    const {
      navega,
      tarjetaID,
      precioIntegracion,
      datosServicio,
    } = this.props

    let { precio } = datosServicio

    let link = null

    if (datosServicio.serviceID === 0) {
      link = '/cita/sinLentes'
    } else {
      link = '/cita/clinica'
    }

    if (precioIntegracion && precioIntegracion !== '') {
      precio = precioIntegracion
    }

    if (process.env.NODE_ENV !== 'production') {
      console.tron.display({
        name: '[BotonServicios] Servicio',
        value: {
          datosServicio,
          precioIntegracion,
          precio,
        },
      })
    }

    navega(link, datosServicio.serviceID, datosServicio.serviceHeaderName, precio, tarjetaID)
  }

  render() {
    const {
      imagen,
      color,
      descripcion,
      precioIntegracion,
      datosServicio,
    } = this.props
    const { respuesta } = this.state
    // if (process.env.NODE_ENV !== 'production') { console.tron.log(`[BotonServicios] Imagen URL: ${imagen}`) }

    if (precioIntegracion !== '' && precioIntegracion) {
      componentePrecio = (
        <p className={`${styles.dinFont} ${styles.secondaryBlue}`}>
          <span className={styles.underlineThrough}>
            {datosServicio.price}
          </span>
          <span className={styles.newPrice}>
            &nbsp;
            {precioIntegracion}
          </span>
        </p>
      )
    } else {
      componentePrecio = (
        <p className={`${styles.dinFont} ${styles.secondaryBlue}`}>
          {datosServicio.price}
        </p>
      )
    }

    /* if ((promocion === 'cien2020') && (datosServicio.serviceID === 1747553)) {
      componentePrecio = (
        <p className={`${styles.dinFont} ${styles.secondaryBlue}`}>
          <span className={styles.underlineThrough}>
            350 MXN
          </span>
          <span className={styles.newPrice}>
            &nbsp;
            {datosServicio.price}
          </span>
        </p>
      )
    } */

    return (
      <div className={styles.serviceBtn}>
        <button
          className={styles.serviceBtnElement}
          color={color}
          value={datosServicio.serviceID}
          onClick={this.handleClick}
          type="button"
        >
          {
            (datosServicio.divClass === 'front')
              ? (
                <div className={styles.contenedor}>
                  <div className={`${styles.div_imgServicio}`}>
                    <img src={imagen} className={styles.iconoServicio} alt="" />
                  </div>
                  <div className={`${styles.div_textoServicio}`}>
                    <p className={`${styles.p_Servicio}`}>
                      {datosServicio.serviceDisplayName}
                    </p>
                    <p className={styles.contenedorDescripcionMovil}>
                      {descripcion}
                    </p>
                    { componentePrecio }
                  </div>
                </div>
              )
              : (
                <p className={styles.contenedorDescripcion}>
                  {datosServicio.serviceDisplayName}
                </p>
              )
          }
        </button>
        <div>
          <p>{respuesta}</p>
        </div>
      </div>
    )
  }
}

BotonServicios.propTypes = {
  color: PropTypes.any,
  datosServicio: PropTypes.object,
  descripcion: PropTypes.string,
  imagen: PropTypes.any,
  navega: PropTypes.func,
  precioIntegracion: PropTypes.any,
  promocion: PropTypes.string,
  tarjetaID: PropTypes.any,
}

BotonServicios.defaultProps = {
  color: null,
  datosServicio: {},
  descripcion: '',
  imagen: null,
  navega: () => {},
  precioIntegracion: null,
  promocion: null,
  tarjetaID: null,
}
