import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import sprite from "images/svg/SVG_TipoConsultaPresencialOmo/sprite.svg";

import styles from "./CreaPacienteTamizadoStyles.module.css";

import moment from "moment";
import "moment/locale/es";

export default ({ gdlvCampaign }) => {
  const [nacimiento, setNacimiento] = useState("");
  const handleChageNacimiento = (event) => {
    let fechaNacimiento = new Date(event.target.value);
    console.log("fecha1", event.target.value);
    console.log(
      "fecha2",
      moment(fechaNacimiento).format("YYYY-MM-DD").toString()
    );
    console.log(
      "fecha3",
      moment(fechaNacimiento).format("DD/MM/YYYY").toString()
    );
    console.log("fecha4", fechaNacimiento.toString());
    setNacimiento(moment(fechaNacimiento).format("DD/MM/YYYY").toString());
  };
  return (
    <Container fluid className={`${styles.containerBienvenido}`}>
      <Container className={`${styles.bienvenidoContainer}`}>
        <Row className={`${styles.rowContainerFormulario}`}>
          <Col className={`${styles.colContainerFormulario}`}>
            <form
              action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
              method="POST"
              className={`${styles.formContainer}`}
            >
              <h1 className={`${styles.formContainer__h1}`}>
                Ingresa los datos de tu tamizaje
              </h1>
              <Container fluid className={`${styles.containerInFormulario}`}>
                <input type="hidden" name="oid" value="00D36000000HFeS" />
                <input
                  type="hidden"
                  name="retURL"
                  value="https://citas.salauno.com.mx/cita/bienvenida?medio=GDLV-Optometria"
                  //value='http://localhost:3000/cita/bienvenida?medio=GDLV-Optometria'
                />
                {/* <input  type='hidden' id="recordType" name="recordType" value="01236000000E5C9" /> */}
                <Row className={`${styles.rowContainerItems}`}>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="first_name"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Nombre *
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id="first_name"
                          maxlength="40"
                          name="first_name"
                          size="20"
                          type="text"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="Nombre(s)"
                          required
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="last_name"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Apellidos *
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id="last_name"
                          maxlength="80"
                          name="last_name"
                          size="20"
                          type="text"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="Apellido P  Appellido M"
                          required
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="email"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Correo electrónico *
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id="email"
                          maxlength="80"
                          name="email"
                          size="20"
                          type="text"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="correo@hotmail.com"
                          required
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="fecha_nacimiento"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Fecha de nacimiento *
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          type="date"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="dd/mm/aaaa"
                          onChange={(e) => handleChageNacimiento(e)}
                          required
                        />
                        <input
                          id="00N36000005wJBE"
                          name="00N36000005wJBE"
                          type="text"
                          className={`${styles.colContainerInput__input} ${styles.displayInput_hidden}`}
                          value={nacimiento}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="mobile"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Teléfono celular *
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id="mobile"
                          maxlength="10"
                          name="mobile"
                          size="10"
                          type="tel"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="Teléfono celular"
                          required
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="phone"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Teléfono fijo
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <input
                          id="phone"
                          maxlength="10"
                          name="phone"
                          size="10"
                          type="tel"
                          className={`${styles.colContainerInput__input}`}
                          placeholder="Teléfono fijo"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="recordType"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Tipo de registro de candidato
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <select
                          className={`${styles.rowContainerItem__select}`}
                          id="recordType"
                          name="recordType"
                        >
                          <option value="01236000000E5C9">Paciente</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="diabetes"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Diabetes
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <select
                          className={`${styles.rowContainerItem__select}`}
                          id="00N36000006l4O3"
                          name="00N36000006l4O3"
                          title="Diabetes"
                        >
                          <option value="">-- Ninguno --</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="hipertension"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Hipertensión
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <select
                          className={`${styles.rowContainerItem__select}`}
                          id="00N36000006l58Y"
                          name="00N36000006l58Y"
                          title="Hipertensión"
                        >
                          <option value="">-- Ninguno --</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="otra_enfermedad"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Otra enfermedad Sistemica
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <select
                          className={`${styles.rowContainerItem__select}`}
                          id="00N3600000OyrbY"
                          name="00N3600000OyrbY"
                          title="Otra enfermedad Sistemica"
                        >
                          <option value="">--Ninguno--</option>
                          <option value="LUPUS">LUPUS</option>
                          <option value="ARTITIS">ARTITIS</option>
                          <option value="ENFERMEDAD DE TIROIDES">
                            ENFERMEDAD DE TIROIDES
                          </option>
                          <option value="ENFERMEDAD DE ADISON">
                            ENFERMEDAD DE ADISON
                          </option>
                          <option value="SÍNDROME SJOGREN">
                            SÍNDROME SJOGREN
                          </option>
                          <option value="VIH">VIH</option>
                          <option value="TUBERCULOSIS">TUBERCULOSIS</option>
                          <option value="ESCLEROSIS MÚLTIPLE">
                            ESCLEROSIS MÚLTIPLE
                          </option>
                          <option value="LEUCEMIA">LEUCEMIA</option>
                          <option value="OTRO">OTRO</option>
                          <option value="SIN PATOLOGÍA APARENTE (SANO)">
                            SIN PATOLOGÍA (SANO)
                          </option>
                        </select>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="comentario"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          ¿Desea realizar algun comentaro?
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <textarea
                          className={`${styles.rowContainerItem__textArea}`}
                          id="00N1R00000TWdE1"
                          name="00N1R00000TWdE1"
                          rows="10"
                          type="text"
                          wrap="soft"
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={4} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerLabel}`}>
                        <label
                          htmlFor="comentario"
                          className={`${styles.colContainerLabel__label}`}
                        >
                          Prediagnostico
                        </label>
                      </Col>
                      <Col xs={12} className={`${styles.colContainerInput}`}>
                        <textarea
                          className={`${styles.rowContainerItem__textArea}`}
                          id="00N1R00000TWdE1"
                          name="00N1R00000TWdE1"
                          rows="10"
                          type="text"
                          wrap="soft"
                        />
                      </Col>
                    </Row>
                  </Col>

                  <input
                    id="00N36000006jZtN" //Codigo Unico
                    name="00N36000006jZtN"
                    type="hidden"
                    value={gdlvCampaign}
                  />
                  <input
                    id="00N36000005AqLz" //Medio
                    name="00N36000005AqLz"
                    title="Medio."
                    type="hidden"
                    value="GDLV-P"
                  />
                  <input
                    id="00N36000006jZtD"
                    name="00N36000006jZtD"
                    type="hidden"
                    value="Agenda2.0"
                  />
                  <Col xs={12} className={`${styles.colContainerItems}`}>
                    <Row className={`${styles.rowContainerItem}`}>
                      <Col xs={12} className={`${styles.colContainerButton}`}>
                        <button
                          className={`${styles.colContainerButton__button}`}
                          type="submit"
                          name="submit"
                        >
                          <Container fluid>
                            <Row>
                              <Col
                                xs={7}
                                className={`${styles.colContainerNombreBoton}`}
                              >
                                Enviar
                              </Col>
                              <Col
                                xs={3}
                                className={`${styles.colContainerSpanBoton}`}
                              >
                                <span>
                                  <svg className={`${styles.svg}`}>
                                    <use
                                      xlinkHref={`${sprite}#icon-arrow-right`}
                                    />
                                  </svg>
                                </span>
                              </Col>
                            </Row>
                          </Container>
                        </button>
                        {/* <input type='submit' name='submit' /> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
