import React from "react";
import { Toast } from "react-bootstrap";
import styles from "../css/HeaderServicios.module.css";

export default function HeaderServicios(props) {
  let toastStyle;
  const { toastShow, toastText } = props;
  if (process.env.NODE_ENV !== "production") {
    console.tron.log(`[Toast] ${toastShow}`);
  }
  toastStyle = toastShow ? styles.toastShow : styles.toastHide;

  return (
    <Toast show={toastShow} className={styles.toastComponent}>
      <Toast.Body className={styles.toastBody}>{toastText}</Toast.Body>
    </Toast>
  );
}
