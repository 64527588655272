import React, { Component } from "react";
import PropTypes from "prop-types";

import SucursalesCercanas from "../components/Dropdown";

import clinicas from "../data/clinica";

let gdlvCampaign = "";

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};
let clinicasFiltradas = {};
let idService = null;
export default class ElegirSucursal extends Component {
  constructor(props) {
    super(props);
    const { urlParams } = props.location.state;
    let LINK = "";
    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[ElegirSucursal] History`,
        value: props.location.state,
        important: true,
      });
    }

    console.log("Sucursal History:", props.location.state);

    const { diagnostico, promocion } = props.location.state;
    if (
      diagnostico.idServicio === 1747562 ||
      diagnostico.idServicio === 1748018 ||
      diagnostico.idServicio === 2719476 ||
      diagnostico.idServicio === 2389968 ||
      diagnostico.idServicio === 1803491 ||
      urlParams.medio === "GDLV" ||
      urlParams.medio === "JNH" ||
      urlParams.cmp === "C-MKT-LASIK-001"
    ) {
      LINK = "/cita/horario";
    } else {
      LINK = "/cita/paraQuien";
    }
    const sortedClinicsKeys = Object.keys(clinicas).sort(this.compareName);

    const clinicsWithServiceKeys = Object.keys(clinicas).filter((clinicKey) => {
      let withService = false;
      if (promocion !== null) {
        if (clinicas[clinicKey].servicios[diagnostico.idServicio]) {
          withService = true;
          idService = promocion.idSeleccionado;
        }
      } else {
        if (clinicas[clinicKey].servicios[diagnostico.idServicio]) {
          withService = true;
        }
      }
      return withService;
    });

    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[ElegirSucursal] clinicsWithServiceKeys`,
        value: clinicsWithServiceKeys,
      });
    }

    let clinicsKeys = sortedClinicsKeys.filter((clinicKey) => {
      let withService = false;
      if (clinicsWithServiceKeys.indexOf(clinicKey) !== -1) {
        //!Proceso donde se filtran las clinicas con promocion 2x1
        if (diagnostico.descripcion === "Promo 2x1") {
          if (clinicas[clinicKey].servicios[diagnostico.idServicio].promo) {
            withService = true;
            clinicasFiltradas[clinicKey] = clinicas[clinicKey];
          }
        } else if (promocion !== null) {
          console.log("clicicaskey: ", clinicas[clinicKey].servicios[idService].promociones.includes(promocion.parameter));
          if (
            clinicas[clinicKey].servicios[idService].promociones.includes(
              promocion.parameter
            )
          ) {
            
            withService = true;
            clinicasFiltradas[clinicKey] = clinicas[clinicKey];
            console.log("entra ", clinicasFiltradas[clinicKey] )
          }
        } else {
          withService = true;
          clinicasFiltradas[clinicKey] = clinicas[clinicKey];
        }
      }
      return withService;
    });

    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[ElegirSucursal] clinicsKeys`,
        value: clinicsKeys,
      });
    }

    this.state = {
      activo: false,
      latUser: null,
      longUser: null,
      conocemosUbicacion: false,
      clinicsKeys,
      prevRegresion: false,
      regClinicId: null,
      cambioSuc: false,
      regresion: props.location.state.regresion,
      clinics: clinicasFiltradas,
    };
  }

  componentDidMount() {
    const { urlParams, gdlvCampaign } = this.props.location.state;
    window.scrollTo(0, 0);
    // if (urlParams.medio === 'GDLV') {
    //   alert('gdlvCampaign' + JSON.stringify(gdlvCampaign));
    //   document.addEventListener('message', (messageData) => {
    //   	gdlvCampaign = messageData.data;
    //   });
    // }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.state.regresion !== state.prevRegresion) {
      return {
        conocemosUbicacion: props.location.state.clinica.conocemosUbicacion,
        regClinicId: props.location.state.clinica.id,
      };
    }
    return null;
  }

  calculaDistancia = (latUser, longUser, latSucursal, longSucursal) => {
    if (latUser !== 0 && longUser !== 0) {
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(latSucursal - latUser);
      const dLon = this.deg2rad(longSucursal - longUser);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(latUser)) *
          Math.cos(this.deg2rad(latSucursal)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d.toFixed(2);
    }

    return 0;
  };

  deg2rad = (deg) => deg * (Math.PI / 180);

  compareName = (a, b) => {
    const key1 = clinicas[a].ClinicName;
    const key2 = clinicas[b].ClinicName;

    if (key1 < key2) {
      return -1;
    }
    if (key1 === key2) {
      return 0;
    }
    return 1;
  };

  compareDistance = (a, b) => {
    const res = clinicasFiltradas[a].distancia - clinicasFiltradas[b].distancia;
    if (res < 0) {
      return -1;
    }
    if (res === 0) {
      return 0;
    }
    return 1;
  };

  success = (position) => {
    const { coords } = position;
    const { latUser, longUser, clinicsKeys } = this.state;
    let clinicasConDistancia = {};

    if (latUser !== coords.latitude || longUser !== coords.longitude) {
      clinicsKeys.forEach((clinicKey) => {
        const location = clinicas[clinicKey].ClinicGeoLocation;
        const ubicacion = location.split(",");
        const distancia = this.calculaDistancia(
          coords.latitude,
          coords.longitude,
          ubicacion[0],
          ubicacion[1]
        );
        clinicasConDistancia[clinicKey] = {
          ...clinicas[clinicKey],
          distancia,
        };
      });

      clinicasFiltradas = clinicasConDistancia;
      const clinicasXDist = Object.keys(clinicasFiltradas).sort(
        this.compareDistance
      );

      this.setState({
        clinicsKeys: clinicasXDist,
        latUser: coords.latitude,
        longUser: coords.longitude,
        conocemosUbicacion: true,
        clinics: clinicasFiltradas,
      });
    }
  };

  error = (err) => {
    if (process.env.NODE_ENV !== "production") {
      console.tron.error(
        `[ElegirSucursal] Error al obtener la ubicación: (${err.code}): ${err.message}`
      );
    }
  };

  manejarClickContinuar = () => {
    const { latUser, longUser, conocemosUbicacion, cambioSuc } = this.state;
    const { history, location } = this.props;
    const { state } = location;
    const { urlParams } = state;
    let { header, diagnostico, historialFlujo, promocion } = state;
    let { flujoStep } = historialFlujo;

    const reagendar = false;
    const rutaA = "/cita/clinica";
    let regresion = null;
    let newHistory = null;
    let comoLLego = null;
    let direccion = null;
    let clinica = {};
    if (promocion !== null) {
      diagnostico.idServicio = idService;
    }
    historialFlujo.flujoStep = flujoStep + 1;
    if (this.idClinica !== this.state.regClinicId) {
      this.idClinica = this.idClinica;
      if (this.idClinica === undefined) {
        this.idClinica = state.clinica.id;
      }
    }
    comoLLego = clinicasFiltradas[this.idClinica].comoLlego;
    direccion = clinicasFiltradas[this.idClinica].ClinicAddress;

    clinica = {
      cambiarClinica: false,
      id: this.idClinica,
      nombreClinica: this.nombreClinica,
      coordClinica: this.coordClinica,
      latUser,
      longUser,
      conocemosUbicacion,
      como: comoLLego,
      direccion,
    };

    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[ElegirSucursal] manejarClickContinuar`,
        value: clinica,
      });
    }

    if (location.state.regresion === true) {
      if (cambioSuc) {
        header[1] = {
          id: 2,
          titulo: "En la sucursal:",
          dato: this.nombreClinica,
          ruta: rutaA,
        };
      } else {
        header[1] = {
          id: 2,
          titulo: "En la sucursal:",
          dato: location.state.clinica.nombreClinica,
          ruta: rutaA,
        };
      }
      regresion = true;
      newHistory = {
        ...location.state,
        clinica,
        header,
        regresion,
        reagendar,
        historialFlujo,
      };
    } else {
      const aux = location.state.header;
      aux.push({
        id: 2,
        titulo: "En la sucursal:",
        dato: this.nombreClinica,
        ruta: rutaA,
      });
      header = aux;
      newHistory = {
        ...location.state,
        clinica,
        header,
        regresion,
        reagendar,
        historialFlujo,
      };
      if (urlParams.medio === "GDLV") {
        // newHistory.gdlvCampaign = gdlvCampaign;
      }
      regresion = false;
    }
    history.push(
      diagnostico.routes[this.props.location.pathname] +
        history.location.search,
      newHistory
    );
  };

  onChangeSucursal = (clinicId, clinicaNombre, clinicaCoord) => {
    const { activo, cambioSuc } = this.state;
    this.idClinica = clinicId;
    this.nombreClinica = clinicaNombre;
    this.coordClinica = clinicaCoord;

    if (this.idClinica !== null) {
      if (!activo) {
        this.setState({ activo: true });
      }
      if (!cambioSuc) {
        this.setState({ cambioSuc: true });
      }
    }

    if (process.env.NODE_ENV !== "production") {
      console.tron.display({
        name: `[ElegirSucursal] onChangeSucursal`,
        value: {
          clinicId,
          clinicaNombre,
          clinicaCoord,
          activo,
          cambioSuc,
        },
      });
    }
  };

  render() {
    const { conocemosUbicacion, clinicsKeys, regClinicId, regresion, clinics } =
      this.state;
    if (
      !conocemosUbicacion ||
      this.props.location.state.clinica !== undefined
    ) {
      navigator.geolocation.getCurrentPosition(
        this.success,
        this.error,
        options
      );
    }

    return (
      <SucursalesCercanas
        clinicsKeys={clinicsKeys}
        conocemosUbicacion={conocemosUbicacion}
        onChangeSucursal={this.onChangeSucursal}
        clinicKey={regClinicId}
        regresion={regresion}
        clinicas={clinics}
        history={this.props.history}
        state={this.props.location.state}
        manejarClickContinuar={this.manejarClickContinuar}
      />
    );
  }
}

ElegirSucursal.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

ElegirSucursal.defaultProps = {
  location: null,
  history: null,
};
