import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import styles from "./SucursalesCercanas.module.css";

export default function DropdownHorarios(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState("AM");

  useEffect(() => {
    setSelected(props.data.options === selected ? selected : "AM");
  });

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        caret
        color="white"
        size="lg"
        className={`${styles.DropdownHorarios} px-5`}
      >
        {selected === "AM" ? "Por la Mañana" : "Por la Tarde"}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          key={"AM"}
          className={`${styles.ItemHorarios}`}
          onClick={() => {
            setSelected("AM");
            props.data.select("AM");
          }}
        >
          {"Por la Mañana"}
        </DropdownItem>
        <DropdownItem
          key={"PM"}
          className={`${styles.ItemHorarios}`}
          onClick={() => {
            setSelected("PM");
            props.data.select("PM");
          }}
        >
          {"Por la Tarde"}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
