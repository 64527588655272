import React, { useEffect } from "react";
import Monitor from "./Monitor";
import { database } from "../../firebase/config";
import { ref, get } from "firebase/database";

const MonitorContainer = () => {
  useEffect(() => {
    async function fetchData() {
      const resultados = ref(database, "notifications/monitor");
      const resultados2 = await get(resultados);
      console.log("resultados:", resultados2.val());
      //setinterval que actualice constantemente    //--database.ref.on para suscribirme a la bd
    }
    fetchData();
  }, []); // Or []

  return <Monitor />;
};
export default MonitorContainer;
